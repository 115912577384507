import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { getIndex } from "../../selectors/monitoring.selectors";

import { SeasonWithCropTo } from "../../../../generated/api/satellite";
import { MonitoringCropIntervals } from "../BioMonitoring";

type Props = {
  isInitCustomCondition: boolean;
  cropIntervals: (MonitoringCropIntervals | SeasonWithCropTo)[];
  selectedIntervalId?: string;
  isFetchingCropIntervals: boolean;
  cropIntervalsFetched?: boolean;
};

const useLocking = ({
  cropIntervals,
  cropIntervalsFetched,
  isFetchingCropIntervals,
  isInitCustomCondition,
  selectedIntervalId,
}: Props) => {
  const index = useSelector(getIndex);

  const [isInit, setIsInit] = useState(false);
  const [cropsHasBeenCalled, setCropsHasBeenCalled] = useState(false);

  useEffect(() => {
    if (
      (isFetchingCropIntervals || cropIntervalsFetched) &&
      !cropsHasBeenCalled
    ) {
      setCropsHasBeenCalled(true);
    }
  }, [cropIntervalsFetched, cropsHasBeenCalled, isFetchingCropIntervals]);

  useEffect(() => {
    const cropsAreInit =
      cropsHasBeenCalled && !isFetchingCropIntervals && cropIntervals.length;

    if (!isInit && cropsAreInit && isInitCustomCondition) {
      setIsInit(true);
    }
  }, [
    cropIntervals,
    isInit,
    isFetchingCropIntervals,
    index,
    selectedIntervalId,
    isInitCustomCondition,
    cropsHasBeenCalled,
  ]);

  return {
    isInit,
  };
};

export { useLocking };
