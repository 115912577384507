import React, { Suspense, useCallback, useEffect, useState } from "react";

import { Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";
import { isNil } from "lodash";
import { Scrollbars } from "react-custom-scrollbars-2";
import { connect } from "react-redux";
import {
  Route,
  Switch,
  generatePath,
  match as matchType,
  useHistory,
  useParams,
} from "react-router-dom";
import { bindActionCreators } from "redux";

import { setFocusedRow } from "../actions/precision.actions";

import { LANGUAGE_ID } from "../../../shared/lang/lang.constants";
import { PRECISION_URLS } from "../precision.constants";

import { YieldMapsDetail } from "../../../precision-farming/routes/parcel-detail/yield-maps/YieldMapsDetail";
import { useHistoryPreviousState } from "../../../precision-farming/useHistoryPreviousState";
import {
  ImportFilesApiType,
  ValidateFileApiType,
  importFilesApi,
  validateFileApi,
} from "../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.api";
import CfBackButton from "../../../shared/components/common/CfBackButton/CfBackButton";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import FabWithOptions from "../../../shared/components/common/FabWithOptions/FabWithOptions";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import useFocusedTableRowSource from "../../../shared/hooks/useFocusedTableRowSource";
import { useToggle } from "../../../shared/hooks/useToggle";
import useWidth from "../../../shared/hooks/useWidth";
import { Thunk } from "../../../types";
import ParcelDetailTabs, {
  ParcelDetailTab,
} from "../../parcels/detail/shared/components/ParcelDetailTabs/ParcelDetailTabs";
import PrecisionDetailHeadingV2 from "../components/PrecisionDetailHeadingV2";
import { satelliteParcelQuery } from "../PrecisionV2.api";

import { BiomonitoringWrapperV2 } from "./biomonitoring/BiomonitoringWrapper/BiomonitoringWrapperV2";
import ImportMachinesDialog from "./ImportMachinesDialog";
import { SoilSamplesV2 } from "./SoilSamplesV2";
import ZoneManagement from "./ZoneManagement";

import { PrecisionState } from "../../../reducers/precision.reducer.types";
import { PrecisionParcel } from "../../../shared/api/sentinel/precision/precision.types";

interface PrecisionTab extends ParcelDetailTab {
  code: (keyof PrecisionParcel)[];
  path: string;
}

export const PRECISION_TABS = {
  zoneManagement: {
    id: "zoneManagement",
    code: ["managementZones"],
    section: "PrecisionDetailTabs",
    path: "zone-management",
  },
  monitoring: {
    id: "monitoring",
    code: ["biomonitoring", "internalBiomonitoring"],
    section: "PrecisionDetailTabs",
    path: "bio-monitoring",
  },
  soilSamples: {
    id: "soilSamples",
    code: ["soilSamples"],
    section: "PrecisionDetailTabs",
    path: "soil-samples",
  },
  yieldMaps: {
    id: "yieldMaps",
    code: ["yieldMaps"],
    section: "PrecisionDetailTabs",
    path: "yield-Maps",
  },
} satisfies Record<string, PrecisionTab>;

type TabId = keyof typeof PRECISION_TABS;

type Props = {
  importFilesApi: (params: ImportFilesApiType) => void;
  langId: LANGUAGE_ID;
  match: matchType<{ farmId: string; parcelId: string }>;
  ngRedirectToSowing: (parcelId: string[]) => void;
  ngRedirectToVrf: (parcelId: string[]) => void;
  ngRedirectToVrs: (parcelId: string[]) => void;
  setFocusedRow: (val?: string) => void;
  validateFileApi: (params: ValidateFileApiType) => void;
};

const PrecisionDetailV2 = ({
  importFilesApi,
  langId,
  match,
  ngRedirectToSowing,
  ngRedirectToVrf,
  ngRedirectToVrs,
  setFocusedRow,
  validateFileApi,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const width = useWidth();

  const [activeTab, setActiveTab] = useState<string>(
    PRECISION_TABS.zoneManagement.id,
  );

  const { farmId, parcelId } = match.params;
  const { pathname } = history.location;
  const { previousLocation } = useHistoryPreviousState();
  const { seasonId } = useParams<{ seasonId: string }>();

  const {
    on: machinesImportIsOpen,
    setOff: handleMachinesImportClose,
    setOn: handleMachinesImportOpen,
  } = useToggle();
  const { doSetFocusedRow } = useFocusedTableRowSource({
    sourceId: parcelId,
    setFocusedRow,
  });

  const parcelData = useQuery(
    satelliteParcelQuery(parcelId, {
      farmIds: farmId,
      "season-id": seasonId,
    }),
  );

  const parcelV2 = parcelData.data?.data;

  useEffect(() => {
    setTabByPathname(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setTabByPathname = (pathname: string) => {
    const tabs = Object.keys(PRECISION_TABS) as TabId[];
    tabs.forEach((tab) => {
      if (pathname.includes(PRECISION_TABS[tab].path)) {
        setActiveTab(PRECISION_TABS[tab].id);
      }
    });
  };

  const handleGoBack = useCallback(() => {
    doSetFocusedRow();

    history.push(
      previousLocation || `/farm/${farmId}/${PRECISION_URLS.seasons()}`,
    );
  }, [doSetFocusedRow, farmId, history, previousLocation]);

  return (
    <Grid className={classes.wrapper} container>
      <Scrollbars>
        <div className={classes.body}>
          <div className={classes.headerWithTabs}>
            <div className={classes.headerWrapper}>
              <PageHeader
                actionButtons={
                  <FabWithOptions
                    options={[
                      {
                        translId: "common.addVrfActivity",
                        action: () => ngRedirectToVrf([parcelId]),
                      },
                      {
                        translId: "common.addVrsActivity",
                        action: () => ngRedirectToVrs([parcelId]),
                        divider: true,
                      },
                      {
                        translId: "PrecisionFarming.add.importRides",
                        action: handleMachinesImportOpen,
                      },
                    ]}
                  />
                }
                backButton={
                  <CfBackButton
                    onClick={handleGoBack}
                    translId="PrecisionDetail.backToPrecision"
                  />
                }
                heading={
                  !isNil(parcelV2) ? (
                    <PrecisionDetailHeadingV2
                      farmId={farmId}
                      parcel={parcelV2}
                    />
                  ) : null
                }
              />
            </div>

            {!isNil(parcelV2) ? (
              <ParcelDetailTabs
                activateTab={(tabId: string) => setActiveTab(tabId)}
                activeTab={activeTab}
                width={width}
                onChange={(value: TabId) =>
                  history.push(
                    generatePath(
                      `/farm/${farmId}/${PRECISION_URLS.serviceDetailV2()}/${
                        PRECISION_TABS[value].path
                      }`,
                      {
                        parcelId: parcelV2.id,
                        seasonId,
                      },
                    ),
                  )
                }
                tabs={Object.values(PRECISION_TABS).filter((tab) =>
                  tab.code.some(
                    (code) => (parcelV2 as unknown as PrecisionParcel)[code],
                  ),
                )}
              />
            ) : null}
          </div>
          <Grid className={classes.contentWrapper} container>
            <Grid className={classes.content} item>
              <Suspense fallback={<CfLoader />}>
                <Switch>
                  <Route
                    exact
                    path={`/farm/:farmId/${PRECISION_URLS.serviceDetailV2()}/${
                      PRECISION_TABS.monitoring.path
                    }`}
                    render={() => (
                      <BiomonitoringWrapperV2
                        ngRedirectToSowing={ngRedirectToSowing}
                        parcel={parcelV2}
                        parcelId={parcelId}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/farm/:farmId/${PRECISION_URLS.serviceDetailV2()}/${
                      PRECISION_TABS.soilSamples.path
                    }`}
                    render={() => (
                      <SoilSamplesV2 parcel={parcelV2} parcelId={parcelId} />
                    )}
                  />
                  <Route
                    exact
                    render={() => <YieldMapsDetail />}
                    path={`/farm/:farmId/${PRECISION_URLS.serviceDetailV2()}/${
                      PRECISION_TABS.yieldMaps.path
                    }`}
                  />
                  <Route
                    exact
                    path={`/farm/:farmId/${PRECISION_URLS.serviceDetailV2()}/${
                      PRECISION_TABS.zoneManagement.path
                    }`}
                    render={() =>
                      !isNil(parcelV2) ? (
                        <ZoneManagement
                          farmId={farmId}
                          langId={langId}
                          parcelId={parcelV2.externalParcelId}
                        />
                      ) : null
                    }
                  />
                </Switch>
              </Suspense>
            </Grid>
          </Grid>
        </div>
      </Scrollbars>
      <ImportMachinesDialog
        handleClose={handleMachinesImportClose}
        importFilesApi={importFilesApi}
        open={machinesImportIsOpen}
        validateFileApi={validateFileApi}
        onSuccess={() =>
          history.push(`/farm/${farmId}/${PRECISION_URLS.asApplied()}`)
        }
      />
    </Grid>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      importFilesApi,
      validateFileApi,
      setFocusedRow,
    },
    dispatch,
  );

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: "100%",
  },
  headerWithTabs: {
    backgroundColor: theme.palette.common.white,
    borderBottom: "1px solid #EFEFEF",
  },
  headerWrapper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  body: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentWrapper: {
    height: "100%",
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    width: "100%",
  },
}));

export default connect(mapStateToProps, mapDispatchToProps)(PrecisionDetailV2);
