import { queryOptions, useMutation } from "@tanstack/react-query";

import {
  getAllFarms,
  GetAllFarmsParams,
  putFarm,
} from "../../../generated/api/agroevidence";
import { getCountedData } from "../../../shared/api/client.utils";
import { staleTime } from "../../../shared/query-client";

const adminFarmsKeys = ["agroevidence", "adminFarms"];

const accountFarmsQuery = ({ ...params }: GetAllFarmsParams) =>
  queryOptions({
    queryKey: [...adminFarmsKeys, params],
    queryFn: async ({ signal }) => {
      const data = await getAllFarms(params, { signal });
      return getCountedData(data);
    },
    staleTime: staleTime.Infinity,
  });

const useUpdateFarmSettingsMutation = () =>
  useMutation({
    mutationFn: (params: Parameters<typeof putFarm>) => putFarm(...params),
  });

export { adminFarmsKeys, accountFarmsQuery, useUpdateFarmSettingsMutation };
