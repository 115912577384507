import React, { Fragment } from "react";

import { Theme, alpha } from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { getVariableExpenses } from "../../../../../shared/api/sentinel/variableApplication/variableApplication.selectors";

import CfStaticMap from "../../../../../shared/components/specific/CfStaticMap/CfStaticMap";

import {
  IndexResponse,
  IndexType,
} from "../../../../../shared/api/satellite/satellite.types";

type Props = {
  isSelected?: boolean;
  mapId: string;
  satellite: IndexResponse;
  showType?: boolean;
  onClick: () => void;
};

const VaMapSourceItem = ({
  isSelected = false,
  mapId,
  onClick,
  satellite: { chemicalElement, crop, dateFrom: from, dateTo: to, type, zones },
  showType,
}: Props) => {
  const classes = useStyles();
  const { formatDate } = useIntl();
  const variableExpenses = useSelector(getVariableExpenses);

  const isSameDay = (from: string, to: string) =>
    moment(from).isSame(moment(to));

  const datestamp = (() => {
    if (from && to) {
      if (isSameDay(from, to)) {
        return `${formatDate(from)}`;
      } else {
        return `${formatDate(from)} − ${formatDate(to)}`;
      }
    }
    return null;
  })();

  const renderCropstamp = () => (
    <>
      {type && !type?.endsWith(IndexType.SAVI) && (
        <span
          className={classnames(classes.cropstamp, {
            [classes.cropstampActive]: isSelected,
          })}
        >
          {type === IndexType.SAMPLES ? (
            <FormattedMessage
              id={`VaMapSourceDialog.element.${chemicalElement}`}
            />
          ) : (
            <span data-test="crop">{crop?.name}</span>
          )}
        </span>
      )}
    </>
  );

  const renderTypestamp = () => {
    const isSavi = type === IndexType.SAVI;
    const saviType = variableExpenses?.length
      ? variableExpenses[0].zoneType
      : undefined;

    return (
      <>
        {type && showType && (
          <span className={classes.typestamp} data-test="type">
            <Typography variant="caption">
              <FormattedMessage
                id={`VaMapSourceDialog.${isSavi && saviType ? saviType : type}`}
              />
            </Typography>
          </span>
        )}
      </>
    );
  };

  return (
    <Fragment>
      <div className={classes.mapImage}>
        <a
          className={classes.href}
          onClick={onClick}
          role="button"
          tabIndex={0}
        >
          {renderTypestamp()}
          {renderCropstamp()}
          <CfStaticMap
            geometries={zones}
            isSelected={isSelected}
            mapId={mapId}
          />
        </a>
      </div>
      {datestamp && (
        <div className={classes.datestamp} data-test="date-stamp">
          {datestamp}
        </div>
      )}
    </Fragment>
  );
};

export { VaMapSourceItem };

const useStyles = makeStyles((theme: Theme) => ({
  cropstamp: {
    backgroundColor: alpha(theme.palette.common.black, 0.5),
    color: theme.palette.common.white,
    position: "absolute",
    zIndex: 100,
    left: 0,
    top: 0,
    padding: "2px 6px",
    fontSize: 12,
    borderTopLeftRadius: 5,
  },
  datestamp: {
    fontSize: 12,
    textAlign: "center",
    padding: "2px 0px",
  },
  typestamp: {
    color: theme.palette.grey[500],
    fontSize: 12,
    position: "absolute",
    zIndex: 100,
    left: 0,
    top: -20,
  },
  cropstampActive: {
    backgroundColor: theme.palette.secondary.main,
  },
  href: {
    "&:focus": {
      outline: "none",
    },
  },
  mapImage: {
    width: 150,
    height: 150,
  },
}));
