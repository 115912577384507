/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";

import { useIntl } from "react-intl";

import CfAutosuggest from "../../../../../shared/components/common/CfAutosuggest/CfAutosuggest";

export type ParcelZoneSelectorProps = {
  autoFocus: boolean;
  inputRef: unknown;
  onSuggestionSelected: (suggestion: unknown) => void;
  onSuggestionsClearRequested: () => void;
  onSuggestionsFetchRequested: (searchInput?: string) => void;
  placeholder?: string;
  suggestions: unknown[];
};

const ParcelZoneSelector = ({
  autoFocus = false,
  inputRef = () => {},
  onSuggestionSelected = () => {},
  onSuggestionsClearRequested = () => {},
  onSuggestionsFetchRequested = () => {},
  placeholder,
  suggestions = [],
}: ParcelZoneSelectorProps) => {
  const { formatMessage } = useIntl();

  const getSuggestionValue = ({
    blockNumber,
    localName,
    name,
  }: {
    blockNumber: string;
    localName: string;
    name: string;
  }) =>
    localName ? `${blockNumber ? `${blockNumber},` : ""} ${localName}` : name;

  return (
    <div data-testid="parcel-zone-selector">
      <CfAutosuggest
        autoFocus={autoFocus}
        clearSuggestions={onSuggestionsClearRequested}
        getSuggestions={onSuggestionsFetchRequested}
        getSuggestionValue={getSuggestionValue}
        inputRef={inputRef}
        isDebounced
        onSuggestionSelected={onSuggestionSelected}
        placeholder={formatMessage({ id: placeholder })}
        requiredLength={3}
        suggestions={suggestions}
        testData="parcel-zone-selector"
      />
    </div>
  );
};

export { ParcelZoneSelector };
