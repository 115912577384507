import { queryOptions } from "@tanstack/react-query";

import { GetStatusParams, getStatus } from "../../../generated/api/telematics";
import { staleTime } from "../../../shared/query-client";

const statusQuery = ({ farmIds }: GetStatusParams) =>
  queryOptions({
    queryKey: ["telematics", "account", "status", farmIds],
    queryFn: ({ signal }) => getStatus({ farmIds }, { signal }),
    staleTime: staleTime.Infinity,
  });

const dashboardQuery = ({ farmIds }: GetStatusParams) =>
  queryOptions({
    ...statusQuery({ farmIds }),
    select: (data) => data.data.dashboard,
  });

export { statusQuery, dashboardQuery };
