import React from "react";

import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import PrecisionMapModal from "../../../../../core/precision/components/PrecisionMapModal/PrecisionMapModal";
import PrecisionMapV2 from "../../../../../core/precision/components/PrecisionMapModal/PrecisionMapV2";
import SectionHeader from "../../../../../core/precision/components/ZoneManagement/SectionHeader";
import {
  YieldMapDetailResponse,
  SatelliteParcelV2,
} from "../../../../../generated/api/satellite";
import CfStaticMap from "../../../../../shared/components/specific/CfStaticMap/CfStaticMap";
import { useToggle } from "../../../../../shared/hooks/useToggle";
import { useIsMobile } from "../../../../../shared/hooks/useWidth";

import { YieldMapLegend } from "./YieldMapLegend";

interface Props {
  data: YieldMapDetailResponse;
  parcelId: string;
  parcelData: SatelliteParcelV2;
}

const YieldMapGeometries = (props: Props) => {
  const data = props.data;

  const { on: isOpen, setOff: closeDialog, setOn: openDialog } = useToggle();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const getLegend = (isModal?: boolean) => (
    <div>
      <SectionHeader
        headingTranslationId="YieldMaps.zoning.title"
        isModal={isModal}
      />
    </div>
  );

  return (
    <>
      <div className={classes.zonesMap} onClick={data ? openDialog : undefined}>
        {!isMobile ? <YieldMapLegend legend={data.legend} /> : null}
        <CfStaticMap
          interactive={false}
          interpolate={false}
          mapId="geometries-raster-yield-map"
          image={{
            url: data.rasterUrl,
            extent: [
              data.rasterExtent.minX,
              data.rasterExtent.minY,
              data.rasterExtent.maxX,
              data.rasterExtent.maxY,
            ],
          }}
        />
      </div>
      {isOpen && data && (
        <PrecisionMapModal
          header={null}
          legend={getLegend(true)}
          onClose={closeDialog}
          map={
            <PrecisionMapV2
              geometries={null}
              header={null}
              interpolate={false}
              parcel={props.parcelData}
              parcelId={props.parcelId}
              mapImage={{
                url: data.rasterUrl,
                extent: [
                  data.rasterExtent.minX,
                  data.rasterExtent.minY,
                  data.rasterExtent.maxX,
                  data.rasterExtent.maxY,
                ],
              }}
            />
          }
        />
      )}
      {isMobile ? (
        <div className={classes.legenda}>
          <details>
            <summary className={classes.legendaButton}>
              <FormattedMessage id="YieldMaps.legend.mobilelegend" />
            </summary>
            <YieldMapLegend legend={data.legend} />
          </details>
        </div>
      ) : null}
    </>
  );
};

const useStyles = makeStyles(() => ({
  zonesMap: {
    width: "100%",
    height: "100%",
    maxHeight: "716px",
    minHeight: "368px",
    minWidth: "300px",
    cursor: "pointer",
    position: "relative",
  },
  legenda: {
    padding: "20px 0 80px 0",
  },
  legendaButton: {
    fontSize: "16px",
    fontFamily: "Roboto",
    lineHeight: "16px",
    backgroundColor: "#fff",
    padding: "12px",
    borderRadius: "8px",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.10)",
  },
}));

export { YieldMapGeometries };
