import { AnyAction } from "redux";

import * as types from "./monitoring.constants";
import * as typesV2 from "./monitoringV2.constants";

import { MonitoringAPI } from "./monitoring.types";

const initialState: MonitoringAPI = {
  isFetching: false,
  error: undefined,
  items: [],
  cropIntervals: [],
  cropIntervalsIsFetching: false,
  cropIntervalsError: undefined,
  records: undefined,
  recordsIsFetching: false,
  validity: undefined,
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.GET_MONITORING_DATA_REQUEST:
      case typesV2.GET_MONITORING_DATA_REQUEST:
        return {
          ...state,
          isFetching: true,
          error: {},
        };
      case types.GET_MONITORING_DATA_SUCCESS:
      case typesV2.GET_MONITORING_DATA_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload,
        };
      case types.GET_MONITORING_DATA_ERROR:
      case typesV2.GET_MONITORING_DATA_ERROR:
        return {
          ...state,
          isFetching: false,
          error: action.payload,
        };
      case types.RESET_MONITORING_DATA:
      case typesV2.RESET_MONITORING_DATA:
        return {
          ...state,
          isFetching: false,
          error: {},
          items: [],
        };
      case types.GET_MONITORING_CROP_INTERVALS_REQUEST:
        return {
          ...state,
          cropIntervalsIsFetching: true,
          cropIntervalsError: {},
        };
      case types.GET_MONITORING_CROP_INTERVALS_SUCCESS:
        return {
          ...state,
          cropIntervalsIsFetching: false,
          cropIntervals: action.payload,
        };
      case types.GET_MONITORING_CROP_INTERVALS_ERROR:
        return {
          ...state,
          cropIntervalsIsFetching: false,
          cropIntervalsError: action.payload,
        };
      case types.RESET_MONITORING_CROP_INTERVALS:
        return {
          ...state,
          cropIntervalsIsFetching: false,
          cropIntervals: [],
        };
      case types.GET_BIOMONITORING_INDEX_RECORDS_REQUEST:
        return {
          ...state,
          recordsIsFetching: true,
          recordsError: {},
        };
      case types.GET_BIOMONITORING_INDEX_RECORDS_SUCCESS:
        return {
          ...state,
          recordsIsFetching: false,
          records: action.payload,
          validity: action.payload.validity,
        };
      case types.GET_BIOMONITORING_INDEX_RECORDS_ERROR:
        return {
          ...state,
          recordsIsFetching: false,
          recordsError: action.payload,
        };
      case types.RESET_BIOMONITORING_INDEX_RECORDS:
        return {
          ...state,
          recordsIsFetching: false,
          records: undefined,
          validity: undefined,
        };
      default:
        return state;
    }
  };
