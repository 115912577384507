import { queryOptions } from "@tanstack/react-query";

import {
  GetDriveSubjectsParams,
  getDriveSubjects,
} from "../../../../generated/api/telematics";
import { staleTime } from "../../../../shared/query-client";

const subjectsQuery = ({ farmIds, search }: GetDriveSubjectsParams) =>
  queryOptions({
    queryKey: ["telematics", "subject", farmIds, search],
    queryFn: ({ signal }) => getDriveSubjects({ search, farmIds }, { signal }),
    staleTime: staleTime.Infinity,
  });

export { subjectsQuery };
