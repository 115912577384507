import { queryOptions } from "@tanstack/react-query";

import {
  getFarmPfVersion,
  GetFarmPfVersionParams,
  getParcelSeasons,
  GetParcelSeasonsParams,
  getSamplesV2,
  GetSamplesV2Params,
} from "../../../../generated/api/satellite";
import { staleTimeUnit } from "../../../../shared/api/client.utils";

import { apiKeys } from "./apiKeys";

const pfVersionQuery = (params: GetFarmPfVersionParams) =>
  queryOptions({
    queryKey: apiKeys.migration.value(params),
    queryFn: ({ signal }) => getFarmPfVersion(params, { signal }),
    staleTime: staleTimeUnit["1_day"],
  });

const getParcelSeasonsQuery = (
  parcelId: string,
  params: GetParcelSeasonsParams,
) =>
  queryOptions({
    queryKey: apiKeys.parcelSeasons.all(parcelId, params),
    queryFn: ({ signal }) => getParcelSeasons(parcelId, params, { signal }),
    staleTime: staleTimeUnit["1_day"],
  });

const getSamplesV2Query = (
  parcelId: string,
  params: GetSamplesV2Params,
  enabled: boolean = true,
) =>
  queryOptions({
    queryKey: apiKeys.soilSamples.parcelSamples.all(parcelId, params),
    queryFn: ({ signal }) => getSamplesV2(parcelId, params, { signal }),
    staleTime: staleTimeUnit["1_day"],
    enabled,
  });

export { pfVersionQuery, getParcelSeasonsQuery, getSamplesV2Query };
