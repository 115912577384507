import React, { Suspense } from "react";

import { Switch, Route } from "react-router-dom";

import { LANGUAGE_ID, LANG_KEYS } from "../../../shared/lang/lang.constants";
import { PRECISION_URLS } from "../precision.constants";

import { FarmPfVersionEnum } from "../../../generated/api/satellite";
import { PfVersionGuard } from "../../../precision-farming/components/pf-version-guard/PfVersionGuard";
import { ServiceGuard } from "../../../precision-farming/components/service-guard/ServiceGuard";
import { Dashboard } from "../../../precision-farming/routes/dashboard/Dashboard";
import { ParcelImportTree } from "../../../precision-farming/routes/parcel-import/ParcelImport";
import { PrescriptionMaps } from "../../../precision-farming/routes/prescription-maps/PrescriptionMaps";
import { PrecisionServicesV2 } from "../../../precision-farming/routes/services/PrecisionServicesV2";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import BaseContainer from "../../../shared/containers/BaseContainer/BaseContainer";
import { useFarmToggles } from "../../../shared/toggles/useFarmToggles";
import { Detail as AsAppliedDetail } from "../components/AsApplied/AsAppliedDetail/Detail";
import translationsCZ from "../lang/locale-cs-CZ.json";
import translationsEL from "../lang/locale-el-GR.json";
import translationsEN from "../lang/locale-en-US.json";
import translationsES from "../lang/locale-es-ES.json";
import translationsHU from "../lang/locale-hu-HU.json";
import translationsPL from "../lang/locale-pl-PL.json";
import translationsRO from "../lang/locale-ro-RO.json";

import PrecisionAsApplied from "./PrecisionAsApplied";
import PrecisionDetail from "./PrecisionDetail";
import PrecisionDetailV2 from "./PrecisionDetailV2";
import PrecisionServices from "./PrecisionServices";
import PrecisionTabs from "./PrecisionTabs";

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.EL]: translationsEL,
  [LANG_KEYS.RO]: translationsRO,
  [LANG_KEYS.HU]: translationsHU,
  [LANG_KEYS.PL]: translationsPL,
};

type Props = {
  farmId: string;
  langId: LANGUAGE_ID;
  ngRedirectToUserIntegrations: (farmId: string) => void;
} & Record<
  "ngRedirectToSowing" | "ngRedirectToVrf" | "ngRedirectToVrs",
  (parcelId: string[]) => void
>;

const PrecisionWrapper = (props: Props) => {
  const { isActive } = useFarmToggles();
  const hasPrecisionFarming20Flag = isActive("PRECISION_FARMING_2_0");

  return (
    <BaseContainer langId={props.langId} translations={translations}>
      <Suspense fallback={<CfLoader />}>
        <Route
          exact
          path={[
            `/farm/:farmId/${PRECISION_URLS.main}`,
            `/farm/:farmId/${PRECISION_URLS.seasons()}`,
            `/farm/:farmId/${PRECISION_URLS.seasonDetail()}`,
            `/farm/:farmId/${PRECISION_URLS.seasonsDashboard()}`,
            `/farm/:farmId/${PRECISION_URLS.services()}`,
            `/farm/:farmId/${PRECISION_URLS.servicesV2()}`,
            `/farm/:farmId/${PRECISION_URLS.asApplied()}`,
            `/farm/:farmId/${PRECISION_URLS.prescriptionMaps()}`,
          ]}
          render={() => (
            <PrecisionTabs
              ngRedirectToVrf={props.ngRedirectToVrf}
              ngRedirectToVrs={props.ngRedirectToVrs}
            />
          )}
        />
        <Switch>
          <Route
            exact
            path={`/farm/:farmId/${PRECISION_URLS.main}`}
            render={(routerProps) => (
              <ServiceGuard langId={props.langId}>
                <PrecisionServices {...props} {...routerProps} />
              </ServiceGuard>
            )}
          />

          <Route
            path={`/farm/:farmId/${PRECISION_URLS.seasons()}`}
            render={({ match }) => (
              <Switch>
                <Route
                  exact
                  path={`${match.path}/dashboard`}
                  render={() => (
                    <ServiceGuard langId={props.langId}>
                      {hasPrecisionFarming20Flag ? (
                        <Dashboard />
                      ) : (
                        <PfVersionGuard
                          requiredVersion={FarmPfVersionEnum["2_0_0"]}
                        >
                          <Dashboard />
                        </PfVersionGuard>
                      )}
                    </ServiceGuard>
                  )}
                />
                <Route
                  path={[`${match.path}/:seasonId`, "*"]}
                  render={(routerProps) => (
                    <ServiceGuard langId={props.langId}>
                      {hasPrecisionFarming20Flag ? (
                        <PrecisionServicesV2 {...props} {...routerProps}>
                          <Switch>
                            <Route
                              path={`${routerProps.match.path}/parcel-import`}
                            >
                              <ParcelImportTree />
                            </Route>
                          </Switch>
                        </PrecisionServicesV2>
                      ) : (
                        <PfVersionGuard
                          requiredVersion={FarmPfVersionEnum["2_0_0"]}
                        >
                          <PrecisionServicesV2 {...props} {...routerProps}>
                            <Switch>
                              <Route
                                path={`${routerProps.match.path}/parcel-import`}
                              >
                                <ParcelImportTree />
                              </Route>
                            </Switch>
                          </PrecisionServicesV2>
                        </PfVersionGuard>
                      )}
                    </ServiceGuard>
                  )}
                />
              </Switch>
            )}
          />

          <Route
            exact
            path={`/farm/:farmId/${PRECISION_URLS.services()}`}
            render={(routerProps) => (
              <ServiceGuard langId={props.langId}>
                <PrecisionServices {...props} {...routerProps} />
              </ServiceGuard>
            )}
          />
          <Route
            exact
            path={`/farm/:farmId/${PRECISION_URLS.asApplied()}`}
            render={(routerProps) => (
              <PrecisionAsApplied {...props} {...routerProps} />
            )}
          />
          <Route
            path={`/farm/:farmId/${PRECISION_URLS.asAppliedDetail()}`}
            render={() => <AsAppliedDetail langId={props.langId} />}
          />
          <Route
            path={`/farm/:farmId/${PRECISION_URLS.serviceDetailV2()}`}
            render={(routerProps) =>
              hasPrecisionFarming20Flag ? (
                <PrecisionDetailV2 {...props} {...routerProps} />
              ) : (
                <PfVersionGuard requiredVersion={FarmPfVersionEnum["2_0_0"]}>
                  <PrecisionDetailV2 {...props} {...routerProps} />
                </PfVersionGuard>
              )
            }
          />
          <Route
            path={`/farm/:farmId/${PRECISION_URLS.serviceDetail()}`}
            render={(routerProps) =>
              hasPrecisionFarming20Flag ? (
                <PrecisionDetail {...props} {...routerProps} />
              ) : (
                <PfVersionGuard requiredVersion={FarmPfVersionEnum["1_0_0"]}>
                  <PrecisionDetail {...props} {...routerProps} />
                </PfVersionGuard>
              )
            }
          />
          <Route
            path={`/farm/:farmId/${PRECISION_URLS.prescriptionMaps()}`}
            render={() => (
              <PfVersionGuard requiredVersion={FarmPfVersionEnum["2_0_0"]}>
                <PrescriptionMaps />
              </PfVersionGuard>
            )}
          />
        </Switch>
      </Suspense>
    </BaseContainer>
  );
};

export default PrecisionWrapper;
