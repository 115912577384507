import { queryOptions } from "@tanstack/react-query";
import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./admin.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import {
  getFarmPfVersion,
  GetFarmPfVersionParams,
} from "../../../../generated/api/satellite";
import { apiKeys } from "../../../../precision-farming/routes/services/api/apiKeys";
import { staleTime } from "../../../query-client";

import {
  IntegrationType,
  ParcelsConfigUpdateRequest,
} from "../../satellite/satellite.types";

type GetAdminParcelsParams = {
  localNameBlockNr: string;
  farmIds: string | undefined;
  "monitoring-gisat"?: boolean;
  "monitoring-planet"?: boolean;
  "management-zones"?: boolean;
  "growth-potential"?: boolean;
  "yield-maps"?: boolean;
  crops?: string[];
  page: number;
  "per-page": number;
  "sort-col": string;
  "sort-dir": string;
};

export const getCrops = (params: { farmIds?: string }) => ({
  [RSAA]: {
    endpoint: `admin/crops?${queryString.stringify(params)}&`,
    method: methods.GET,
    module: modules.SENTINEL,

    types: [
      types.GET_ADMIN_CROPS,
      types.GET_ADMIN_CROPS_SUCCESS,
      types.GET_ADMIN_CROPS_ERROR,
    ],
  },
});

export const getAdminParcels = (
  context: string,
  params: GetAdminParcelsParams,
  versionUrl?: string,
) => ({
  [RSAA]: {
    endpoint: `admin${versionUrl}?${queryString.stringify(params)}&`,
    method: methods.GET,
    context,
    module: modules.SENTINEL,
    types: [
      types.GET_ADMIN_PARCELS,
      types.GET_ADMIN_PARCELS_SUCCESS,
      types.GET_ADMIN_PARCELS_ERROR,
    ],
  },
});

export const getAdminIntegration = (params: {
  farmIds?: string;
  type: IntegrationType;
}) => ({
  [RSAA]: {
    endpoint: `admin/integration?${queryString.stringify(params)}&`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_ADMIN_INTEGRATION,
      types.GET_ADMIN_INTEGRATION_SUCCESS,
      types.GET_ADMIN_INTEGRATION_ERROR,
    ],
  },
});

export const updateAdminIntegration = ({
  farmIds,
  payload,
}: {
  farmIds?: string;
  payload: {
    type: IntegrationType;
    enabled: boolean;
  };
}) => ({
  [RSAA]: {
    endpoint: `admin/integration?farmIds=${farmIds}&`,
    method: methods.PUT,
    module: modules.SENTINEL,
    body: JSON.stringify(payload),
    types: [
      types.UPDATE_ADMIN_INTEGRATION,
      types.UPDATE_ADMIN_INTEGRATION_SUCCESS,
      types.UPDATE_ADMIN_INTEGRATION_ERROR,
    ],
  },
});

export const getParcelsByName = (
  context: string,
  search: string,
  farmId?: string,
) => ({
  [RSAA]: {
    endpoint: `admin?include-disabled=true&per-page=50&farmIds=${farmId}&localNameBlockNr=${search}&`,
    method: methods.GET,
    context,
    module: modules.SENTINEL,
    types: [
      types.GET_ADMIN_PARCELS,
      types.GET_ADMIN_PARCELS_SUCCESS,
      types.GET_ADMIN_PARCELS_ERROR,
    ],
  },
});

export const updateParcelServices = ({
  farmId,
  payload,
  versionUrl,
}: {
  farmId: string;
  payload: ParcelsConfigUpdateRequest;
  versionUrl: string;
}) => ({
  [RSAA]: {
    endpoint: `admin/${versionUrl}?farmIds=${farmId}&`,
    method: methods.PUT,
    module: modules.SENTINEL,
    body: JSON.stringify(payload),
    types: [
      types.UPDATE_PARCEL_SERVICES,
      types.UPDATE_PARCEL_SERVICES_SUCCESS,
      types.UPDATE_PARCEL_SERVICES_ERROR,
    ],
  },
});

export const resetAdminParcels = (context: string) => ({
  type: types.RESET_ADMIN_PARCELS,
  meta: {
    context,
  },
});

export const getAdminStatistics = ({
  farmId,
  version,
}: {
  farmId: string;
  version: string;
}) => ({
  [RSAA]: {
    endpoint: `admin/${version}statistics?farmIds=${farmId}&`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_ADMIN_STATS,
      types.GET_ADMIN_STATS_SUCCESS,
      types.GET_ADMIN_STATS_ERROR,
    ],
  },
});

const getFarmPfVersionQuery = (params: GetFarmPfVersionParams) =>
  queryOptions({
    queryKey: [...apiKeys.general, ...Object.values(params)],
    queryFn: async ({ signal }) => {
      const response = await getFarmPfVersion(params, { signal });
      return response;
    },
    staleTime: staleTime.Infinity,
  });

export { getFarmPfVersionQuery };
