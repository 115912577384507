import React, { useEffect } from "react";

import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { get, isNil } from "lodash";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getAdminStats,
  isFetchingAdminStatsIsFetching,
  itemIsUpdatingAdminParcels,
} from "../../../../../shared/api/sentinel/admin/admin.selectors";
import {
  getSentinelAdminStatsFarm,
  getSentinelAdminStatsOrder,
  getSentinelAdminStatsOrderBy,
} from "../../selectors/sentinelStats.selectors";

import * as sortOrder from "../../../../../shared/constants/sortOrder.constants";

import { getAdminStatistics } from "../../../../../shared/api/sentinel/admin/admin.api";
import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableCellNumber from "../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";
import { useTypedIntl } from "../../../../../shared/hooks/useTypedIntl";

import { Body } from "./Body";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { TableBodyColumn, TableBodyRow } from "./types";
import { useSharedTableStyles } from "./useSharedTableStyles";

interface Props {
  versionPF: string;
}

const columns: TableBodyColumn[] = [
  {
    key: "name",
    label: <FormattedMessage id="SentinelAdminStatsPanel.table.column.name" />,
    sortable: true,
    style: {
      width: "40%",
      paddingLeft: 10,
    },
  },
  {
    key: "parcel",
    label: (
      <FormattedMessage id="SentinelAdminStatsPanel.table.column.parcel" />
    ),
    sortable: false,
    align: "right",
    style: { width: "30%" },
  },
  {
    key: "area",
    label: <FormattedMessage id="SentinelAdminStatsPanel.table.column.area" />,
    sortable: false,
    align: "right",
    style: { width: "30%", paddingRight: 10 },
    renderBodyCell: (row: TableBodyRow) => {
      if (isNil(row?.area)) {
        return <>-</>;
      } else if (!row?.area) {
        return <>0</>;
      } else {
        return (
          <CfTableCellNumber>
            <CfFormattedNumber value={row?.area} />
          </CfTableCellNumber>
        );
      }
    },
  },
];

const StatsPanelTable = (props: Props) => {
  const { versionPF } = props;
  const dispatch = useDispatch();
  const farm = useSelector(getSentinelAdminStatsFarm);
  const order = useSelector(getSentinelAdminStatsOrder);
  const orderBy = useSelector(getSentinelAdminStatsOrderBy);
  const fetchedData = useSelector(getAdminStats);
  const isFetching = useSelector(isFetchingAdminStatsIsFetching);
  const parcelIsUpdating = useSelector(itemIsUpdatingAdminParcels);

  const sharedClasses = useSharedTableStyles();
  const intl = useTypedIntl();

  const classes = useStyles();
  const version = versionPF === "2_0_0" ? "v2/" : "";

  useEffect(() => {
    if (farm && !parcelIsUpdating) {
      dispatch(getAdminStatistics({ farmId: farm.id, version }));
    }
  }, [order, orderBy, farm, dispatch, parcelIsUpdating, version]);

  const isInit = farm && fetchedData && !isFetching;

  const data: TableBodyRow[] = isInit
    ? [
        {
          name: intl.formatMessage({
            id: "SentinelAdminStatsPanel.table.row.monitoringGisat",
          }),
          parcel: fetchedData?.monitoringGisat,
          area: fetchedData?.monitoringGisatArea,
        },
        {
          name: intl.formatMessage({
            id: "SentinelAdminStatsPanel.table.row.monitoringPlanet",
          }),
          parcel: fetchedData?.monitoringPlanet,
          area: fetchedData?.monitoringPlanetArea,
        },
        {
          name: intl.formatMessage({
            id: "SentinelAdminStatsPanel.table.row.zoneManagement",
          }),
          parcel: fetchedData?.managementZones,
          area: fetchedData?.managementZonesArea,
        },
        {
          name: intl.formatMessage({
            id: "SentinelAdminStatsPanel.table.row.yieldMaps",
          }),
          parcel: fetchedData?.yieldMaps,
          area: fetchedData?.yieldMapsArea,
        },
      ]
    : [];

  data.sort((a, b) => {
    if (order === sortOrder.ASC) {
      return get(a, orderBy) > get(b, orderBy) ? 1 : -1;
    } else {
      return get(a, orderBy) < get(b, orderBy) ? 1 : -1;
    }
  });

  return (
    <Paper className={classes.paper}>
      <Header
        classes={sharedClasses}
        columns={columns}
        order={order}
        orderBy={orderBy}
      />
      <Scrollbars autoHeight={true} autoHeightMax={400}>
        <Body
          classes={sharedClasses}
          columns={columns}
          data={data}
          isFetching={isFetching}
        />
      </Scrollbars>
      <Footer
        classes={sharedClasses}
        columns={columns}
        fetchedData={isInit ? fetchedData : undefined}
        versionPF={versionPF}
      />
    </Paper>
  );
};

export const useStyles = makeStyles(() => ({
  paper: {
    overflowX: "auto",
  },
}));

export { StatsPanelTable };
