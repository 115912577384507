import { queryOptions } from "@tanstack/react-query";

import { getSeedApplicationsAtDate } from "../../../../generated/api/agroevidence";

const seedApplicationKey = ["seedAplications"];

const seedApplicationAtDateQuery = ({
  params,
}: {
  params: Parameters<typeof getSeedApplicationsAtDate>[0];
}) =>
  queryOptions({
    queryKey: [...seedApplicationKey, ...Object.values(params)],
    queryFn: async ({ signal }) => {
      const data = await getSeedApplicationsAtDate(params, { signal });
      return data.data;
    },
  });

export { seedApplicationAtDateQuery };
