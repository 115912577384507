import React from "react";

import { queryOptions, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useDispatch, useSelector } from "react-redux";

import { getPrecisionParcelListTextFilter } from "../../../core/precision/selectors/precisionParcelList.selectors";
import { getHasNoPrecision } from "../../../shared/api/sentinel/precision/precision.selectors";

import {
  setHasNoPrecision,
  setPrecisionParcelsRequest,
  setPrecisionParcelsSuccess,
  setTaskdataIsVisible,
} from "../../../core/precision/actions/precision.actions";

import { LANGUAGE_ID } from "../../../shared/lang/lang.constants";

import { usePfVersion } from "../../../core/precision/containers/api/usePfVersion";
import NoPrecision from "../../../core/precision/containers/NoPrecision";
import {
  getIntegration,
  getSatelliteParcels,
  IntegrationType,
} from "../../../generated/api/satellite";
import { useFarmIds } from "../../../shared/api/client.utils";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";

import { ParcelStatus } from "../../../shared/api/agroevidence/agroevidence.types";

type Props = {
  langId: LANGUAGE_ID;
};

const ServiceGuard = ({ children, langId }: React.PropsWithChildren<Props>) => {
  const dispatch = useDispatch();
  const textFilter = useSelector(getPrecisionParcelListTextFilter);

  const parcels = useQuery(parcelsQuery(useFarmIds()));
  const integrations = useQuery(integrationsQuery(useFarmIds()));
  const currentHasNoPrecision = useSelector(getHasNoPrecision);

  const { isLoading: pfVersionIsLoading } = usePfVersion();

  if (parcels.isPending) {
    dispatch(setPrecisionParcelsRequest);
    return <CfLoader />;
  }

  if (integrations.isPending || pfVersionIsLoading) {
    return <CfLoader />;
  }

  if (parcels.isError) {
    return <CfErrorPage error={parcels.error} />;
  }

  if (
    integrations.isError &&
    (integrations.error as AxiosError).response?.status === 403
  ) {
    // if (integrations.isError && integrations.error.status === 403) {
    dispatch(setTaskdataIsVisible(false));
    return <CfErrorPage error={integrations.error} />;
  }

  if (integrations.isError || integrations.isSuccess) {
    dispatch(setTaskdataIsVisible(true));
  }

  if (parcels.isSuccess) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch(setPrecisionParcelsSuccess(parcels.data.data as any));
  }

  const hasNoPrecision = parcels.data.data.length === 0 && !textFilter.length;
  if (currentHasNoPrecision !== hasNoPrecision) {
    dispatch(setHasNoPrecision(hasNoPrecision));
  }

  if (hasNoPrecision) {
    return <NoPrecision langId={langId} />;
  }

  return <>{children}</>;
};

const parcelsQuery = ({ farmIds }: { farmIds: string }) =>
  queryOptions({
    queryKey: ["precision-farming", "parcels", farmIds],
    queryFn: async ({ signal }) => {
      const data = await getSatelliteParcels(
        {
          farmIds,
        },
        { signal },
      );
      const openParcels = data.data.filter(
        (item) =>
          item.status === ParcelStatus.OPEN &&
          (item.historyPotential ||
            item.biomonitoring ||
            item.soilSamples ||
            item.internalBiomonitoring ||
            item.managementZones),
      );

      return { ...data, data: openParcels };
    },
    staleTime: Infinity,
  });

const integrationsQuery = ({ farmIds }: { farmIds: string }) =>
  queryOptions({
    queryKey: ["precision-farming", "integrations", farmIds],
    queryFn: ({ signal }) =>
      getIntegration(
        {
          type: IntegrationType.TASKDATA,
          farmIds,
        },
        { signal },
      ),
  });

export { ServiceGuard };
