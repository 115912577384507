import { queryOptions } from "@tanstack/react-query";

import { logbookByMachines } from "../../../generated/api/telematics";
import { getCountedData } from "../../../shared/api/client.utils";

const machinesQuery = (params: Parameters<typeof logbookByMachines>[0]) =>
  queryOptions({
    queryKey: ["telematics", "machines", ...Object.values(params)],
    queryFn: async ({ signal }) => {
      const machines = await logbookByMachines(params, { signal });
      return getCountedData(machines);
    },
  });

export { machinesQuery };
