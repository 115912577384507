import React, { useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  getIsFetchingParcelActivities,
  getParcelActivitiesOrder,
  getParcelActivitiesOrderBy,
  getParcelActivitiesPage,
  getParcelActivitiesTotalCount,
  getParcelActivitiesRowsPerPage,
  getParcelActivitiesSelected,
  getSelectedOnPage,
  getParcelActivitiesDateFilter,
  getParcelActivitiesAdvancedFilter,
  getProcessedActivities,
} from "../../selectors/parcelActivities.selectors";

import {
  fetchParcelActivities,
  resetParcelActivities,
} from "../../actions/parcelActivities.actions";

import { NAMESPACE as namespace } from "../../reducer/parcelActivities.reducer";

import { ColumnData } from "../../../../../../catalogues/helpers/tableHelpers";
import CfTableFooter from "../../../../../../common/components/CfTableFooter/CfTableFooter";
import { useCfTableFooter } from "../../../../../../common/components/CfTableFooter/useCfTableFooter";
import CfTableHead from "../../../../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../../../../common/components/CfTableHead/useCfTableHead";
import CfTableBody from "../../../../../../shared/components/tables/CfTableBody/CfTableBody";
import CfTableBodyEmpty from "../../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { getColDesc } from "../../../../../../shared/misc/helper";
import { COLOR_GREY } from "../../../../../../theme";

const ELEMENT_UNIT = "(kg/ha)   ";

const createColumns = (): {
  "dateStart,id": ColumnData;
  "actionType.localizedNames.value": ColumnData;
  source: ColumnData;
  activityCrop: ColumnData;
  area: ColumnData;
  accountableN?: ColumnData;
  material: ColumnData;
  dose: ColumnData;
  dosePerHectare: ColumnData;
  n: ColumnData;
  p2o5: ColumnData;
  k2o: ColumnData;
  mgo: ColumnData;
  cao: ColumnData;
  s: ColumnData;
} => ({
  "dateStart,id": getColDesc(true, <FormattedMessage id="common.date" />),
  "actionType.localizedNames.value": getColDesc(
    false,
    <FormattedMessage id="common.action-name" />,
  ),
  source: getColDesc(false, <FormattedMessage id="common.source" />),
  activityCrop: getColDesc(false, <FormattedMessage id="common.crop" />),
  area: getColDesc(
    false,
    <span>
      <div>
        <FormattedMessage id="common.activityArea" />
      </div>
      <div style={{ color: COLOR_GREY[400] }}>
        <FormattedMessage id="common.parcelArea" />
      </div>
    </span>,
    undefined,
    "right",
  ),
  accountableN: getColDesc(
    false,
    <span>
      <FormattedMessage id="common.accountableN" />
      {` ${ELEMENT_UNIT}`}
    </span>,
    undefined,
    "right",
  ),
  material: getColDesc(
    false,
    <FormattedMessage id="common.material" />,
    undefined,
    "right",
  ),
  dose: getColDesc(
    false,
    <FormattedMessage id="common.totalDose" />,
    undefined,
    "right",
  ),
  dosePerHectare: getColDesc(
    false,
    <FormattedMessage id="common.dosePerHectare" />,
    undefined,
    "right",
  ),
  n: getColDesc(false, <span>N{` ${ELEMENT_UNIT}`}</span>, undefined, "right"),
  p2o5: getColDesc(
    false,
    <span>
      P<sub>2</sub>O<sub>5</sub>
      {` ${ELEMENT_UNIT}`}
    </span>,
    undefined,
    "right",
  ),
  k2o: getColDesc(
    false,
    <span>
      K<sub>2</sub>O{` ${ELEMENT_UNIT}`}
    </span>,
    undefined,
    "right",
  ),
  mgo: getColDesc(false, `MgO ${ELEMENT_UNIT}`, undefined, "right"),
  cao: getColDesc(false, `CaO ${ELEMENT_UNIT}`, undefined, "right"),
  s: getColDesc(false, `S ${ELEMENT_UNIT}`, undefined, "right"),
});

type Props = {
  farmId: string;
  parcelId: string;
};

export const ParcelActivitiesTable = ({ farmId, parcelId }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const activities = useSelector(getProcessedActivities);
  const isFetching = useSelector(getIsFetchingParcelActivities);
  const count = useSelector(getParcelActivitiesTotalCount);
  const page = useSelector(getParcelActivitiesPage);
  const order = useSelector(getParcelActivitiesOrder);
  const orderBy = useSelector(getParcelActivitiesOrderBy);
  const rowsPerPage = useSelector(getParcelActivitiesRowsPerPage);
  const selected = useSelector(getParcelActivitiesSelected);
  const selectedOnPage = useSelector(getSelectedOnPage);
  const dateFilter = useSelector(getParcelActivitiesDateFilter);
  const advancedFilter = useSelector(getParcelActivitiesAdvancedFilter);

  const columns = createColumns();
  const { onPageChange, onRowsPerPageChange } = useCfTableFooter(namespace);
  const { onSelect, onSort } = useCfTableHead(namespace);

  useEffect(
    () => () => {
      dispatch(resetParcelActivities());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    dispatch(fetchParcelActivities(parcelId, false));
    // eslint-disable-next-line max-len
  }, [
    page,
    rowsPerPage,
    order,
    orderBy,
    dateFilter,
    parcelId,
    advancedFilter,
    dispatch,
  ]);

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        items={activities}
        onSelect={onSelect}
        onSort={onSort}
        order={order}
        orderBy={orderBy}
        selected={selected}
        selectedOnPage={selectedOnPage}
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}
      {activities.length && !isFetching ? (
        activities.map((row) => (
          <CfTableBody
            columns={columns}
            hover
            key={row.id}
            namespace={namespace}
            row={row}
            selected={selected}
            onClick={() => {
              history.push(`/farm/${farmId}/actions/${row.id}`);
              history.go(0);
            }}
          />
        ))
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

export default ParcelActivitiesTable;
