import React from "react";

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import {
  SatelliteParcelV2,
  ZoneResponse,
} from "../../../generated/api/satellite";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import CfStatusPanel from "../../../shared/components/common/CfStatusPanel/CfStatusPanel";
import SatelliteIcon from "../../../shared/icons/SatelliteIcon";
import BioMonitoringImagesOverallError from "../components/BioMonitoring/BioMonitoringImagesOverallError";
import { IndiceType } from "../selectors/indices";

import { ChartSection } from "./biomonitoring/ChartSection/ChartSection";
import { TilesContainerV2 } from "./biomonitoring/TilesContainerV2";
import { IndexTypeDataV2Type } from "./biomonitoring/types";
import { useChartDesignData } from "./biomonitoring/useChartDesignData";

import { Crop } from "../../../shared/api/sentinel/managementZones/managementZones.types";
import {
  CropIntervalsV2,
  imgExtent,
} from "../../../shared/api/sentinel/monitoring/monitoring.types";

export type MapImage = {
  extent: number[];
  url: string;
};

export type GeometriesV2 = {
  color?: string;
  geometry: {
    coordinates: number[][][][];
    type: string;
  };
  zoneNumber: number;
};

export interface MonitoringCropIntervalsV2 extends CropIntervalsV2 {
  id: string;
  name: string;
}

export interface ChartDataOrMonitoringDataV2 {
  crop?: Crop;
  dateFrom: string;
  dateTo: string;
  imgExtent: imgExtent;
  imgPath: string;
  indexType: IndiceType;
  status?: string;
  value: number;
  tooltipValue?: number;
  zones: ZoneResponse[];
  average: number;
}

interface Props {
  parcelId: string;
  hookData: IndexTypeDataV2Type;
  index: IndiceType;
  lastUpdate?: string;
  parcel?: SatelliteParcelV2;
}

const BioMonitoringV2 = ({
  hookData,
  index,
  lastUpdate,
  parcel,
  parcelId,
}: Props) => {
  const { chartDesignData } = useChartDesignData(index);

  const {
    data,
    graphData,
    infoDialogContent,
    isFetching,
    lastImageDateTo,
    overallImagesError,
  } = hookData;

  const classes = useStyles(isFetching);

  return (
    <>
      {isFetching ? (
        <CfLoader />
      ) : (
        <>
          {overallImagesError && (
            <BioMonitoringImagesOverallError
              lastImageDateTo={lastImageDateTo}
              overallImagesError={overallImagesError}
            />
          )}
          {!data.length && (
            <CfStatusPanel
              fullWidth={true}
              grey={true}
              icon={SatelliteIcon}
              title={<FormattedMessage id="BioMonitoring.imagesProcessed" />}
            />
          )}
          {!overallImagesError && !!data.length && (
            <Grid className={classes.wrapper} container spacing={1}>
              <ChartSection
                chartDesignData={chartDesignData}
                graphData={graphData}
                index={index}
                isFetchingData={isFetching}
              />
              <TilesContainerV2
                index={index}
                infoDialogContent={infoDialogContent}
                lastUpdate={lastUpdate}
                monitoringData={data}
                parcel={parcel}
                parcelId={parcelId}
              />
            </Grid>
          )}
        </>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    marginBottom: 10,
    height: (isFetching) => (isFetching ? "90%" : "auto"),
  },
}));

export { BioMonitoringV2 };
