import { queryOptions } from "@tanstack/react-query";

import {
  getV2SatelliteParcel,
  GetV2SatelliteParcelParams,
} from "../../generated/api/satellite";
import { getCountedData } from "../../shared/api/client.utils";
import { staleTime } from "../../shared/query-client";

const satelliteServicesKey = ["satellite", "precisionV2"];

const satelliteParcelQuery = (
  parcelId: string,
  params: GetV2SatelliteParcelParams,
) =>
  queryOptions({
    queryKey: [...satelliteServicesKey, params, parcelId],
    queryFn: async ({ signal }) => {
      const data = await getV2SatelliteParcel(parcelId, params, { signal });
      return getCountedData(data);
    },
    staleTime: staleTime.Infinity,
    placeholderData: (prev) => prev,
  });

export { satelliteParcelQuery };
