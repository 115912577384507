import React, { FC } from "react";

import { FormattedMessage, useIntl } from "react-intl";

import CfStatusPanel from "../../../../../shared/components/common/CfStatusPanel/CfStatusPanel";
import { links } from "../../../../../shared/constants/links";
import useWidth from "../../../../../shared/hooks/useWidth";
import SatelliteIcon from "../../../../../shared/icons/SatelliteIcon";
import { AnyTodo } from "../../../../../types";

import {
  IndexType,
  VariableApplicationZoneType,
} from "../../../../../shared/api/satellite/satellite.types";

interface Props {
  langId?: string;
  source: AnyTodo;
  type: IndexType | VariableApplicationZoneType;
  messageId?: string;
}

const VaMapSourceNotAvailable: FC<Props> = ({
  langId = "cs-CZ",
  messageId,
  source = null,
  type,
}) => {
  const width = useWidth();
  const intl = useIntl();

  const isPurchased = Array.isArray(source) && !source.length;
  const isSoilSamples = type === "SAMPLES";

  const getMessageId = () => {
    if (isPurchased && isSoilSamples)
      return "VaMapSourceNotAvailable.soilSamples.needToWait";
    else if (isPurchased && !isSoilSamples)
      return "VaMapSourceNotAvailable.needToWait";
    else return "VaMapSourceNotAvailable.needToBuy";
  };

  const getValuesType = () => {
    if (type === IndexType.CAB || type === IndexType.LAI) return "monitoring";
    else
      return intl
        .formatMessage({ id: `VaMapSourceDialog.${type}` })
        .toLowerCase();
  };

  return (
    <CfStatusPanel
      dense={true}
      elevation={0}
      fullWidth={true}
      grey={true}
      horizontal={width !== "xs"}
      icon={SatelliteIcon}
      content={
        <FormattedMessage
          id={messageId || getMessageId()}
          values={{
            type: getValuesType(),
          }}
        />
      }
      linkHref={
        langId === "cs-CZ"
          ? links.cs.precisionFarming
          : links.en.precisionFarming
      }
      linkText={
        isPurchased ? null : <FormattedMessage id="common.findOutMore" />
      }
    />
  );
};

export { VaMapSourceNotAvailable };
