import React from "react";

import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { AddIcon } from "../../../../shared/icons/AddIcon";
import { NewPrescriptionMapsIcon } from "../../../../shared/icons/NewPrescriptionMapsIcon";

const NewPrescriptionMapsPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.icon}>
        <NewPrescriptionMapsIcon />
      </div>
      <div className={classes.text}>
        <FormattedMessage id="PrescriptionMaps.noMapsInfo" />
      </div>
      <button className={classes.button}>
        <div className={classes.buttonIcon}>
          <AddIcon />
        </div>
        <div>
          <FormattedMessage id="PrescriptionMaps.newMaps" />
        </div>
      </button>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "148px 0 0 0",
  },
  icon: {
    borderRadius: "200px",
    background: "#FFF",
    width: "212px",
    height: "212px",
    flexShrink: 0,
    padding: "22px 32px 42px 32px",
  },
  text: {
    color: "#333",
    fontWeight: 400,
    padding: "24px",
  },
  button: {
    border: "none",
    padding: "12px 16px",
    margin: "8px",
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    borderRadius: "8px",
    background: "#00A179",
    boxShadow: "0px 8px 8px 0px rgba(0, 0, 0, 0.16)",
    display: "flex",
    flexDirection: "row",
    "&:hover": {
      background: "#3F8E6D",
    },
  },
  buttonIcon: {
    margin: "0 8px 0 0",
    height: "24px",
  },
}));

export { NewPrescriptionMapsPage };
