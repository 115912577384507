import React from "react";

import { Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { FormattedDate, FormattedMessage } from "react-intl";

import {
  ApplicationBufferType,
  PlantProtectionDetailRegistrationApplicationTo,
} from "../../../../../generated/api/agroevidence";
import { COLOR_GREY } from "../../../../../theme";

export const ApplicationRowDetails = ({
  canUpdate,
  data,
}: {
  data: PlantProtectionDetailRegistrationApplicationTo;
  canUpdate: boolean;
}) => {
  const classes = useStyles();

  const renderBufferProtectionZone = (type: string) => {
    const buffer = data.buffers.find((buffer) => buffer.type === type);

    if (!buffer)
      return (
        <FormattedMessage id="Catalogues.plantProtection.application.editDialog.noProtectionZone" />
      );
    return (
      <FormattedMessage
        id="Catalogues.plantProtection.application.editDialog.protectionZoneLevel"
        values={{ zone: buffer?.protectionLevel }}
      />
    );
  };

  const renderProtectionZone = (type: string) => {
    const buffer = data.buffers.find((buffer) => buffer.type === type);

    if (!buffer) return "0 m";

    if (!buffer.buffer) {
      return "-";
    }

    return `${buffer.buffer} m`;
  };

  const renderBufferByDriftReduction = (
    driftReduction: number,
    type: string,
  ) => {
    const buffer = data.buffers.find(
      (buffer) =>
        buffer.type === type && buffer.driftReduction === driftReduction,
    );

    if (!buffer) return "0 m";
    return buffer.isAllowed ? (
      `${buffer.buffer} m`
    ) : (
      <FormattedMessage id="Catalogues.plantProtection.application.editDialog.reductionNotAllowed" />
    );
  };

  const renderIntervalString = (
    minInterval?: number,
    maxInterval?: number,
    translationId?: string,
  ) => {
    if (minInterval || maxInterval) {
      const intervalString = [minInterval, maxInterval]
        .filter(Boolean)
        .join(" - ");

      return (
        <span>
          {intervalString}{" "}
          {translationId ? <FormattedMessage id={translationId} /> : ""}
        </span>
      );
    }

    return <span>-</span>;
  };

  return (
    <Grid className={classes.container} container>
      <Grid
        container
        direction="column"
        item
        rowSpacing={1}
        xs={canUpdate ? 3 : 2}
      >
        <Grid container direction="row" item justifyContent="start">
          <Typography variant="body2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.dosage" />
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.minDose" />
          </Typography>
          <Typography variant="subtitle2">
            {data.minDose ?? "-"} {data.unit ?? ""}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.maxDose" />
          </Typography>
          <Typography variant="subtitle2">
            {data.maxDose ?? "-"} {data.unit ?? ""}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.minDoseWater" />
          </Typography>
          <Typography variant="subtitle2">
            {data.minDoseWater ?? "-"} {data.unitWater ?? ""}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.maxDoseWater" />
          </Typography>
          <Typography variant="subtitle2">
            {data.maxDoseWater ?? "-"} {data.unitWater ?? ""}
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="column" item rowSpacing={1} xs={3}>
        <Grid container direction="row" item justifyContent="start">
          <Typography variant="body2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.usage" />
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.minmaxBbch" />
          </Typography>
          <Typography variant="subtitle2">
            {renderIntervalString(data.minBbch, data.maxBbch)}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.interval" />
          </Typography>
          <Typography variant="subtitle2">
            {renderIntervalString(
              data.minInterval,
              data.maxInterval,
              "Catalogues.plantProtection.application.editDialog.dayUnit",
            )}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.protectionPeriod" />
          </Typography>
          <Typography variant="subtitle2">
            {data.protectionPeriod ?? "-"}{" "}
            {data.protectionPeriod && (
              <FormattedMessage id="Catalogues.plantProtection.application.editDialog.dayUnit" />
            )}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.allowedApplications" />
          </Typography>
          <Typography variant="subtitle2">
            {data.allowedApplications ?? "-"}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="column"
        item
        rowSpacing={1}
        xs={canUpdate ? 3 : 2}
      >
        <Grid container direction="row" item justifyContent="start">
          <Typography variant="body2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.distanceEdge" />
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.withoutReduction" />
          </Typography>
          <Typography variant="subtitle2">
            {renderBufferByDriftReduction(0, "Boundary")}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.reduction50" />
          </Typography>
          <Typography variant="subtitle2">
            {renderBufferByDriftReduction(50, "Boundary")}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.reduction75" />
          </Typography>
          <Typography variant="subtitle2">
            {renderBufferByDriftReduction(75, "Boundary")}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.reduction90" />
          </Typography>
          <Typography variant="subtitle2">
            {renderBufferByDriftReduction(90, "Boundary")}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="column"
        item
        rowSpacing={1}
        xs={canUpdate ? 3 : 2}
      >
        <Grid container direction="row" item justifyContent="start">
          <Typography variant="body2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.distanceWater" />
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.withoutReduction" />
          </Typography>
          <Typography variant="subtitle2">
            {renderBufferByDriftReduction(0, "Water")}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.reduction50" />
          </Typography>
          <Typography variant="subtitle2">
            {renderBufferByDriftReduction(50, "Water")}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.reduction75" />
          </Typography>
          <Typography variant="subtitle2">
            {renderBufferByDriftReduction(75, "Water")}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.reduction90" />
          </Typography>
          <Typography variant="subtitle2">
            {renderBufferByDriftReduction(90, "Water")}
          </Typography>
        </Grid>
      </Grid>

      {!canUpdate && (
        <Grid container direction="column" item rowSpacing={1} xs={3}>
          <Grid container direction="row" item justifyContent="start">
            <Typography variant="body2">
              <FormattedMessage id="Catalogues.plantProtection.application.validity" />
            </Typography>
          </Grid>
          <Grid container direction="row" item justifyContent="start">
            <Typography className={classes.label} variant="subtitle2">
              <FormattedMessage id="Catalogues.plantProtection.applications.legalStart" />
            </Typography>
            <Typography variant="subtitle2">
              {data.legalStart ? (
                <FormattedDate value={data.legalStart} />
              ) : (
                "-"
              )}
            </Typography>
          </Grid>
          <Grid container direction="row" item justifyContent="start">
            <Typography className={classes.label} variant="subtitle2">
              <FormattedMessage id="Catalogues.plantProtection.applications.legalEnd" />
            </Typography>
            <Typography variant="subtitle2">
              {data.legalEnd ? <FormattedDate value={data.legalEnd} /> : "-"}
            </Typography>
          </Grid>
          <Grid container direction="row" item justifyContent="start">
            <Typography className={classes.label} variant="subtitle2">
              <FormattedMessage id="Catalogues.plantProtection.applications.marketEnd" />
            </Typography>
            <Typography variant="subtitle2">
              {data.marketEnd ? <FormattedDate value={data.marketEnd} /> : "-"}
            </Typography>
          </Grid>
          <Grid container direction="row" item justifyContent="start">
            <Typography className={classes.label} variant="subtitle2">
              <FormattedMessage id="Catalogues.plantProtection.applications.usableUntil" />
            </Typography>
            <Typography variant="subtitle2">
              {data.validUntil ? (
                <FormattedDate value={data.validUntil} />
              ) : (
                "-"
              )}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid
        className={classes.marginTop}
        container
        direction="column"
        item
        rowSpacing={1}
        xs={canUpdate ? 3 : 2}
      >
        <Grid container direction="row" item justifyContent="start">
          <Typography variant="body2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.waterProtectionZones" />
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.surfaceProtectionZone" />
          </Typography>
          <Typography variant="subtitle2">
            {renderBufferProtectionZone("SurfaceWaterProtectionZones")}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.groundProtectionZone" />
          </Typography>
          <Typography variant="subtitle2">
            {renderBufferProtectionZone("GroundWaterProtectionZones")}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        className={classes.marginTop}
        container
        direction="column"
        item
        rowSpacing={1}
        xs={canUpdate ? 3 : 2}
      >
        <Grid container direction="row" item justifyContent="start">
          <Typography variant="body2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.populationProtectionZones" />
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.populationProtectionZones" />
          </Typography>
          <Typography variant="subtitle2">
            {renderProtectionZone("PopulationProtectionZones")}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        className={classes.marginTop}
        container
        direction="column"
        item
        rowSpacing={1}
        xs={canUpdate ? 3 : 2}
      >
        <Grid container direction="row" item justifyContent="start">
          <Typography variant="body2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.slopingLand" />
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.slopeWater" />
          </Typography>
          <Typography variant="subtitle2">
            {renderProtectionZone(ApplicationBufferType.SlopeWater)}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="start">
          <Typography className={classes.label} variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.slopeVegetationStrip" />
          </Typography>
          <Typography variant="subtitle2">
            {renderProtectionZone(ApplicationBufferType.SlopeVegetationStrip)}
          </Typography>
        </Grid>
      </Grid>

      <Grid className={classes.noteRow} container direction="row" item xs={12}>
        <Typography className={classes.label} variant="subtitle2">
          <FormattedMessage id="common.note" />:
        </Typography>
        <Typography variant="subtitle2"> {data.notes ?? "-"}</Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.grey[100],
    padding: 20,
  },
  noteRow: {
    paddingTop: 20,
  },
  label: {
    paddingRight: 10,
    color: COLOR_GREY[500],
  },
  marginTop: {
    marginTop: "12px",
  },
}));
