import { AnyAction } from "redux";

import * as types from "./parcels.constants";
import * as typesV2 from "../../sentinel/variableApplication/variableApplicationV2.constants";

const initialState = {
  items: [],
  isFetchingItems: false,
  itemsError: undefined,
  totalCount: 0,
  itemsSuggestion: [],
  isFetchingItemsSuggestion: false,
  statistics: undefined,
  isFetchingStatistics: false,
  item: undefined,
  isFetchingItem: false,
  itemError: undefined,
  isFetchingCenters: false,
  centers: [],
  itemSeedApplication: [],
  isFetchingItemSeedApplication: false,
  itemPredecessors: [],
  isFetchingItemPredecessors: false,
  itemEagriRestrictions: undefined,
  isFetchingItemEagriRestrictions: false,
  isExportingData: false,
  isFetchingLandUse: false,
  landUse: [],
  itemSubtractionsGeometry: undefined,
  isFetchingItemSubtractionsGeometry: false,
  itemSubtractionsArea: undefined,
  isFetchingItemSubtractionsArea: false,
  focusedRow: undefined,
};

export default (reducerContext?: string) =>
  (state = initialState, action: AnyAction) => {
    const actionContext = action.meta?.context;
    if (actionContext && actionContext !== reducerContext) return state;

    switch (action.type) {
      case types.GET_PARCELS_REQUEST:
      case typesV2.GET_PARCELS_REQUEST:
        return {
          ...state,
          isFetchingItems: true,
          itemsError: undefined,
        };
      case types.GET_PARCELS_SUCCESS:
      case typesV2.GET_PARCELS_SUCCESS:
        return {
          ...state,
          isFetchingItems: false,
          items: action.payload,
          totalCount: Number(action.meta.headers.get("X-Total-Count")),
        };
      case types.GET_PARCELS_ERROR:
      case typesV2.GET_PARCELS_ERROR:
        return {
          ...state,
          isFetchingItems: false,
          itemsError: action.payload,
          totalCount: 0,
        };
      case types.RESET_PARCELS:
      case typesV2.RESET_PARCELS:
        return {
          ...state,
          items: [],
          totalCount: 0,
        };
      case types.GET_PARCELS_SUGGESTIONS_REQUEST:
        return {
          ...state,
          isFetchingItemsSuggestion: true,
        };
      case types.GET_PARCELS_SUGGESTIONS_SUCCESS:
        return {
          ...state,
          itemsSuggestion: action.payload,
          isFetchingItemsSuggestion: false,
        };
      case types.GET_PARCELS_SUGGESTIONS_ERROR:
        return {
          ...state,
          isFetchingItemsSuggestion: false,
        };
      case types.RESET_PARCELS_SUGGESTIONS:
        return {
          ...state,
          itemsSuggestion: [],
          isFetchingItemsSuggestion: false,
        };

      case types.ADD_PARCELS:
        return {
          ...state,
          items: state.items.concat(action.payload),
        };

      case types.GET_PARCELS_STATISTICS_REQUEST:
        return {
          ...state,
          isFetchingStatistics: true,
        };
      case types.GET_PARCELS_STATISTICS_SUCCESS:
        return {
          ...state,
          isFetchingStatistics: false,
          statistics: action.payload,
        };
      case types.GET_PARCELS_STATISTICS_ERROR:
        return {
          ...state,
          isFetchingStatistics: false,
        };
      case types.RESET_PARCELS_STATISTICS:
        return {
          ...state,
          isFetchingStatistics: false,
          statistics: undefined,
        };

      case types.GET_PARCELS_LAND_USE_REQUEST:
        return {
          ...state,
          isFetchingLandUse: true,
        };
      case types.GET_PARCELS_LAND_USE_SUCCESS:
        return {
          ...state,
          isFetchingLandUse: false,
          landUse: action.payload,
        };
      case types.GET_PARCELS_LAND_USE_ERROR:
        return {
          ...state,
          isFetchingLandUse: false,
        };
      case types.RESET_PARCELS_LAND_USE:
        return {
          ...state,
          isFetchingLandUse: false,
          landUse: [],
        };

      case types.GET_CENTERS_REQUEST:
        return {
          ...state,
          isFetchingCenters: true,
        };
      case types.GET_CENTERS_SUCCESS:
        return {
          ...state,
          centers: action.payload,
          isFetchingCenters: false,
        };
      case types.GET_CENTERS_ERROR:
        return {
          ...state,
          isFetchingCenters: false,
        };
      case types.RESET_CENTERS:
        return {
          ...state,
          isFetchingCenters: false,
          centers: [],
        };

      case types.GET_PARCEL_REQUEST:
      case types.GET_PARCEL_V2_REQUEST:
        return {
          ...state,
          isFetchingItem: true,
          itemError: undefined,
        };
      case types.GET_PARCEL_SUCCESS:
      case types.GET_PARCEL_V2_SUCCESS:
        return {
          ...state,
          isFetchingItem: false,
          item: action.payload,
        };
      case types.GET_PARCEL_ERROR:
      case types.GET_PARCEL_V2_ERROR:
        return {
          ...state,
          isFetchingItem: false,
          itemError: action.payload,
        };
      case types.RESET_PARCEL:
      case types.RESET_PARCEL_V2:
        return {
          ...state,
          item: undefined,
          itemError: undefined,
        };

      case types.GET_PARCEL_SEED_APPLICATION_REQUEST:
        return {
          ...state,
          isFetchingItemSeedApplication: true,
        };
      case types.GET_PARCEL_SEED_APPLICATION_SUCCESS:
        return {
          ...state,
          isFetchingItemSeedApplication: false,
          itemSeedApplication: action.payload,
        };
      case types.GET_PARCEL_SEED_APPLICATION_ERROR:
        return {
          ...state,
          isFetchingItemSeedApplication: false,
        };

      case types.GET_PARCEL_PREDECESSORS_REQUEST:
        return {
          ...state,
          isFetchingItemPredecessors: true,
        };
      case types.GET_PARCEL_PREDECESSORS_SUCCESS:
        return {
          ...state,
          isFetchingItemPredecessors: false,
          itemPredecessors: action.payload,
        };
      case types.GET_PARCEL_PREDECESSORS_ERROR:
        return {
          ...state,
          isFetchingItemPredecessors: false,
        };

      case types.GET_PARCEL_EAGRI_RESTRICTIONS_REQUEST:
        return {
          ...state,
          isFetchingItemEagriRestrictions: true,
        };
      case types.GET_PARCEL_EAGRI_RESTRICTIONS_SUCCESS:
        return {
          ...state,
          isFetchingItemEagriRestrictions: false,
          itemEagriRestrictions: action.payload,
        };
      case types.GET_PARCEL_EAGRI_RESTRICTIONS_ERROR:
        return {
          ...state,
          isFetchingItemEagriRestrictions: false,
        };

      case types.EXPORT_PARCELS_REQUEST:
        return {
          ...state,
          isExportingData: true,
        };
      case types.EXPORT_PARCELS_SUCCESS:
        return {
          ...state,
          isExportingData: false,
        };
      case types.EXPORT_PARCELS_ERROR:
        return {
          ...state,
          isExportingData: false,
        };

      case types.POST_PARCEL_SUBTRACTIONS_GEOMETRY_REQUEST:
        return {
          ...state,
          isFetchingItemSubtractionsGeometry: true,
        };
      case types.POST_PARCEL_SUBTRACTIONS_GEOMETRY_SUCCESS:
        return {
          ...state,
          isFetchingItemSubtractionsGeometry: false,
          itemSubtractionsGeometry: action.payload,
        };
      case types.POST_PARCEL_SUBTRACTIONS_GEOMETRY_ERROR:
        return {
          ...state,
          isFetchingItemSubtractionsGeometry: false,
        };
      case types.RESET_PARCEL_SUBTRACTIONS_GEOMETRY:
        return {
          ...state,
          itemSubtractionsGeometry: undefined,
          isFetchingItemSubtractionsGeometry: false,
        };

      case types.POST_PARCEL_SUBTRACTIONS_AREA_REQUEST:
        return {
          ...state,
          isFetchingItemSubtractionsArea: true,
        };
      case types.POST_PARCEL_SUBTRACTIONS_AREA_SUCCESS:
        return {
          ...state,
          isFetchingItemSubtractionsArea: false,
          itemSubtractionsArea: action.payload,
        };
      case types.POST_PARCEL_SUBTRACTIONS_AREA_ERROR:
        return {
          ...state,
          isFetchingItemSubtractionsArea: false,
        };
      case types.RESET_PARCEL_SUBTRACTIONS_AREA:
        return {
          ...state,
          itemSubtractionsArea: undefined,
          isFetchingItemSubtractionsArea: false,
        };

      case types.SET_FOCUSED_ROW: {
        return {
          ...state,
          focusedRow: action.payload,
        };
      }
      default:
        return state;
    }
  };
