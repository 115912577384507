import React from "react";

import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { generatePath, Link, useLocation } from "react-router-dom";

import { PRECISION_URLS } from "../../../../../core/precision/precision.constants";

import planetIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/planet.svg";
import planetDisabledIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/planet_disabled.svg";
import testTubeIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/test-tube.svg";
import testTubeDisabledIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/test-tube_disabled.svg";
import { PRECISION_TABS } from "../../../../../core/precision/containers/PrecisionDetailV2";
import {
  DashboardParcelTo,
  DashboardSeasonTo,
  PrecisionFarmingProduct,
} from "../../../../../generated/api/satellite";
import { useFarmIds } from "../../../../../shared/api/client.utils";
import { HistoryPrevState } from "../../../../types";

type Props = {
  products: PrecisionFarmingProduct[];
  data: DashboardParcelTo;
  newestSeason: DashboardSeasonTo;
};

const ParcelCellIconBar = ({ data, newestSeason, products }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const disabledColor = "#F6F6F6";

  const planetIsActive = products.includes(
    "SENTINEL_HUB_MANAGEMENT_ZONES_SAVI",
  );
  const soilIsActive = products.includes("INTERNAL_SOIL_SAMPLES");

  const { farmIds } = useFarmIds();
  const getRootUrl = (path: string) =>
    generatePath(
      `/farm/${farmIds}/${PRECISION_URLS.serviceDetailV2()}/${path}`,
      {
        parcelId: data.parcelId,
        seasonId: newestSeason.seasonId,
      },
    );

  return (
    <div className={classes.icons}>
      <Tooltip
        title={<FormattedMessage id="PrecisionTableService.managementZones" />}
      >
        {planetIsActive ? (
          <Link
            className={classes.icon}
            style={{
              background: "#E0F5F0",
            }}
            to={{
              pathname: getRootUrl(PRECISION_TABS.zoneManagement.path),
              state: {
                previousLocation: { ...location },
              } as HistoryPrevState,
            }}
          >
            <img alt="planet" src={planetIcon} />
          </Link>
        ) : (
          <div
            className={classes.icon}
            style={{
              background: disabledColor,
            }}
          >
            <img alt="planet_disabled" src={planetDisabledIcon} />
          </div>
        )}
      </Tooltip>
      <Tooltip
        title={<FormattedMessage id="PrecisionTableService.soilSamples" />}
      >
        {soilIsActive ? (
          <Link
            className={classes.icon}
            style={{
              background: "#F8E6DB",
            }}
            to={{
              pathname: getRootUrl(PRECISION_TABS.soilSamples.path),
              state: {
                previousLocation: { ...location },
              } as HistoryPrevState,
            }}
          >
            <img alt="test-tube" src={testTubeIcon} />
          </Link>
        ) : (
          <div
            className={classes.icon}
            style={{
              background: disabledColor,
            }}
          >
            <img alt="test-tube_disabled" src={testTubeDisabledIcon} />
          </div>
        )}
      </Tooltip>
    </div>
  );
};

export const useStyles = makeStyles(() => ({
  icons: {
    display: "flex",
    gap: 12,
  },
  icon: {
    padding: 4,
    height: 24,
    width: 24,
    borderRadius: "100%",
  },
}));

export { ParcelCellIconBar };
