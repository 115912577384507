import React, { useState } from "react";

import { makeStyles } from "@mui/styles";

import { DashboardParcelTo } from "../../../generated/api/satellite";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import { useDelayedLoader } from "../../hooks/useDelayedLoader";
import { SeasonParcelMutationPayloadType } from "../../types";

import { YieldInput } from "./YieldInput";
import { YieldPlaceholder } from "./YieldPlaceholder";

type Props = {
  parcel: DashboardParcelTo;
  seasonId: string;
  handleMutation: (payload: SeasonParcelMutationPayloadType) => void;
  isPending: boolean;
  classes?: Record<string, string>;
};

const YieldCell = ({
  classes: propClasses = {},
  handleMutation,
  isPending,
  parcel,
  seasonId,
}: Props) => {
  const loaderClasses = useLoaderStyles();
  const [edit, setEdit] = useState(false);

  const { isDelayed } = useDelayedLoader(isPending, 600);

  const handleChange = (e: React.SyntheticEvent, newYield?: number) => {
    e.stopPropagation();

    handleMutation({
      cropInternalId: parcel.cropInternalId,
      cropName: parcel.cropName,
      parcelId: parcel.parcelId,
      seasonId,
      yieldInTonnes: newYield,
    });
  };

  let Jsx = null;

  if (isDelayed) {
    Jsx = <CfLoader classes={loaderClasses} size={32} />;
  } else if (edit) {
    Jsx = (
      <YieldInput
        classes={propClasses}
        handleChange={handleChange}
        setEdit={setEdit}
        value={parcel.yieldInTonnes}
      />
    );
  } else if (!edit) {
    Jsx = (
      <YieldPlaceholder
        classes={propClasses}
        parcel={parcel}
        onClick={(e) => {
          e.stopPropagation();
          setEdit(true);
        }}
      />
    );
  }

  return <div className={propClasses.wrapper}>{Jsx}</div>;
};

const useLoaderStyles = makeStyles(() => ({
  wrapper: {
    justifyContent: "flex-start",
  },
}));

export { YieldCell };
