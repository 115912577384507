import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfLabel from "../../../../../shared/components/common/CfLabel/CfLabel";

const ActionLabel = () => {
  const classes = useStyles();

  return (
    <CfLabel
      classes={{ label: classes.l }}
      message={<FormattedMessage id="ActionsList.variableApplication" />}
    />
  );
};

export { ActionLabel };

const useStyles = makeStyles((theme: Theme) => {
  const { breakpoints } = theme;
  return {
    l: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    [breakpoints.down("sm")]: {
      l: {
        width: "min-content",
      },
    },
  };
});
