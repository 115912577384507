import React, { FC, useEffect } from "react";

import { styled } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { connect, useSelector } from "react-redux";
import {
  NavLink,
  NavLinkProps,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getHasNoPrecision,
  getIsFetchingPrecisionParcels,
  getIsInitPrecisionPage,
  getTaskdataIsVisible,
  getTotalCount,
} from "../../../shared/api/sentinel/precision/precision.selectors";

import { setAsAppliedShowVarioDocInactive } from "../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.actions";
import {
  fetchPrecisionAsApplied,
  setPrecisionPageIsInit,
} from "../actions/precision.actions";

import { PRECISION_URLS } from "../precision.constants";

import {
  ImportFilesApiType,
  ValidateFileApiType,
  importFilesApi,
  validateFileApi,
} from "../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.api";
import CfPrimaryTab from "../../../shared/components/common/CfPrimaryTab/CfPrimaryTab";
import CfPrimaryTabs from "../../../shared/components/common/CfPrimaryTabs/CfPrimaryTabs";
import FabWithOptions from "../../../shared/components/common/FabWithOptions/FabWithOptions";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import { useToggle } from "../../../shared/hooks/useToggle";
import useToggles from "../../../shared/toggles/toggles";
import TOGGLES from "../../../shared/toggles/toggles.const";
import { useFarmToggles } from "../../../shared/toggles/useFarmToggles";
import { Thunk } from "../../../types";

import { usePfVersion } from "./api/usePfVersion";
import ImportMachinesDialog from "./ImportMachinesDialog";
import { usePrecisionFarmingStyles } from "./styles";

import { PrecisionState } from "../../../reducers/precision.reducer.types";

interface Props {
  count: number;
  fetchPrecisionAsApplied: () => void;
  importFilesApi: (params: ImportFilesApiType) => void;
  isFetching: boolean;
  isInit: boolean;
  ngRedirectToVrf: (parcelId: string[]) => void;
  ngRedirectToVrs: (parcelId: string[]) => void;
  setAsAppliedShowVarioDocInactive: (val: boolean) => void;
  setPrecisionPageIsInit: (payload: boolean) => void;
  validateFileApi: (params: ValidateFileApiType) => void;
}

const PrecisionTabs: FC<Props> = ({
  count,
  fetchPrecisionAsApplied,
  importFilesApi,
  isFetching,
  isInit,
  ngRedirectToVrf,
  ngRedirectToVrs,
  setAsAppliedShowVarioDocInactive,
  setPrecisionPageIsInit,
  validateFileApi,
}) => {
  const [, , isToggleActive] = useToggles();
  const classes = usePrecisionFarmingStyles();

  const history = useHistory();
  const { pathname } = useLocation();
  const { farmId } = useParams<Record<"farmId", string>>();
  const taskdataIsVisible = useSelector(getTaskdataIsVisible);
  const hasNoPrecision = useSelector(getHasNoPrecision);
  const { isActive } = useFarmToggles();

  const { isLoading, isV1, isV2, pfVersionResponse } = usePfVersion();
  const tabs = [
    ...(isLoading || isV1 || pfVersionResponse.isError
      ? [
          {
            to: [`/farm/${farmId}/${PRECISION_URLS.services()}`],
            "data-test": "precision-services",
            label: (
              <div>
                <FormattedMessage id="PrecisionFarming.tabs.services" />
              </div>
            ),
          },
        ]
      : []),
    ...(isV2 || isActive("PRECISION_FARMING_2_0")
      ? [
          {
            to: [
              `/farm/${farmId}/${PRECISION_URLS.seasons()}`,
              `/farm/${farmId}/${PRECISION_URLS.seasonsDashboard()}`,
            ],
            "data-test": "precision-seasons",
            label: <FormattedMessage id="PrecisionFarming.tabs.services" />,
          },
        ]
      : []),
    {
      "data-test": "precision-as-applied",
      to: [`/farm/${farmId}/${PRECISION_URLS.asApplied()}`],
      label: <FormattedMessage id="PrecisionFarming.tabs.machineData" />,
    },
    ...(isToggleActive(TOGGLES.PRESCRIPTION_MAPS_TOGGLE.name)
      ? [
          {
            "data-test": "prescription-maps",
            label: (
              <div>
                <FormattedMessage id="PrecisionFarming.tabs.prescriptionMaps" />
              </div>
            ),
            to: [`/farm/${farmId}/${PRECISION_URLS.prescriptionMaps()}`],
          },
        ]
      : []),
  ];

  const foundTabIndex = tabs.findIndex((tab) =>
    tab.to.some((path) => pathname.startsWith(path)),
  );
  const currentTab = foundTabIndex === -1 ? 0 : foundTabIndex;

  const asAppliedTabIsSelected = currentTab === tabs.length - 1;

  const {
    on: machinesImportIsOpen,
    setOff: handleMachinesImportClose,
    setOn: handleMachinesImportOpen,
  } = useToggle();

  useEffect(() => {
    if (!isInit && !isFetching && count > 0) {
      setAsAppliedShowVarioDocInactive(false);
      setPrecisionPageIsInit(true);
    }
  }, [
    isFetching,
    count,
    setPrecisionPageIsInit,
    isInit,
    setAsAppliedShowVarioDocInactive,
  ]);

  return (
    <div>
      <div className={classes.container}>
        <PageHeader
          actionButtons={
            <div className={classes.fabContainer}>
              <FabWithOptions
                options={[
                  {
                    translId: "common.addVrfActivity",
                    action: () => ngRedirectToVrf([]),
                    disabled: hasNoPrecision,
                    hidden: false,
                  },
                  {
                    translId: "common.addVrsActivity",
                    action: () => ngRedirectToVrs([]),
                    divider: taskdataIsVisible,
                    disabled: hasNoPrecision,
                    hidden: false,
                  },
                  {
                    translId: "PrecisionFarming.add.importRides",
                    action: handleMachinesImportOpen,
                    hidden: !taskdataIsVisible,
                  },
                ]}
              />
            </div>
          }
          classes={{
            header: classes.header,
          }}
          heading={
            <PageHeading
              dataTest="precision-heading"
              value={<FormattedMessage id="common.precision" />}
            />
          }
        />
        <div className={classes.tabsContainer}>
          <CfPrimaryTabs centered tabValue={currentTab}>
            {tabs.map((tab) => (
              <StyledTab
                component={StyledNavLink}
                data-test={tab["data-test"]}
                key={tab.to.join(",")}
                label={tab.label}
                to={tab.to[0]}
              />
            ))}
          </CfPrimaryTabs>
        </div>
      </div>
      {machinesImportIsOpen && (
        <ImportMachinesDialog
          handleClose={handleMachinesImportClose}
          importFilesApi={importFilesApi}
          open={machinesImportIsOpen}
          validateFileApi={validateFileApi}
          onSuccess={() => {
            if (asAppliedTabIsSelected) {
              fetchPrecisionAsApplied();
            } else {
              history.push(`/farm/${farmId}/${PRECISION_URLS.asApplied()}`);
            }
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  isFetching: getIsFetchingPrecisionParcels(state),
  count: getTotalCount(state),
  isInit: getIsInitPrecisionPage(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      importFilesApi,
      validateFileApi,
      fetchPrecisionAsApplied,
      setPrecisionPageIsInit,
      setAsAppliedShowVarioDocInactive,
    },
    dispatch,
  );

const StyledTab = styled(CfPrimaryTab)<NavLinkProps>({});

const StyledNavLink = styled(NavLink)({
  "&:focus": {
    outline: "unset",
    outlineOffset: "0",
    textDecoration: "unset",
  },
  "&:hover": {
    textDecoration: "unset",
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PrecisionTabs);
