import React from "react";

import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

interface Row {
  name: string;
  value: React.JSX.Element | string | undefined | null;
}
interface Props {
  data: Array<Row>;
}

const YieldMapBasicInfo = (props: Props) => {
  const rows = props.data;

  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <FormattedMessage id="YieldMaps.basicInfo.title" />
      </div>
      <div className={classes["table-wrapper"]}>
        {rows.map((row: Row, i: number) => (
          <div className={classes["table-row"]} key={i}>
            <div className={classes.label}>
              <FormattedMessage id={`YieldMaps.basicInfo.${row.name}`} />
            </div>
            <div className={classes.value}>{row.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    color: "#000",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    width: "100%",
  },
  title: {
    textTransform: "uppercase",
    padding: "24px 0 16px 0",
  },
  label: {
    color: "#848484",
    fontSize: "16px",
    width: "50%",
    padding: "20px",
  },
  value: {
    fontWeight: 500,
    textAlign: "end",
    width: "50%",
    padding: "20px",
  },
  "table-wrapper": {
    borderRadius: "8px",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.10)",
  },
  "table-row": {
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.10)",
    flexShrink: 0,
    backgroundColor: "#fff",
    margin: "2px",
    display: "flex",
    justifyContent: "space-between",
  },
}));

export { YieldMapBasicInfo };
