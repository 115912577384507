import React, { Fragment } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WarningIcon from "@mui/icons-material/Warning";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfBackButton from "../../../../../shared/components/common/CfBackButton/CfBackButton";
import PageHeader from "../../../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../../../shared/components/common/PageHeading/PageHeading";
import { withFarm } from "../../../../../shared/hocs/context/withFarm";
import VariableActionExport from "../VariableActionExport/VariableActionExport";

const ActionFormHeader = (props) => {
  const {
    classes,
    handleStartIsSplitting,
    isDisabled,
    isDraft,
    isExisting,
    isLoading,
    ngGoToActions,
    onExport,
    title,
  } = props;
  return (
    <div className={classes.headerWrapper}>
      <PageHeader
        actionButtons={
          isExisting ? (
            <Fragment>
              {onExport && (
                <VariableActionExport
                  buttonText={<FormattedMessage id="Vra.exportList.header" />}
                  iconButton={true}
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  onExport={onExport}
                />
              )}
              {!!handleStartIsSplitting && (
                <Tooltip
                  title={
                    <FormattedMessage id="ActionSplit.button.tooltip.label" />
                  }
                >
                  <span>
                    <IconButton
                      data-test="action-other-split"
                      disabled={isDisabled}
                      onClick={handleStartIsSplitting}
                      size="large"
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Fragment>
          ) : null
        }
        backButton={
          <CfBackButton
            onClick={() => ngGoToActions()}
            translId="action.backToActivities"
          />
        }
        heading={
          <div className={classes.headingContainer}>
            {isDraft && (
              <Tooltip title={<FormattedMessage id="ActionDetail.isDraft" />}>
                <WarningIcon color="secondary" />
              </Tooltip>
            )}
            <PageHeading dataTest="action-name" translationId={title} />
          </div>
        }
      />
    </div>
  );
};

ActionFormHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isExisting: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onExport: PropTypes.func,
  ngGoToActions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isDraft: PropTypes.bool,
};

ActionFormHeader.defaultProps = {
  onExport: undefined,
  isLoading: false,
  isDraft: false,
};

const styles = (theme) => ({
  headerWrapper: {
    padding: theme.spacing(2),
  },
  headingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const ActionFormHeaderWithFarm = withFarm(ActionFormHeader);
export default withStyles(styles)(ActionFormHeaderWithFarm);
