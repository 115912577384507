import {
  queryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import {
  State,
  driveDetail1,
  logbookByDrivers,
  setState,
  tosExport,
  tosState,
} from "../../../generated/api/telematics";
import { getCountedData, useFarmIds } from "../../../shared/api/client.utils";
import { queryClient } from "../../../shared/query-client";

const telematicsDriversKey = ["telematics", "drivers"];

const driversQuery = (params: Parameters<typeof logbookByDrivers>[0]) =>
  queryOptions({
    queryKey: [...telematicsDriversKey, ...Object.values(params)],
    queryFn: async ({ signal }) => {
      const data = await logbookByDrivers(params, { signal });
      return getCountedData(data);
    },
  });

const payrollSystemStateQuery = (params: Parameters<typeof tosState>[0]) =>
  queryOptions({
    queryKey: [...telematicsDriversKey, "tos", "status", Object.values(params)],
    queryFn: ({ signal }) => tosState(params, { signal }),
  });

const driveQuery = (
  driveId: Parameters<typeof driveDetail1>[0],
  params: Parameters<typeof driveDetail1>[1],
) =>
  queryOptions({
    queryKey: [...telematicsDriversKey, driveId, ...Object.values(params)],
    queryFn: ({ signal }) => driveDetail1(driveId, params, { signal }),
  });

const useApproveDriveMutation = () => {
  const farmIds = useFarmIds();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: Parameters<typeof setState>[0]) =>
      setState(payload, farmIds),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: telematicsDriversKey,
      });
    },
  });
};

const usePostponeDriveMutation = () => {
  const farmIds = useFarmIds();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      drive,
      state,
    }: Parameters<typeof setState>[0] & { state: State }) =>
      setState(
        {
          drive,
          state: state === "DEFERRED" ? State.NOT_APPROVED : State.DEFERRED,
        },
        farmIds,
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: telematicsDriversKey,
      });
    },
  });
};

const useSendToPayrollSystemMutation = () => {
  const farmIds = useFarmIds();

  return useMutation({
    mutationFn: () => tosExport(farmIds),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: telematicsDriversKey,
      });
    },
  });
};

export {
  driversQuery,
  payrollSystemStateQuery,
  useApproveDriveMutation,
  useSendToPayrollSystemMutation,
  usePostponeDriveMutation,
  telematicsDriversKey,
  driveQuery,
};
