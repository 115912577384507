const PREFIX = "PZ_V2";

export const GET_MIGRATION_REQUEST = `${PREFIX}/GET_MIGRATION_REQUEST`;
export const GET_MIGRATION_SUCCESS = `${PREFIX}/GET_MIGRATION_SUCCESS`;
export const GET_MIGRATION_ERROR = `${PREFIX}/GET_MIGRATION_ERROR`;

export const GET_PARCELS_REQUEST = `${PREFIX}/GET_PARCELS_REQUEST`;
export const GET_PARCELS_SUCCESS = `${PREFIX}/GET_PARCELS_SUCCESS`;
export const GET_PARCELS_ERROR = `${PREFIX}/GET_PARCELS_ERROR`;
export const RESET_PARCELS = `${PREFIX}/RESET_PARCELS`;

export const GET_VARIABLE_ACTION_ZONES = `${PREFIX}/GET_VARIABLE_ACTION_ZONES`;
export const GET_VARIABLE_ACTION_ZONES_SUCCESS = `${PREFIX}/GET_VARIABLE_ACTION_ZONES_SUCCESS`;
export const GET_VARIABLE_ACTION_ZONES_FAILURE = `${PREFIX}/GET_VARIABLE_ACTION_ZONES_FAILURE`;
export const RESET_VARIABLE_ACTION_ZONES = `${PREFIX}/RESET_VARIABLE_ACTION_ZONES`;
