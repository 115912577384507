import { queryOptions } from "@tanstack/react-query";

import {
  yieldMapDetail,
  YieldMapDetailParams,
  getParcelSeasons,
  GetParcelSeasonsParams,
} from "../../../../generated/api/satellite";
import { staleTime } from "../../../../shared/query-client";
import { apiKeys } from "../../services/api/apiKeys";

const getParcelDataQuery = (seasonId: string, params: YieldMapDetailParams) =>
  queryOptions({
    queryKey: [...apiKeys.general, ...Object.values(params), seasonId],
    queryFn: async ({ signal }) => {
      const response = await yieldMapDetail(seasonId, params, { signal });
      return response;
    },
    staleTime: staleTime.Infinity,
  });

const getYieldSeasonQuery = (
  seasonIds: string,
  params: GetParcelSeasonsParams,
) =>
  queryOptions({
    queryKey: [...apiKeys.general, params, seasonIds],
    queryFn: async ({ signal }) => {
      const response = await getParcelSeasons(seasonIds, params, { signal });
      return response;
    },
  });

export { getParcelDataQuery, getYieldSeasonQuery };
