import { queryOptions } from "@tanstack/react-query";

import { GetAll1Params, getAll1 } from "../../../../generated/api/telematics";
import { staleTime } from "../../../../shared/query-client";

const productionOperationsQuery = (payload: GetAll1Params) =>
  queryOptions({
    queryKey: ["telematics", "operations", "production", payload.farmIds],
    queryFn: ({ signal }) =>
      getAll1(
        {
          farmIds: payload.farmIds,
        },
        { signal },
      ),
    staleTime: staleTime.Infinity,
  });

export { productionOperationsQuery };
