import {
  GetFarmPfVersionParams,
  GetParcelSeasonsParams,
  GetSamplesV2Params,
} from "../../../../generated/api/satellite";

const generalKeys = ["satellite", "general"];

const apiKeys = {
  general: [...generalKeys] as const,

  migration: {
    general: () => [...apiKeys.general, "migration"] as const,
    value: (params: GetFarmPfVersionParams) =>
      [...apiKeys.migration.general(), params] as const,
  },
  parcelSeasons: {
    general: () => [...apiKeys.general, "parcel-seasons"] as const,
    all: (parcelId: string, params: GetParcelSeasonsParams) =>
      [...apiKeys.parcelSeasons.general(), parcelId, params] as const,
  },
  soilSamples: {
    general: () => [...apiKeys.general, "soil-samples"] as const,
    parcelSamples: {
      all: (parcelId: string, params: GetSamplesV2Params) =>
        [...apiKeys.soilSamples.general(), parcelId, params] as const,
    },
  },
};

export { apiKeys };
