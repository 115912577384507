import React from "react";

import { Field, FieldArray } from "formik";

import {
  RestrictionTo,
  RestrictionType,
} from "../../../../../generated/api/agroevidence";
import { useVegetationStrips } from "../../../ActionOthers/hooks/useVegetationStrips";
import { ActionParcelSubtractableAreasSection } from "../ActionParcelSubtractableAreasSection/ActionParcelSubtractableAreasSection";
import { SubtractableAreaWithRadioButton } from "../SubtractableAreaWithRadioButton/SubtractableAreaWithRadioButton";

type Props = {
  isEditing: boolean;
  maxValue: number;
  onParcelSubtractionDelete: (type: string, value: number) => void;
  parcelIndex: number;
};

const WaterParcelSubtractableAreas = ({
  isEditing,
  maxValue,
  onParcelSubtractionDelete,
  parcelIndex,
}: Props) => {
  useVegetationStrips();
  return (
    <FieldArray name={`parcels.${parcelIndex}.subtractableAreas.water`}>
      {({ form, push, remove }) => {
        const handleSubtractionDelete = (type: string, value: number) => {
          const index = form.values.parcels[
            parcelIndex
          ].subtractableAreas.water.findIndex(
            (sa: RestrictionTo) => sa.value === value,
          );
          // if delete checked value, set checked value to 0
          if (
            form.values.parcels[parcelIndex].subtractableAreas.waterChecked ===
            form.values.parcels[parcelIndex].subtractableAreas.water[index]
              .value
          ) {
            form.setFieldValue(
              `parcels.${parcelIndex}.subtractableAreas.waterChecked`,
              0,
            );
          }
          remove(index);
          onParcelSubtractionDelete(type, value);
        };

        const handleAddSubtractableArea = (sa: RestrictionTo) => {
          push(sa);
          form.setFieldValue(
            `parcels.${parcelIndex}.subtractableAreas.waterChecked`,
            sa.value,
          );
        };

        const fieldError = form.getFieldMeta(
          `parcels.${parcelIndex}.subtractableAreas.waterChecked`,
        );

        return (
          <ActionParcelSubtractableAreasSection
            handleAdd={handleAddSubtractableArea}
            isEditing={isEditing}
            isSufficientError={fieldError.error}
            maxValue={maxValue}
            subtractableAreaType={RestrictionType.Water}
            resetCannotBeUsedWarning={() => {
              form.setFieldError(
                `parcels.${parcelIndex}.subtractableAreas.waterChecked`,
                undefined,
              );
            }}
            resetSubtractableAreasWarning={() => {
              form.setFieldError(
                `parcels.${parcelIndex}.subtractableAreas.waterChecked`,
                undefined,
              );
            }}
            setCannotBeUsedWarning={(message: string) => {
              form.setFieldError(
                `parcels.${parcelIndex}.subtractableAreas.waterChecked`,
                message,
              );
            }}
            setSubtractableAreasWarning={() => {
              form.setFieldError(
                `parcels.${parcelIndex}.subtractableAreas.waterChecked`,
                "Selected value is lower than the maximum value added from POR",
              );
            }}
            subtractableAreas={
              form.values.parcels[parcelIndex].subtractableAreas.water
            }
            subtractableChecked={
              form.values.parcels[parcelIndex].subtractableAreas.waterChecked
            }
          >
            <Field
              component={SubtractableAreaWithRadioButton}
              handleSubtractionDelete={handleSubtractionDelete}
              isEditing={isEditing}
              name={`parcels.${parcelIndex}.subtractableAreas.waterChecked`}
              options={form.values.parcels[parcelIndex].subtractableAreas.water}
              subtractableAreaType={RestrictionType.Water}
            />
          </ActionParcelSubtractableAreasSection>
        );
      }}
    </FieldArray>
  );
};

export { WaterParcelSubtractableAreas };
