import React, { useContext, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RSAAResultAction } from "redux-api-middleware";
import invariant from "tiny-invariant";

import {
  getSelectedOnPage,
  getSentinelAdminFarm,
} from "../../selectors/sentinel.selectors";

import { setTableSelected } from "../../../../../shared/actions/table.actions";
import { fetchActiveParcels } from "../../actions/sentinel.actions";

import { NAMESPACE as namespace } from "../../reducer/sentinelAdmin.reducer";

import { ParcelDataTo } from "../../../../../generated/api/satellite";
import {
  getFarmPfVersionQuery,
  updateParcelServices,
} from "../../../../../shared/api/sentinel/admin/admin.api";
import { SnackbarContext } from "../../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import SentinelAdminParcelsDialog from "../SentinelAdminParcelsDialog/SentinelAdminParcelsDialog";

export type ParcelWithServices = Pick<
  ParcelDataTo,
  | "parcelId"
  | "products"
  | "name"
  | "biomonitoringEnabled"
  | "historyPotentialEnabled"
  | "internalBiomonitoringEnabled"
  | "managementZonesEnabled"
  | "variableApplicationEnabled"
  | "yieldMapsEnabled"
>;

export type SentinelAdminType = "bulkEdit" | "toggle";
export type SentinelAdminTypeNoToggle = Exclude<SentinelAdminType, "toggle">;

const useSentinelAdmin = () => {
  const [mode, setMode] = useState<SentinelAdminType>();
  const [activeParcels, setActiveParcels] = useState<
    ParcelWithServices[] | undefined
  >();
  const farm = useSelector(getSentinelAdminFarm);
  const selected = useSelector(getSelectedOnPage);
  const showSnackbar = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const versionPF = useQuery({
    ...getFarmPfVersionQuery({ farmIds: farm?.id || "" }),
    enabled: !!farm?.id,
  });

  const activeParcel = activeParcels ? activeParcels[0] : undefined;

  const getDialogTitle = () => {
    if (mode === "toggle") {
      return (
        <span style={{ display: "flex", justifyContent: "center" }}>
          <FormattedMessage id="SentinelAdminParcelsDialog.serviceSettings" />{" "}
          {activeParcel?.name}
        </span>
      );
    }
    return <FormattedMessage id="SentinelAdmin.parcels.services.edit" />;
  };

  const handleRowClick = (activeParcel: ParcelDataTo) => {
    setMode("toggle");
    setActiveParcels([activeParcel]);
  };

  const handleBulkDialogCancel = () => {
    setActiveParcels(undefined);
  };

  const handleBulkActionClick = (mode: SentinelAdminTypeNoToggle) => {
    setMode(mode);
    setActiveParcels(
      selected.map((parcelId) => ({
        parcelId,
        variableApplicationEnabled: false,
        historyPotentialEnabled: false,
        biomonitoringEnabled: false,
        internalBiomonitoringEnabled: false,
        managementZonesEnabled: false,
        yieldMapsEnabled: false,
        name: undefined,
      })),
    );
  };

  const handleBulkDialogAccept: React.ComponentProps<
    typeof SentinelAdminParcelsDialog
  >["onSubmit"] = (config) => {
    const versionUrl =
      versionPF.data?.data === "2_0_0" ? "v2/season-parcels" : "";
    const versionUrlParcels = versionPF.data?.data === "2_0_0" ? "/v2" : "";
    invariant(farm, "Farm has to be defined");
    invariant(activeParcels, "Active parcel has to be defined");

    (
      dispatch(
        updateParcelServices({
          farmId: farm.id,
          payload: {
            ...config,
            parcelId: activeParcels.map((parcel) => parcel.parcelId),
          },
          versionUrl,
        }),
      ) as unknown as Promise<unknown>
    )
      .then((res: RSAAResultAction) => {
        if (res.error) {
          showSnackbar({
            isError: true,
            message: (
              <FormattedMessage id="SentinelAdmin.parcels.settings.failure" />
            ),
          });
        } else {
          dispatch(setTableSelected([], namespace));
          showSnackbar({
            isSuccess: true,
            message: (
              <FormattedMessage id="SentinelAdmin.parcels.settings.bulk.success" />
            ),
          });
          dispatch(fetchActiveParcels({ versionUrl: versionUrlParcels }));
        }
      })
      .finally(() => {
        setMode(undefined);
        setActiveParcels(undefined);
      });
  };

  return {
    selected,
    farm,
    activeParcel,
    mode,
    dialogTitle: getDialogTitle(),
    handleRowClick,
    handleBulkDialogCancel,
    handleBulkActionClick,
    handleBulkDialogAccept,
  };
};

export { useSentinelAdmin };
