import { queryOptions } from "@tanstack/react-query";

import { getClients } from "../../../../generated/api/telematics";

const winfasClientCombinationsQuery = ({
  disabled,
  ...params
}: Parameters<typeof getClients>[0] & { disabled?: boolean }) =>
  queryOptions({
    queryKey: [
      "telematics",
      "winfas",
      "clients",
      ...Object.values(params),
      disabled,
    ],
    queryFn: ({ signal }) => (disabled ? null : getClients(params, { signal })),
  });

export { winfasClientCombinationsQuery };
