import React, { Suspense, useEffect, useState } from "react";

import { Box, Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  match as matchType,
  useLocation,
} from "react-router-dom";
import { RSAAResultAction } from "redux-api-middleware";

import {
  getIsFetchingParcel,
  getParcel,
} from "../../../../../../shared/api/agroevidence/parcels/parcels.selectors";
import { getVariableApplicationStatus } from "../../selectors/parcelDetail.selectors";

import { updateParcelName } from "../../actions/parcelDetail.actions";

import * as satelliteProductsTypes from "../../../../../../shared/constants/satelliteProductsTypes.constants";

import {
  ParcelDetailTo,
  ParcelPredecessorTo,
} from "../../../../../../generated/api/agroevidence";
import {
  getParcelApi,
  resetParcelApi,
  getParcelSeedApplicationApi,
  getParcelPredecessorsApi,
  getParcelEagriRestrictionsApi,
} from "../../../../../../shared/api/agroevidence/parcels/parcels.api";
import {
  getParcelSowingPlanApi,
  resetParcelSowingPlanApi,
  updateSeasonsSowingPlanApi,
  resetSeasonsSowingPlanApi,
} from "../../../../../../shared/api/agroevidence/sowingPlan/sowingPlan.api";
import {
  getPrecisionParcelApi,
  resetPrecisionParcelApi,
} from "../../../../../../shared/api/sentinel/precision/precision.api";
import {
  getSatelliteProductsApi,
  resetSatelliteProductsApi,
} from "../../../../../../shared/api/sentinel/satelliteProducts/satelliteProducts.api";
import CfErrorPage from "../../../../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfLoader from "../../../../../../shared/components/common/CfLoader/CfLoader";
import PageHeader from "../../../../../../shared/components/common/PageHeader/PageHeader";
import ShowHideMap from "../../../../../../shared/components/common/ShowHideMap/ShowHideMap";
import useWidth from "../../../../../../shared/hooks/useWidth";
import LocalStorage from "../../../../../../shared/services/LocalStorage.service";
import {
  Geometries,
  MapImage,
} from "../../../../../precision/containers/BioMonitoring";
import {
  ParcelsService,
  SHOWN_NUMBER_SOWING_SEASONS,
  START_YEAR_FIRST_SOWING_SEASONS,
} from "../../../../shared/services/Parcels.service";
import { ParcelActivities } from "../../../activities/containers/ParcelActivities/ParcelActivities";
import { ParcelDetailOverview } from "../../../overview/ParcelDetailOverview";
import { ParcelDetailFabButton } from "../../components/ParcelDetailFabButton/ParcelDetailFabButton";
import { ParcelDetailHeader } from "../../components/ParcelDetailHeader/ParcelDetailHeader";
import ParcelDetailTabs, {
  ParcelDetailTab,
} from "../../components/ParcelDetailTabs/ParcelDetailTabs";
import ParcelDetailMap from "../ParcelDetailMap/ParcelDetailMap";

import { CoreParcelWarningMessage } from "./CoreParcelWarningMessage";
import { ParcelDetailButtonBack } from "./ParcelDetailButtonBack";

interface PrecisionTab extends ParcelDetailTab {
  path: string;
}

type Tabs = {
  [prop: string]: PrecisionTab;
};

const PARCEL_TABS: Tabs = {
  overview: {
    id: "overview",
    section: "ParcelDetailTabs",
    path: "overview",
  },
  activities: {
    id: "activities",
    section: "ParcelDetailTabs",
    path: "activities",
  },
};

type Props = {
  countryCode: string;
  langId: string;
  match: matchType<{ farmId: string; parcelId: string }>;
  ngGoToMainMap: (farmId: string, parcelId: string) => void;
  ngRedirectToEph: (parcelId: string[]) => void;
  ngRedirectToHarvest: (parcelId: string[]) => void;
  ngRedirectToMowing: (parcelId: string[]) => void;
  ngRedirectToOtherActionsNew: (parcelId: string[]) => void;
  ngRedirectToSowing: (parcelId: string[]) => void;
  ngRedirectToVrf: (parcelId: string[]) => void;
  ngRedirectToVrs: (parcelId: string[]) => void;
};

const ParcelDetail = ({
  countryCode,
  langId,
  match,
  ngGoToMainMap,
  ngRedirectToEph,
  ngRedirectToHarvest,
  ngRedirectToMowing,
  ngRedirectToOtherActionsNew,
  ngRedirectToSowing,
  ngRedirectToVrf,
  ngRedirectToVrs,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const width = useWidth();
  const dispatch = useDispatch();

  const parcel = useSelector(getParcel) as ParcelDetailTo;
  const isFetchingParcel = useSelector(getIsFetchingParcel);
  const variableApplicationStatus = useSelector(getVariableApplicationStatus);

  const { farmId, parcelId } = match.params;
  const { pathname } = useLocation();
  const error = {};

  const farmSettings = LocalStorage.loadFromLocalStorage("farmSettings");

  const checkIsDesktop = (currentWidth?: string) =>
    currentWidth ? ["md", "lg", "xl"].indexOf(currentWidth) >= 0 : false;
  const [isDesktop, setIsDesktop] = useState(checkIsDesktop(width));
  const [displayMap, setDisplayMap] = useState(isDesktop);

  const [activeTab, setActiveTab] = useState<string>(PARCEL_TABS.overview.id);
  const [historicalParcel, setHistoricalParcel] =
    useState<ParcelPredecessorTo | null>(null);
  const [geometries, setGeometries] = useState<Geometries | null>(null);
  const [mapImage, setmapImage] = useState<MapImage | null>(null);

  useEffect(() => {
    fetchParcelData(parcelId);
    setTabByPathname(pathname);

    return () => {
      dispatch(resetParcelApi());
      dispatch(resetPrecisionParcelApi());
      dispatch(resetParcelSowingPlanApi());
      dispatch(resetSeasonsSowingPlanApi());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchParcelData(parcelId);
    setMapExtraLayer(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelId]);

  useEffect(() => {
    dispatch(getParcelApi(parcelId));
  }, [dispatch, langId, parcelId]);

  useEffect(() => {
    const currentIsDesktop = isDesktop;
    const newIsDesktop = checkIsDesktop(width);

    if (currentIsDesktop !== newIsDesktop && newIsDesktop !== displayMap) {
      setDisplayMap(!displayMap);
    }

    setIsDesktop(newIsDesktop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const setTabByPathname = (pathname: string) => {
    let tabId = PARCEL_TABS.overview.id;
    const tabs = Object.keys(PARCEL_TABS);
    tabs.forEach((tab) => {
      if (pathname.toLowerCase().includes(PARCEL_TABS[tab].path)) {
        tabId = PARCEL_TABS[tab].id;
      }
    });
    activateTab(tabId);
  };

  const activateTab = (tabId: string) => {
    let displayMapNew = displayMap;
    if (activeTab === PARCEL_TABS.activities.id && checkIsDesktop(width)) {
      displayMapNew = true;
    }
    if (tabId === PARCEL_TABS.activities.id) {
      displayMapNew = false;
    }
    setActiveTab(tabId);
    setDisplayMap(displayMapNew);
    setGeometries(null);
  };

  const setMapExtraLayer = (
    source: MapImage | Geometries | null,
    isImage = false,
  ) => {
    if (isImage) {
      setGeometries(null);
      setmapImage(source as MapImage);
    } else {
      setGeometries(source as Geometries);
      setmapImage(null);
    }
  };

  const fetchParcelData = (parcelId: string) => {
    dispatch(resetSatelliteProductsApi());
    dispatch(getParcelApi(parcelId));
    dispatch(getParcelSeedApplicationApi(parcelId, true));
    dispatch(getParcelPredecessorsApi(parcelId));
    dispatch(getSatelliteProductsApi(parcelId));
    (
      dispatch(getPrecisionParcelApi(parcelId)) as unknown as Promise<unknown>
    ).then((res: RSAAResultAction) => {
      if (res?.error) {
        dispatch(resetPrecisionParcelApi());
      }
    });
    (
      dispatch(
        updateSeasonsSowingPlanApi(
          START_YEAR_FIRST_SOWING_SEASONS,
          SHOWN_NUMBER_SOWING_SEASONS,
        ),
      ) as unknown as Promise<unknown>
    ).then(() => {
      dispatch(
        getParcelSowingPlanApi(
          parcelId,
          START_YEAR_FIRST_SOWING_SEASONS,
          SHOWN_NUMBER_SOWING_SEASONS,
        ),
      );
    });

    if (countryCode === "CZ") {
      dispatch(getParcelEagriRestrictionsApi(parcelId));
    }
  };

  const confirmEditing = (newName: string) =>
    dispatch(updateParcelName(parcelId, newName));

  const toggleMap = () => setDisplayMap(!displayMap);

  const goToHistoricalParcel = (historicalParcel: ParcelPredecessorTo) => {
    history.push(
      `/farm/${farmId}/parcels/${historicalParcel.id}/${PARCEL_TABS[activeTab].path}`,
    );
    setHistoricalParcel(historicalParcel);

    if (checkIsDesktop(width)) {
      setDisplayMap(true);
    } else {
      setDisplayMap(false);
    }
  };

  const isHistorical = ParcelsService.isParcelHistorical(parcel);
  const displayContent = !displayMap || (width !== "xs" && width !== "sm");

  let parcelColor = null;
  if (!isFetchingParcel && parcel?.seedApplication) {
    parcelColor = parcel.seedApplication.seed.crop.color;
  }

  return (
    <CfErrorPage error={error}>
      <Grid className={classes.wrapper} container>
        {displayContent && (
          <Grid
            className={classes.bodyWrapper}
            item
            md={displayMap ? 8 : 12}
            xs={12}
          >
            <Scrollbars>
              <div className={classes.body}>
                <div className={classes.headerWithTabs}>
                  <div className={classes.headerWrapper}>
                    <PageHeader
                      actionButtons={
                        <ParcelDetailFabButton
                          ngRedirectToEph={ngRedirectToEph}
                          ngRedirectToHarvest={ngRedirectToHarvest}
                          ngRedirectToMowing={ngRedirectToMowing}
                          ngRedirectToSowing={ngRedirectToSowing}
                          ngRedirectToVrf={ngRedirectToVrf}
                          ngRedirectToVrs={ngRedirectToVrs}
                          parcelId={parcelId}
                          isVraAllowed={
                            variableApplicationStatus ===
                            satelliteProductsTypes.ACTIVE
                          }
                          ngRedirectToOtherActionsNew={
                            ngRedirectToOtherActionsNew
                          }
                        />
                      }
                      backButton={
                        <ParcelDetailButtonBack
                          farmId={farmId}
                          history={history}
                          parcelId={parcelId.toString()}
                        />
                      }
                      heading={
                        parcel ? (
                          <ParcelDetailHeader
                            confirmEditing={confirmEditing}
                            historicalParcel={historicalParcel}
                            isFetchingParcel={isFetchingParcel}
                            parcel={parcel}
                          />
                        ) : null
                      }
                    />
                  </div>
                  {farmSettings.eagriSettingsEnabled && (
                    <Box className={classes.warningMessageContainer}>
                      <CoreParcelWarningMessage parcel={parcel} />
                    </Box>
                  )}
                  {parcel ? (
                    <ParcelDetailTabs
                      activateTab={(tabId) => activateTab(tabId)}
                      activeTab={activeTab}
                      tabs={Object.values(PARCEL_TABS)}
                      width={width}
                      onChange={(value) =>
                        history.push(
                          `/farm/${farmId}/parcels/${parcel.id}/${PARCEL_TABS[value].path}`,
                        )
                      }
                    />
                  ) : null}
                </div>
                <Grid className={classes.contentWrapper} container>
                  <Grid
                    item
                    lg={displayMap ? "auto" : 1}
                    xl={displayMap ? "auto" : 2}
                  />
                  <Grid
                    className={classes.content}
                    item
                    lg={displayMap ? 12 : 10}
                    xl={displayMap ? 12 : 8}
                  >
                    {parcel?.id && (
                      <Suspense fallback={<CfLoader />}>
                        <Switch>
                          <Route
                            exact
                            path={`/farm/:farmId/parcels/:parcelId/${PARCEL_TABS.overview.path}`}
                            render={() => (
                              <ParcelDetailOverview
                                countryCode={countryCode}
                                displayedMap={displayMap}
                                farmId={farmId}
                                goToHistoricalParcel={goToHistoricalParcel}
                                parcel={parcel}
                              />
                            )}
                          />
                          <Route
                            exact
                            path={`/farm/:farmId/parcels/:parcelId/${PARCEL_TABS.activities.path}`}
                            render={() => (
                              <ParcelActivities
                                farmId={farmId}
                                langId={langId}
                                parcelId={parcelId}
                              />
                            )}
                          />
                        </Switch>
                      </Suspense>
                    )}
                  </Grid>
                  <Grid
                    item
                    lg={displayMap ? "auto" : 1}
                    xl={displayMap ? "auto" : 2}
                  />
                </Grid>
              </div>
            </Scrollbars>
            <ShowHideMap
              handleClick={() => toggleMap()}
              isMapHidden={!displayMap}
            />
          </Grid>
        )}
        <Grid
          className={classes.mapWrapper}
          item
          md={4}
          style={{ display: displayMap ? "block" : "none" }}
          xs={12}
        >
          {!displayContent && (
            <ShowHideMap handleClick={() => toggleMap()} isMapHidden={false} />
          )}
          <ParcelDetailMap
            displayMap={displayMap}
            geometries={geometries}
            goToHistoricalParcel={goToHistoricalParcel}
            isHistorical={isHistorical}
            mapImage={mapImage}
            ngGoToMainMap={ngGoToMainMap}
            parcelColor={parcelColor}
            parcelId={parcelId}
          />
        </Grid>
      </Grid>
    </CfErrorPage>
  );
};

export { ParcelDetail };

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: "100%",
  },
  headerWithTabs: {
    backgroundColor: theme.palette.common.white,
    borderBottom: "1px solid #EFEFEF",
  },
  headerWrapper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  bodyWrapper: {
    position: "relative",
  },
  body: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentWrapper: {
    height: "100%",
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    width: "100%",
  },
  mapWrapper: {
    height: "100%",
  },
  warningMessageContainer: {
    margin: "0 20px",
  },
}));
