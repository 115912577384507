import React from "react";

interface Props {
  color?: string;
  height?: string;
  width?: string;
}

const ChevronRightIcon = (props: Props) => {
  const { color, height, width } = props;
  return (
    <>
      <svg
        fill={color || "none"}
        height={height || "24"}
        viewBox="0 0 24 24"
        width={width || "24"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 18L16 12L10 6L8.6 7.4L13.2 12L8.6 16.6L10 18Z"
          fill={color || "black"}
        />
      </svg>
    </>
  );
};

export { ChevronRightIcon };
