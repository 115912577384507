import { groupBy } from "lodash";

import { SoilSamplesResponse } from "../../../../generated/api/satellite";
import {
  kmgZonesColors,
  percentageZonesColors,
  phZonesColors,
  SoilSamplesZonesColor,
  zonesColors,
} from "../../services/soilSamlesZonesColors";

import {
  Sample,
  ZoneV2,
} from "../../../../shared/api/sentinel/soilSamples/soilSamples.types";

const customOrderSoilSamples = [
  "PH",
  "P",
  "K",
  "MG",
  "CA",
  "KMG",
  "S",
  "FE",
  "ZN",
  "B",
  "HUMUS",
  "COx",
];

export const getValuesSoilSamplesData = (data: SoilSamplesResponse[]) => {
  if (!data) {
    return [];
  }

  const combinedSamples = data
    .reduce((acc: Sample[], obj) => acc.concat(obj.samples), [])
    .sort(
      (a, b) =>
        customOrderSoilSamples.indexOf(a.sample) -
        customOrderSoilSamples.indexOf(b.sample),
    );

  const groups = groupBy(
    combinedSamples,
    (item) => `${item.geometry.coordinates[0]}_${item.geometry.coordinates[1]}`,
  );

  return Object.values(groups);
};

export const getZonesSoilSamplesData = (data: SoilSamplesResponse[]) =>
  (data ?? []).reduce((acc: { [key: string]: ZoneV2[] }, item) => {
    item?.zones.forEach((zone) => {
      let colorArray: SoilSamplesZonesColor[];
      if (zone.sample === "PH") {
        colorArray = phZonesColors;
      } else if (zone.sample === "KMG") {
        colorArray = kmgZonesColors;
      } else if (zone.sample === "HUMUS" || zone.sample === "COx") {
        colorArray = percentageZonesColors;
      } else {
        colorArray = zonesColors;
      }

      if (!acc[zone.sample]) {
        acc[zone.sample] = [];
      }

      acc[zone.sample] = [
        ...acc[zone.sample],
        {
          ...zone,
          color: colorArray.find(
            (color) => color.zoneStrength === zone.zoneStrength,
          )?.color,
        },
      ];
    });
    return acc;
  }, {});

export const getPointsSoilSamplesData = (data: SoilSamplesResponse[]) =>
  (data ?? []).reduce((acc: { [key: string]: Sample[] }, item) => {
    item?.samples.forEach((point) => {
      if (!acc[point.sample]) {
        acc[point.sample] = [];
      }
      acc[point.sample] = [...acc[point.sample], point];
    });
    return acc;
  }, {});

export const getCombinedSoilSamplesData = (
  zones: { [key: string]: ZoneV2[] },
  points: { [key: string]: Sample[] },
) => {
  if (!zones) return;
  return Object.entries(zones)
    .map(([name, zones]) => ({
      name,
      zones,
      points: points[name] || [],
    }))
    .sort(
      (a, b) =>
        customOrderSoilSamples.indexOf(a.name) -
        customOrderSoilSamples.indexOf(b.name),
    );
};
