import angular from "angular";

import momentDurationFormatSetup from "moment-duration-format";
import moment from "moment";
import intercomInit from "./assets/vendor/intercom";
import Intercom from "./shared/services/Intercom.service";
import googleTagManagerInit from "./assets/vendor/google-tag-manager";
import common from "./common/common.module";
import components from "./components/components.module";
import admin from "./admin/admin.module";
import core from "./core/core.module";
import evidence from "./evidence/evidence.module";
import sensors from "./sensors/sensors.module";
import notifications from "./notifications/notifications.module";
import irrigation from "./irrigation/irrigation.module";
import telematics from "./telematics/telematics.module";
import catalogues from "./catalogues/catalogues.module";
import vendor from "./assets/vendor/vendor.module";
import GoogleAnalytics from "./shared/services/GoogleAnalytics.service";

momentDurationFormatSetup(moment);

export function createAppModule() {
  return angular
    .module("app", [
      common,
      components,
      admin,
      core,
      evidence,
      sensors,
      notifications,
      irrigation,
      telematics,
      vendor,
      catalogues,
    ])
    .config(config)
    .run(run);
}

export function fetchConfig() {
  return fetch(require("../config/config.json"))
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(
        `Unable to configure FE: ${response.status} ${response.statusText}`,
      );
    })
    .catch((error) => {
      throw new Error(`Problem fetching configuration: ${error.message}`);
    });
}

/* @ngInject */
export function config(
  $urlRouterProvider,
  $controllerProvider,
  $locationProvider,
  BACKEND_OPTIONS,
) {
  $locationProvider.html5Mode(true);
  $locationProvider.hashPrefix("!");

  $controllerProvider.allowGlobals();
  $urlRouterProvider.otherwise("/farm");

  if (BACKEND_OPTIONS.intercom_app_id) {
    intercomInit(BACKEND_OPTIONS.intercom_app_id);
    Intercom.boot(BACKEND_OPTIONS.intercom_app_id);
  }

  if (process.env.NODE_ENV === "production") {
    if (BACKEND_OPTIONS.gaId) {
      GoogleAnalytics.init(BACKEND_OPTIONS.gaId);
    }
    if (BACKEND_OPTIONS.gtmId) {
      googleTagManagerInit(BACKEND_OPTIONS.gtmId);
    }
  }
}

/* @ngInject */
export function run($rootScope, BACKEND_OPTIONS, $location) {
  if (process.env.NODE_ENV !== "production" || !BACKEND_OPTIONS.gtmId) {
    return;
  }

  $rootScope.$on("$locationChangeSuccess", () => {
    const dataLayer = (window.dataLayer = window.dataLayer || []);
    dataLayer.push({
      event: "ngRouteChange",
      attributes: {
        route: $location.path(),
      },
    });
  });
}
