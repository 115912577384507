import { queryOptions } from "@tanstack/react-query";

import { getAll } from "../../../../generated/api/telematics";
import { staleTime } from "../../../../shared/query-client";

const operationsQuery = () =>
  queryOptions({
    queryKey: ["telematics", "operations"],
    queryFn: ({ signal }) => getAll(undefined, { signal }),
    staleTime: staleTime.Infinity,
  });

export { operationsQuery };
