import { useQuery } from "@tanstack/react-query";

import { NO_CROP } from "../../../../evidence/actions/shared/misc/action.helpers";
import { InternalCropTo } from "../../../../generated/api/satellite";
import { useFarmIds } from "../../../../shared/api/client.utils";
import { SelectObject } from "../../../../shared/components/form/FormSelect/types";
import { cropsQuery } from "../../dashboard/api/dashboard.api";

const useCropOptions = () => {
  const fetchedCrops = useQuery(cropsQuery(useFarmIds()));

  const { crops, cropsOptions } = (() => {
    const sortedCrops: InternalCropTo[] = [];
    const sortedCropsOptions: SelectObject<InternalCropTo>[] = [];

    let noCrop: InternalCropTo | undefined;
    let noCropOption: SelectObject<InternalCropTo> | undefined;

    fetchedCrops.data?.data?.forEach((c) => {
      const cropOption = {
        key: c.id,
        value: c.name,
        obj: c,
      } as SelectObject<InternalCropTo>;

      if (c.id === NO_CROP.id) {
        noCrop = c;
        noCropOption = cropOption;
      } else {
        sortedCrops.push(c);
        sortedCropsOptions.push(cropOption);
      }
    });

    return {
      crops: noCrop ? [noCrop, ...sortedCrops] : sortedCrops,
      cropsOptions: noCropOption
        ? [noCropOption, ...sortedCropsOptions]
        : sortedCropsOptions,
    };
  })();

  return {
    crops,
    cropsOptions,
  };
};

export { useCropOptions };
