import React, { FC, useState } from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment, { Moment } from "moment";
import { FormattedMessage } from "react-intl";

interface PorUsageReportDialogProps {
  onAccept: (year: string) => void;
  onClose: () => void;
  showDialog?: boolean;
  isLoading: boolean;
}

const NitrogenBalanceReportDialog: FC<PorUsageReportDialogProps> = ({
  isLoading,
  onAccept,
  onClose,
  showDialog = false,
}) => {
  const classes = useStyles();

  const [reportYear, setReportYear] = useState<Moment>(moment());

  const handleSendReport = () => {
    const formattedYear = reportYear.format("yyyy").toString();
    onAccept(formattedYear);
  };

  const onDateFromChange = (value: Moment) => {
    setReportYear(value);
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogContainer }}
      data-test="report-por-dialog"
      onClose={onClose}
      open={showDialog}
    >
      <DialogTitle data-test="dialog-title">
        <FormattedMessage id="Reports.nitrogenBalance.dialogTitle" />
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.itemContainer}>
          <Grid className={classes.grid} item xs={5}>
            <DatePicker
              label={<FormattedMessage id="common.date" />}
              maxDate={undefined}
              minDate={undefined}
              name="dateFrom"
              onChange={onDateFromChange}
              openTo="year"
              value={reportYear}
              views={["year"]}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          alignItems="center"
          container
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            className={classes.button}
            id="reset"
            onClick={onClose}
            type="reset"
            variant="text"
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            className={classes.button}
            color="primary"
            disabled={isLoading}
            id="send"
            onClick={handleSendReport}
            type="submit"
            variant="text"
          >
            {!isLoading && <FormattedMessage id="common.generateReport" />}
            {isLoading && (
              <CircularProgress className={classes.progress} size={21} />
            )}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export { NitrogenBalanceReportDialog };

const useStyles = makeStyles(() => ({
  dialogContainer: {
    maxWidth: "400px",
  },
  progress: {
    marginLeft: 10,
  },
  itemContainer: {
    padding: "5px 0px",
  },
  button: {
    marginLeft: 8,
  },
  label: {
    fontSize: 12,
    marginBottom: 5,
  },
  grid: {
    marginRight: 30,
  },
}));
