import React from "react";

interface Props {
  color?: string;
  height?: string;
  width?: string;
}

const ChevronLeftIcon = (props: Props) => {
  const { color, height, width } = props;
  return (
    <>
      <svg
        fill={color || "none"}
        height={height || "24"}
        viewBox="0 0 24 24"
        width={width || "24"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 18L8 12L14 6L15.4 7.4L10.8 12L15.4 16.6L14 18Z"
          fill={color || "black"}
        />
      </svg>
    </>
  );
};
export { ChevronLeftIcon };
