import { queryOptions } from "@tanstack/react-query";

import { getKeysCombinations } from "../../../../generated/api/telematics";

const driveKeysQuery = (params: Parameters<typeof getKeysCombinations>[0]) =>
  queryOptions({
    queryKey: ["telematics", "keys", ...Object.values(params)],
    queryFn: ({ signal }) => getKeysCombinations(params, { signal }),
  });

export { driveKeysQuery };
