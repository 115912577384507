import { useQuery } from "@tanstack/react-query";

import { FarmPfVersionEnum } from "../../../../generated/api/satellite";
import { useFarmIds } from "../../../../shared/api/client.utils";

import { pfVersionQuery } from "./precision.api";

const usePfVersion = () => {
  const { farmIds } = useFarmIds();

  const pfVersion = useQuery(
    pfVersionQuery({
      farmIds,
    }),
  );

  const pfVersionValue = pfVersion.data?.data;

  const isV1 = pfVersionValue === FarmPfVersionEnum["1_0_0"];
  const isV2 = pfVersionValue === FarmPfVersionEnum["2_0_0"];

  return {
    pfVersionResponse: pfVersion,
    pfVersionValue: pfVersion.data?.data,
    isLoading: !pfVersion.isSuccess && !pfVersion.isError,
    isV1,
    isV2,
  };
};

export { usePfVersion };
