import { RSAA } from "redux-api-middleware";

import * as types from "./farmsAdmin.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../../api.constants";

import { getStatesForApiCall } from "../../../api.helpers";

export const forceGeoserverReset = (params: { farmId: string }) => ({
  [RSAA]: {
    endpoint: `admin/geoserver/farm/${params.farmId}/refresh-farm-cache?hardReset=true&`,
    method: methods.POST,
    module: modules.AGROEVIDENCE,
    types: getStatesForApiCall("ADMIN")("FORCE_GEOSERVER_RESET"),
  },
});

export const getUsersByFarmIdApi = (farmId: string) => ({
  [RSAA]: {
    endpoint: `admin/farms/${farmId}/users?`,
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_USERS_BY_FARM_REQUEST,
      types.GET_USERS_BY_FARM_SUCCESS,
      types.GET_USERS_BY_FARM_ERROR,
    ],
  },
});
