import React from "react";

import { usePfVersion } from "../../../core/precision/containers/api/usePfVersion";
import { FarmPfVersionEnum } from "../../../generated/api/satellite";

type Props = {
  requiredVersion: FarmPfVersionEnum;
};

const PfVersionGuard = ({
  children,
  requiredVersion,
}: React.PropsWithChildren<Props>) => {
  const { isLoading, pfVersionValue } = usePfVersion();

  if (!isLoading && requiredVersion === pfVersionValue) {
    return <>{children}</>;
  }

  return null;
};

export { PfVersionGuard };
