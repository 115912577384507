import { RSAA } from "redux-api-middleware";

import * as types from "./precision.constants";
import * as typesV2 from "./precisionV2.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import { RsaaMethods, RsaaTypes } from "../../api.types";

export const getPrecisionParcelsApi = () => ({
  [RSAA]: {
    endpoint: "precision-parcels?",
    method: methods.GET as RsaaMethods,
    module: modules.SENTINEL,
    types: [
      types.GET_PRECISION_PARCELS_REQUEST,
      types.GET_PRECISION_PARCELS_SUCCESS,
      types.GET_PRECISION_PARCELS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetPrecisionParcelsApi = () => ({
  type: types.RESET_PRECISION_PARCELS,
});

export const getPrecisionParcelApi = (parcelId: string) => ({
  [RSAA]: {
    endpoint: `precision-parcels/${parcelId}?`,
    method: methods.GET as RsaaMethods,
    module: modules.SENTINEL,
    types: [
      types.GET_PRECISION_PARCEL_REQUEST,
      types.GET_PRECISION_PARCEL_SUCCESS,
      types.GET_PRECISION_PARCEL_ERROR,
    ] as RsaaTypes,
  },
});

export const getPrecisionParcelV2Api = (parcelId: string) => ({
  [RSAA]: {
    endpoint: `precision-parcels/v2/${parcelId}?`,
    method: methods.GET as RsaaMethods,
    module: modules.SENTINEL,
    types: [
      typesV2.GET_PRECISION_PARCEL_REQUEST,
      typesV2.GET_PRECISION_PARCEL_SUCCESS,
      typesV2.GET_PRECISION_PARCEL_ERROR,
    ] as RsaaTypes,
  },
});

export const resetPrecisionParcelApi = () => ({
  type: types.RESET_PRECISION_PARCEL,
});

export const getPrecisionTaskDataApi = (
  taskDataId: string,
  taskId: string,
) => ({
  [RSAA]: {
    endpoint: `taskdata/${taskDataId}/${taskId}?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: types.getStatesForApiCall("GET_PRECISION_TASK_DATA"),
  },
});

export const getPrecisionTaskDataTimelineApi = (
  taskDataId: string,
  taskId: string,
  attribute: string,
) => ({
  [RSAA]: {
    endpoint: `taskdata/${taskDataId}/${taskId}/timeline?attribute=${attribute}&`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: types.getStatesForApiCall("GET_PRECISION_TASK_TIMELINE_DATA"),
  },
});

export const getPrecisionTaskDataReportApi = (
  taskDataId: string,
  taskId: string,
) => ({
  [RSAA]: {
    endpoint: `taskdata/${taskDataId}/${taskId}/report?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: types.getStatesForApiCall("GET_PRECISION_TASK_DATA_REPORT"),
  },
});
