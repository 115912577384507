import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as typesV2 from "./variableApplicationV2.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import { RsaaMethods, RsaaTypes } from "../../api.types";

export const getMigrationApi = () => ({
  [RSAA]: {
    endpoint: `migration?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      typesV2.GET_MIGRATION_REQUEST,
      typesV2.GET_MIGRATION_SUCCESS,
      typesV2.GET_MIGRATION_ERROR,
    ],
  },
});

type GetParcelsV2ApiType = {
  search?: string;
  context?: string;
};

export const getParcelsV2Api = (params: GetParcelsV2ApiType) => ({
  [RSAA]: {
    endpoint: `precision-parcels/v2/ae-schema?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.SENTINEL,
    context: params.context ?? null,
    types: [
      typesV2.GET_PARCELS_REQUEST,
      typesV2.GET_PARCELS_SUCCESS,
      typesV2.GET_PARCELS_ERROR,
    ] as RsaaTypes,
  },
});

export const getVariableActionZonesV2Api = (
  farmId: string,
  parcelId: string,
) => ({
  [RSAA]: {
    endpoint: `variable-applications/v2/zones/${farmId}/${parcelId}?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      typesV2.GET_VARIABLE_ACTION_ZONES,
      typesV2.GET_VARIABLE_ACTION_ZONES_SUCCESS,
      typesV2.GET_VARIABLE_ACTION_ZONES_FAILURE,
    ],
  },
});
