import { AnyAction } from "redux";

import * as types from "./variableApplication.constants";
import * as typesV2 from "./variableApplicationV2.constants";

import { FarmPfVersionEnum } from "../../../../generated/api/satellite";

import {
  AgIntegratedJobsTo,
  MachineTo,
  VariableActionZonesTo,
  VariableApplicationPutRequestBody,
  VariableApplicationResponse,
} from "../../satellite/satellite.types";

const isWriterJob = (job: AgIntegratedJobsTo) => job.type === "WRITER";
const isUploaderJob = (job: AgIntegratedJobsTo) => job.type === "UPLOADER";

interface AgiJob extends AgIntegratedJobsTo {
  result: unknown;
  isFetching: boolean;
}

export type VariableApplicationActionState = {
  isFetching: boolean;
  error: Record<string, unknown>;
  items: string[];
  variableExpense?: VariableApplicationPutRequestBody;
  isVariableExpenseFetching: boolean;
  variableExpenseError: Record<string, unknown>;
  actionIds?: string[];
  isFetchingActions: boolean;
  errorActions: Record<string, unknown>;
  variableExpenses?: VariableApplicationResponse[];
  variableExpensesIsFetching: boolean;
  variableExpensesError: null;
  saveVariableExpenseIsFetching: boolean;
  // isFetchingAgiStatus: boolean;
  // agiStatus: {};
  agiJobs: Record<string, AgiJob>;
  agiMachines: MachineTo[];
  isExportingVA: boolean;
  variableActionZones: VariableActionZonesTo[];
  variableActionZonesIsFetching: boolean;
  bulkIsDeleting: boolean;
  farmPfVersion?: FarmPfVersionEnum;
  farmPfVersionIsFetching: boolean;
};

const initialState: VariableApplicationActionState = {
  isFetching: false,
  error: {},
  items: [],
  variableExpense: undefined, // {},
  isVariableExpenseFetching: false,
  variableExpenseError: {},
  actionIds: [],
  isFetchingActions: false,
  errorActions: {},
  variableExpenses: undefined,
  variableExpensesIsFetching: false,
  variableExpensesError: null,
  saveVariableExpenseIsFetching: false,
  // isFetchingAgiStatus: false,
  // agiStatus: {},
  agiJobs: {},
  agiMachines: [],
  isExportingVA: false,
  variableActionZones: [],
  variableActionZonesIsFetching: false,
  bulkIsDeleting: false,
  farmPfVersion: undefined,
  farmPfVersionIsFetching: false,
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.GET_VARIABLE_FERTILIZATION_PARCELS:
        return {
          ...state,
          isFetching: true,
          error: {},
        };
      case types.GET_VARIABLE_FERTILIZATION_PARCELS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload,
        };
      case types.GET_VARIABLE_FERTILIZATION_PARCELS_FAILURE:
        return {
          ...state,
          isFetching: false,
          error: action.payload,
          totalCount: 0,
        };
      case types.RESET_VARIABLE_FERTILIZATION_PARCELS:
        return {
          ...state,
          items: [],
        };
      case types.POST_VARIABLE_FERTILIZATION_EXPENSE:
        return {
          ...state,
          isVariableExpenseFetching: true,
          variableExpenseError: {},
        };
      case types.POST_VARIABLE_FERTILIZATION_EXPENSE_SUCCESS:
        return {
          ...state,
          isVariableExpenseFetching: false,
          variableExpense: action.payload,
        };
      case types.POST_VARIABLE_FERTILIZATION_EXPENSE_FAILURE:
        return {
          ...state,
          isVariableExpenseFetching: false,
          variableExpenseError: action.payload,
        };
      case types.RESET_VARIABLE_FERTILIZATION_EXPENSE:
        return {
          ...state,
          variableExpense: {},
          isVariableExpenseFetching: false,
          variableExpenseError: {},
        };

      case types.GET_VARIABLE_FERTILIZATION_ACTIONS:
        return {
          ...state,
          isFetchingActions: true,
          errorActions: {},
        };
      case types.GET_VARIABLE_FERTILIZATION_ACTIONS_SUCCESS:
        return {
          ...state,
          isFetchingActions: false,
          actionIds: action.payload,
        };
      case types.GET_VARIABLE_FERTILIZATION_ACTIONS_FAILURE:
        return {
          ...state,
          isFetchingActions: false,
          errorActions: action.payload,
        };
      case types.RESET_VARIABLE_FERTILIZATION_ACTIONS:
        return {
          ...state,
          actionIds: null,
        };
      case types.GET_VARIABLE_FERTILIZATION_EXPENSES:
        return {
          ...state,
          variableExpenses: null,
          variableExpensesIsFetching: true,
        };
      case types.GET_VARIABLE_FERTILIZATION_EXPENSES_SUCCESS:
        return {
          ...state,
          variableExpenses: action.payload,
          variableExpensesError: null,
          variableExpensesIsFetching: false,
        };
      case types.GET_VARIABLE_FERTILIZATION_EXPENSES_FAILURE:
        return {
          ...state,
          variableExpenses: action.payload,
          variableExpensesIsFetching: false,
        };
      case types.RESET_VARIABLE_FERTILIZATION_EXPENSES:
        return {
          ...state,
          variableExpenses: null,
        };
      case types.DELETE_VARIABLE_BULK_IN_PROGRESS:
        return {
          ...state,
          bulkIsDeleting: action.payload,
        };

      case types.PUT_VARIABLE_FERTILIZATION_EXPENSE:
        return {
          ...state,
          saveVariableExpenseIsFetching: true,
        };
      case types.PUT_VARIABLE_FERTILIZATION_EXPENSE_SUCCESS:
        return {
          ...state,
          saveVariableExpenseIsFetching: false,
        };
      case types.PUT_VARIABLE_FERTILIZATION_EXPENSE_FAILURE:
        return {
          ...state,
          saveVariableExpenseIsFetching: false,
        };
      case types.EXPORT_VARIABLE_APPLICATION:
      case types.EXPORT_VARIABLE_APPLICATION_LEGACY:
        return {
          ...state,
          isExportingVA: true,
        };

      case types.EXPORT_VARIABLE_APPLICATION_SUCCESS:
      case types.EXPORT_VARIABLE_APPLICATION_LEGACY_SUCCESS:
      case types.EXPORT_VARIABLE_APPLICATION_ERROR:
      case types.EXPORT_VARIABLE_APPLICATION_LEGACY_ERROR:
        return {
          ...state,
          isExportingVA: false,
        };

      case types.GET_AGI_JOBS:
        return {
          ...state,
          agiJobs: {
            ...state.agiJobs,
            [action.payload.applicationId]: {
              ...(state.agiJobs[action.payload.applicationId] || {}),
              isFetching: true,
            },
          },
        };
      case types.GET_AGI_JOBS_SUCCESS:
        return {
          ...state,
          agiJobs: {
            ...state.agiJobs,
            [action.payload.applicationId]: {
              isFetching: false,
              result: {
                writer: action.payload.jobs.filter(isWriterJob),
                uploader: action.payload.jobs.filter(isUploaderJob),
              },
            },
          },
        };
      case types.GET_AGI_MACHINES_SUCCESS:
        return {
          ...state,
          agiMachines: action.payload,
        };

      case types.GET_VARIABLE_ACTION_ZONES:
      case typesV2.GET_VARIABLE_ACTION_ZONES:
        return {
          ...state,
          variableActionZones: [],
          variableActionZonesIsFetching: true,
        };
      case types.GET_VARIABLE_ACTION_ZONES_SUCCESS:
      case typesV2.GET_VARIABLE_ACTION_ZONES_SUCCESS:
        return {
          ...state,
          variableActionZonesIsFetching: false,
          variableActionZones: action.payload,
        };
      case types.GET_VARIABLE_ACTION_ZONES_FAILURE:
      case typesV2.GET_VARIABLE_ACTION_ZONES_FAILURE:
        return {
          ...state,
          variableActionZonesIsFetching: false,
        };
      case types.RESET_VARIABLE_ACTION_ZONES:
      case typesV2.RESET_VARIABLE_ACTION_ZONES:
        return {
          ...state,
          variableActionZones: [],
          variableActionZonesIsFetching: false,
        };
      case typesV2.GET_MIGRATION_REQUEST:
        return {
          ...state,
          farmPfVersionIsFetching: true,
        };
      case typesV2.GET_MIGRATION_SUCCESS:
        return {
          ...state,
          farmPfVersionIsFetching: false,
          farmPfVersion: action.payload,
        };
      case typesV2.GET_MIGRATION_ERROR:
        return {
          ...state,
          farmPfVersionIsFetching: false,
          farmPfVersion: undefined,
        };

      default:
        return state;
    }
  };
