import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";

type Props = {
  parcelsArea: number;
};

const TotalActivityArea = ({ parcelsArea }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.totalActivityArea}>
      <span className={classes.totalAreaLabel}>
        <FormattedMessage id="ParcelZoneSelector.totalActivityArea" />:{" "}
      </span>
      <span className={classes.totalAreaValue}>
        <CfFormattedNumber decimalDigits={3} value={parcelsArea} /> ha
      </span>
    </div>
  );
};

export { TotalActivityArea };

const useStyles = makeStyles((theme: Theme) => ({
  totalActivityArea: {
    backgroundColor: theme.palette.grey[100],
    padding: "12px 24px",
    overflow: "hidden",
    marginTop: 10,
    fontWeight: "bold",
  },
  totalAreaLabel: {
    color: theme.palette.grey[500],
  },
  totalAreaValue: {
    float: "right",
  },
}));
