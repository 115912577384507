import React, { Fragment } from "react";

import _ from "lodash";
import { FormattedDate, FormattedMessage } from "react-intl";
import { createSelector } from "reselect";

import {
  getActions,
  getIsFetching,
  getTotalCount,
  isDeletingActions,
} from "../../../../../shared/api/agroevidence/actions/actions.selectors";
import { getVariableActionIds } from "../../../../../shared/api/sentinel/variableApplication/variableApplication.selectors";

import { ActionLabel } from "../../../../../evidence/actions/list/components/ActionLabel/ActionLabel";
import { getRouting } from "../../../../../evidence/actions/shared/misc/action.helpers";
import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import { FormattedAmount } from "../../../../../shared/components/common/FormattedAmount/FormattedAmount";
import { CropName } from "../../../../../shared/components/specific/CropName/CropName";
import CfTableCellNumber from "../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";
import { COLOR_GREY } from "../../../../../theme";

import { ActionsState } from "../../../../../reducers/actions.reducer.types";
import { ParcelsState } from "../../../../../reducers/parcels.reducer.types";
import {
  ActionExpenseOverviewTo,
  ActionOverviewTo,
} from "../../../../../shared/api/agroevidence/agroevidence.types";
import { TableProcessedParcelAction } from "../../../../../shared/api/agroevidence/parcels/parcels.types";

export const getParcelActivitiesPage = (state: ActionsState) =>
  state.ui.parcelActivities.page;
export const getParcelActivitiesRowsPerPage = (state: ActionsState) =>
  state.ui.parcelActivities.rowsPerPage;
export const getParcelActivitiesOrder = (state: ActionsState) =>
  state.ui.parcelActivities.order;
export const getParcelActivitiesOrderBy = (state: ActionsState) =>
  state.ui.parcelActivities.orderBy;
// TODO: ParcelState throws error here in getSelectedOnPage
export const getParcelActivitiesSelected = (state: ActionsState) =>
  state.ui.parcelActivities.selected;
export const getParcelActivitiesAdvancedFilter = (
  state: ActionsState | ParcelsState,
) => state.ui.parcelActivities.advancedFilter;
export const getParcelActivitiesDateFilter = (state: ActionsState) =>
  state.ui.parcelActivities.dateFilter;

export const getIsFetchingParcelActivities = (state: ActionsState) =>
  getIsFetching(state) || isDeletingActions(state);
export const getParcelActivitiesTotalCount = (state: ActionsState) =>
  getTotalCount(state);

export const getProcessedActivities = createSelector(
  getActions,
  getVariableActionIds,
  (actions, variableActionIds) =>
    processValues(actions, variableActionIds || []),
);

export const getSelectedOnPage = createSelector(
  getActions,
  getParcelActivitiesSelected,
  (items, selected) =>
    _.intersection(
      items.map((item) => item.id),
      selected,
    ),
);

const getActionMaterial = (expense: ActionExpenseOverviewTo) =>
  expense?.materialName ?? "-";

const getActionMaterialElementValue = (
  expense: ActionExpenseOverviewTo,
  element: string,
) => (
  <CfTableCellNumber>
    {expense && expense[element as keyof ActionExpenseOverviewTo] ? (
      <CfFormattedNumber
        value={expense[element as keyof ActionExpenseOverviewTo] as number}
      />
    ) : (
      "-"
    )}
  </CfTableCellNumber>
);

const getActionDose = (expense: ActionExpenseOverviewTo) =>
  expense?.amount ? (
    <FormattedAmount expense={expense} param="amount" perHa={false} />
  ) : (
    "-"
  );
const getActionDosage = (expense: ActionExpenseOverviewTo) =>
  expense?.dosePerHa ? (
    <FormattedAmount expense={expense} param="dosePerHa" perHa={true} />
  ) : (
    "-"
  );

const styles = {
  label: {
    span: {
      minWidth: "max-content",
      display: "flex",
    },
  },
};

const processValues = (
  parcelActions: ActionOverviewTo[],
  variableActionIds: string[],
): TableProcessedParcelAction[] =>
  parcelActions.map((activity) => {
    const { expenses, parcels } = activity;
    return {
      id: activity.id,
      isDraft: activity.isDraft,
      route: getRouting(activity, variableActionIds),
      single: {
        "dateStart,id": {
          val: <FormattedDate value={activity.date} />,
          rowspan: expenses.length,
        },
        "actionType.localizedNames.value": {
          val: (
            <Fragment>
              <span style={{ fontWeight: 500 }}>
                <FormattedMessage id={`common.${activity.actionType.code}`} />
              </span>
              {variableActionIds?.includes?.(activity.id) && (
                <span style={styles.label.span}>
                  <ActionLabel />
                </span>
              )}
            </Fragment>
          ),
          rowspan: expenses.length,
        },
        source: {
          val: (
            <FormattedMessage id={`common.action.source.${activity.source}`} />
          ),
          rowspan: expenses.length,
        },
        activityCrop: {
          val: (
            <CropName cropType={activity.seedApplicationType}>
              {activity.crop?.name ?? "-"}
            </CropName>
          ),
          rowspan: expenses.length,
        },
        area: {
          val: (
            <CfTableCellNumber>
              <span>
                <div>
                  <CfFormattedNumber
                    decimalDigits={2}
                    value={parcels[0].parcelArea - parcels[0].restrictedArea}
                  />
                </div>
                <div style={{ color: COLOR_GREY[500] }}>
                  <CfFormattedNumber
                    decimalDigits={2}
                    value={parcels[0].parcelArea}
                  />
                </div>
              </span>
            </CfTableCellNumber>
          ),
          rowspan: expenses.length,
        },
        accountableN: {
          val: activity.seedApplicationNitrogen ? (
            <CfFormattedNumber
              decimalDigits={2}
              value={activity.seedApplicationNitrogen}
            />
          ) : (
            "-"
          ),
          rowspan: expenses.length,
        },
        material: {
          val: getActionMaterial(expenses[0]),
        },
        dose: {
          val: getActionDose(expenses[0]),
        },
        dosePerHectare: {
          val: getActionDosage(expenses[0]),
        },
        n: {
          val: getActionMaterialElementValue(expenses[0], "nitrogen"),
        },
        p2o5: {
          val: getActionMaterialElementValue(expenses[0], "p2o5"),
        },
        k2o: {
          val: getActionMaterialElementValue(expenses[0], "k2o"),
        },
        mgo: {
          val: getActionMaterialElementValue(expenses[0], "mgo"),
        },
        cao: {
          val: getActionMaterialElementValue(expenses[0], "cao"),
        },
        s: {
          val: getActionMaterialElementValue(expenses[0], "s"),
        },
      },
      multi: expenses.slice(1).map((expense) => ({
        material: {
          val: getActionMaterial(expense),
        },
        dose: {
          val: getActionDose(expense),
        },
        dosePerHectare: {
          val: getActionDosage(expense),
        },
        n: {
          val: getActionMaterialElementValue(expense, "nitrogen"),
        },
        p2o5: {
          val: getActionMaterialElementValue(expense, "p2o5"),
        },
        k2o: {
          val: getActionMaterialElementValue(expense, "k2o"),
        },
        mgo: {
          val: getActionMaterialElementValue(expense, "mgo"),
        },
        cao: {
          val: getActionMaterialElementValue(expense, "cao"),
        },
        s: {
          val: getActionMaterialElementValue(expense, "s"),
        },
      })),
    };
  });
