import React, { Fragment, RefObject, useEffect, useState } from "react";

import { Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import find from "lodash/find";
import { FormattedMessage, useIntl } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FieldArrayFieldsProps, FieldArrayMetaProps } from "redux-form";

import { getFarmPfVersion } from "../../../../../shared/api/sentinel/variableApplication/variableApplication.selectors";
import { getActionRelatedDrives } from "../../../../../shared/api/telematics/aggregations/aggregations.selectors";
import {
  getParcelsAndZonesSuggestions,
  getParcelsToAdd,
} from "../../selectors/actions.selectors";

import {
  addInitParcelsToAdd,
  addInitZoneParcelsToAdd,
  addParcelOrZoneParcelsToAdd,
  clearParcelsAndZonesSuggestions,
  clearParcelsToAdd,
  fetchParcelsAndZonesSuggestions,
  fetchParcelHistorySubtractions,
  updateParcelsList,
  updateParcelsSubtractions,
} from "../../actions/actions.actions";

import { FarmPfVersionEnum } from "../../../../../generated/api/satellite";
import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import ListSelectorError from "../../../../../shared/components/form/ListSelectorError/ListSelectorError";
import { AnyTodo, Thunk } from "../../../../../types";
import ActionParcelsList from "../../components/ActionParcelsList/ActionParcelsList";
import { ParcelZoneSelector } from "../../components/ParcelZoneSelector/ParcelZoneSelector";
import { SplitActionsWarningMessage } from "../../components/SplitActions/SplitActionsWarningMessage";
import { mapRequestBodyParcelsSubtraction } from "../../services/ParcelSubtractionMapper.services";

import { ActionsState } from "../../../../../reducers/actions.reducer.types";
import {
  ActionExpenseTo,
  ActionParcelMinimalTo,
  CropTo,
  ParcelSubtractionResponse,
  ParcelTo,
  ZoneTo,
} from "../../../../../shared/api/agroevidence/agroevidence.types";

type ReduxProps = ConnectedProps<typeof connector>;
interface Props {
  checkedItems: Record<number, boolean>;
  expenses: ActionExpenseToExtended[];
  fields: FieldArrayFieldsProps<AnyTodo>;
  filter: AnyTodo;
  farmId: string;
  driftClass: string;
  actionDate: Record<string, unknown>;
  formName: string;
  handleCheckboxChange: (fieldId: string, checked: boolean) => void;
  initParcelIds: string[];
  initZoneIds: string[];
  isDraft: boolean;
  isEditing: boolean;
  isSplitting: boolean;
  maxParcelCount: number;
  meta: FieldArrayMetaProps;
  numberOfCheckedItems: number;
  parcelsArea: number;
  parcelsOnly: boolean;
  placeholder: string;
  setIsSplittingError: (isSplittingError: boolean) => void;
  subtractableAreasIds: string[];
  targetCrop: CropTo | null;
  zonesOnly: boolean;
}

export function ActionParcelControl(props: Readonly<Props & ReduxProps>) {
  const {
    actionRelatedDrives,
    addInitParcelsToAdd,
    addInitZoneParcelsToAdd,
    addParcelOrZoneParcelsToAdd,
    checkedItems,
    clearParcelsAndZonesSuggestions,
    clearParcelsToAdd,
    expenses,
    fetchParcelHistorySubtractions,
    fetchParcelsAndZonesSuggestions,
    fields,
    formName,
    handleCheckboxChange,
    initParcelIds = null,
    initZoneIds = null,
    isDraft = false,
    isEditing,
    isSplitting = false,
    maxParcelCount = null,
    meta,
    numberOfCheckedItems = 0,
    parcelsArea,
    parcelsOnly = false,
    parcelsToAdd = [],
    placeholder = "ParcelZoneSelector.placeholder",
    setIsSplittingError,
    subtractableAreasIds = undefined,
    suggestions,
    targetCrop = null,
    updateParcelsList,
    updateParcelsSubtractions,
    zonesOnly = false,
  } = props;

  const classes = useStyles();
  const intl = useIntl();

  const [isInitialized, setIsInitialized] = useState(false);

  const { error, submitFailed } = meta;

  const isParcelInList = (parcelToCheck: ParcelTo) =>
    find(fields.getAll(), ["id", parcelToCheck.id]) !== undefined;

  const getSuggestions = (searchInput: string) => {
    fetchParcelsAndZonesSuggestions(
      searchInput,
      parcelsOnly,
      zonesOnly,
      undefined,
      true,
    );
  };

  const clearSuggestions = () => {
    clearParcelsAndZonesSuggestions(parcelsOnly, zonesOnly);
  };

  const addParcels = (parcels: ParcelTo[]) => {
    updateParcelsList(
      [
        ...fields.getAll(),
        ...parcels
          .filter((parcel) => !isParcelInList(parcel))
          .map((p) => ({
            ...p,
            subtractableAreas: [],
            restrictedArea: 0,
            actionParcelTotalArea: p.area,
          })),
      ],
      formName,
    );
    return parcels;
  };

  const handleSuggestionSelected = (suggestion: ParcelTo) => {
    addParcelOrZoneParcelsToAdd(suggestion);
  };

  const handleItemRemove = async (index: number) => {
    fields.remove(index);
  };

  const updateParcelSubtractions = (
    parcels: IParcels[],
    expenses: ActionExpenseToExtended[],
    targetCrop: CropTo | null,
    formName: string,
  ) => {
    const parcelsInForm = parcels;

    let requestParam;
    if (formName === "eph") {
      const plantProtectionsExpenses = expenses?.filter(
        (expens) => expens.material.materialTypeId === "CH",
      );
      requestParam = mapRequestBodyParcelsSubtraction(
        parcels,
        plantProtectionsExpenses,
        targetCrop,
      );
    } else {
      requestParam = mapRequestBodyParcelsSubtraction(parcels);
    }

    (
      updateParcelsSubtractions(requestParam) as unknown as Promise<
        ParcelSubtractionResponse[]
      >
    ).then((updatedSubtractions) => {
      updatedSubtractions.forEach((item) => {
        const index = parcels.findIndex(
          (parcel) => parcel.id === item.parcelId,
        );
        const checkedSubtractableAreas = item.subtractions.filter(
          (sa) => sa.isUsed,
        );
        const calculatedRestrictedArea = checkedSubtractableAreas.reduce(
          (sum: number, area: { area: number }) => sum + area.area,
          0,
        );
        parcelsInForm[index].subtractableAreas =
          item.subtractions as unknown as number;
        parcelsInForm[index].restrictedArea = calculatedRestrictedArea;
      });
    });

    updateParcelsList(parcelsInForm, formName);
  };

  useEffect(() => {
    const isSplittingError =
      (isSplitting && numberOfCheckedItems === fields.getAll()?.length) ||
      (isSplitting && numberOfCheckedItems === 0);

    if (setIsSplittingError) {
      setIsSplittingError(isSplittingError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSplitting, numberOfCheckedItems, fields.getAll()?.length]);

  useEffect(() => {
    if (Array.isArray(initParcelIds) && initParcelIds?.length) {
      addInitParcelsToAdd(initParcelIds);
    }

    if (Array.isArray(initZoneIds) && initZoneIds?.length) {
      addInitZoneParcelsToAdd(initZoneIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    updateParcelsList(fields.getAll(), formName);

    const newParcels = fields.getAll();
    const parcelIds = newParcels?.map((p: ParcelTo) => p.id);
    const fetchPromises: Promise<void>[] = [];

    newParcels?.forEach((parcel: ParcelTo) => {
      const index = newParcels.findIndex(
        (option: ParcelTo) => option.id === parcel.id,
      );
      const fetchPromise = (
        fetchParcelHistorySubtractions(
          parcel.id,
          parcelIds,
        ) as unknown as Promise<ParcelSubtractionResponse>
      ).then((predefinedSas) => {
        newParcels[index].subtractableAreas = predefinedSas;
      });
      fetchPromises.push(fetchPromise);
    });

    if (fetchPromises.length) {
      Promise.all(fetchPromises).then(() => {
        updateParcelSubtractions(newParcels, expenses, targetCrop, formName);
      });
    }

    setIsInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.getAll()?.length, formName]);

  useEffect(() => {
    if (!fields.getAll() || !isInitialized) {
      return;
    }
    updateParcelSubtractions(fields.getAll(), expenses, targetCrop, formName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenses?.length, targetCrop, formName]);

  useEffect(() => {
    /*
     * Adds parcels from the external prop parcelsToAdd,
     * which is the user interaction, but parcels can be
     * added also thru the form initialization (like from the local storage)
     */
    if (parcelsToAdd?.length > 0) {
      addParcels(parcelsToAdd);
      clearParcelsToAdd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formName, parcelsToAdd?.length]);

  return (
    <Fragment>
      {isEditing &&
      (maxParcelCount ? fields?.length < maxParcelCount : true) ? (
        <Fragment>
          <ParcelZoneSelector
            autoFocus={true}
            inputRef={(ref: RefObject<HTMLInputElement>) => ref}
            onSuggestionsClearRequested={clearSuggestions}
            onSuggestionSelected={handleSuggestionSelected}
            onSuggestionsFetchRequested={getSuggestions}
            placeholder={placeholder}
            suggestions={suggestions?.map((sugg) => ({
              ...sugg,
              title: intl.formatMessage({ id: sugg.title }),
            }))}
          />
          {submitFailed && error && <ListSelectorError error={error} />}
        </Fragment>
      ) : (
        <div className={classes.parcelsLabel}>
          <FormattedMessage id="common.parcels" />:
        </div>
      )}
      <ActionParcelsList
        actionRelatedDrives={actionRelatedDrives}
        checkedItems={checkedItems}
        expenses={expenses}
        fields={fields}
        formName={formName}
        handleCheckboxChange={handleCheckboxChange}
        isDraft={isDraft}
        isEditing={isEditing}
        isSplitting={isSplitting}
        onItemRemove={handleItemRemove}
        subtractableAreasIds={subtractableAreasIds}
        targetCrop={targetCrop}
      />
      <SplitActionsWarningMessage
        isSplitting={isSplitting}
        numberOfCheckedItems={numberOfCheckedItems}
        numberOfParcels={fields.getAll()?.length}
        setIsSplittingError={setIsSplittingError}
      />
      {parcelsArea > 0 && (
        <Grid container justifyContent="center" spacing={2}>
          <Grid item lg={6} md={7} sm={8} xs={12}>
            <div className={classes.totalActivityArea}>
              <span className={classes.totalAreaLabel}>
                <FormattedMessage id="ParcelZoneSelector.totalActivityArea" />:{" "}
              </span>
              <span className={classes.totalAreaValue}>
                <CfFormattedNumber value={parcelsArea} /> ha
              </span>
            </div>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state: ActionsState, props: Props) => {
  const { filter, formName } = props;
  const farmPfVersion = getFarmPfVersion(state);
  const isV2 = farmPfVersion === FarmPfVersionEnum["2_0_0"];

  return {
    suggestions: getParcelsAndZonesSuggestions(
      (i: ParcelTo) => (!isV2 && filter?.length ? filter.includes(i.id) : true),
      formName,
    )(state) as ISuggestion,
    parcelsToAdd: getParcelsToAdd(formName, state) as ParcelTo[],
    actionRelatedDrives: getActionRelatedDrives(state),
  };
};

const mapDispatchToProps = (dispatch: Thunk<ActionsState>) =>
  bindActionCreators(
    {
      fetchParcelsAndZonesSuggestions,
      clearParcelsAndZonesSuggestions,
      addParcelOrZoneParcelsToAdd,
      clearParcelsToAdd,
      addInitParcelsToAdd,
      addInitZoneParcelsToAdd,
      fetchParcelHistorySubtractions,
      updateParcelsSubtractions,
      updateParcelsList,
    },
    dispatch,
  );

interface IParcels extends ActionParcelMinimalTo {
  subtractableAreas: number;
}

interface ActionExpenseToExtended extends ActionExpenseTo {
  material: {
    materialTypeId: string;
  };
}

type ISuggestion = [
  {
    items: ParcelTo[];
    title: string;
  },
  {
    items: ZoneTo;
    title: string;
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  parcelsLabel: {
    color: theme.palette.grey[500],
  },
  totalActivityArea: {
    backgroundColor: theme.palette.grey[100],
    padding: "12px 24px",
    overflow: "hidden",
    marginTop: 10,
  },
  totalAreaLabel: {
    color: theme.palette.grey[500],
  },
  totalAreaValue: {
    float: "right",
  },
  messageError: {
    color: theme.palette.error.main,
  },
  messageWarning: {
    color: theme.palette.secondary.main,
  },
}));

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ActionParcelControl);
