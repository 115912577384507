import React, { useEffect, useRef } from "react";

import { FieldArrayRenderProps, useFormikContext, useField } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getParcelsToAdd,
  getParcelsAndZonesSuggestions,
} from "../../selectors/reports.selectors";

import {
  addParcelOrZoneParcelsToAdd,
  clearParcelsAndZonesSuggestions,
  clearParcelsToAdd,
  fetchParcelsAndZonesSuggestions,
} from "../../actions/reports.actions";

import ListSelectorError from "../../../../shared/components/form/ListSelectorError/ListSelectorError";
import { getError } from "../../../../shared/misc/formHelpers";
import { AnyTodo, Thunk } from "../../../../types";
import { ParcelZoneSelector } from "../../../actions/shared/components/ParcelZoneSelector/ParcelZoneSelector";
import ReportsParcelsList from "../../components/ReportsParcelsList/ReportsParcelsList";
import {
  ParcelsAndZonesSuggestionType,
  ReportsFormValues,
} from "../Reports/types";

import { ReportsState } from "../../../../reducers/reports.reducer.types";
import { ParcelTo } from "../../../../shared/api/agroevidence/agroevidence.types";

type OwnProps = {
  arrayHelpers: FieldArrayRenderProps;
  hasSubmitted: boolean;
  name: string;
  parcelsOnly?: boolean;
  zonesOnly?: boolean;
};

type ReduxProps = ConnectedProps<typeof connector>;

type Props = OwnProps & ReduxProps;

const ReportsParcelsControl = ({
  addParcelOrZoneParcelsToAdd,
  arrayHelpers,
  clearParcelsAndZonesSuggestions,
  clearParcelsToAdd,
  fetchParcelsAndZonesSuggestions,
  hasSubmitted,
  name,
  parcelsOnly,
  parcelsToAdd,
  suggestions: suggestionProp,
  zonesOnly,
}: Props) => {
  const suggestions: ParcelsAndZonesSuggestionType[] = suggestionProp;

  const { formatMessage } = useIntl();
  const { setFieldValue } = useFormikContext<ReportsFormValues>();
  const [field, meta] = useField(name);
  const isError = !!getError(meta);

  const prevParcelsToAddLengthRef = useRef<number>(-1);

  useEffect(() => {
    const isParcelInList = (parcelToCheck: ParcelTo) =>
      field.value.some((parcel: ParcelTo) => parcel.id === parcelToCheck.id);

    const addParcels = (parcels: ParcelTo[]) => {
      const parcelsIndFields = field.value || [];
      setFieldValue("parcels", [
        ...parcelsIndFields,
        ...parcels.filter((parcel) => !isParcelInList(parcel)),
      ]);
      return parcels;
    };

    if (parcelsToAdd.length > prevParcelsToAddLengthRef.current) {
      addParcels(parcelsToAdd);
      clearParcelsToAdd();
    }

    prevParcelsToAddLengthRef.current = parcelsToAdd.length;
  }, [
    field.value,
    setFieldValue,
    parcelsToAdd,
    clearParcelsToAdd,
    parcelsToAdd.length,
  ]);

  const getSuggestions = (searchInput?: string) => {
    fetchParcelsAndZonesSuggestions(searchInput, parcelsOnly, zonesOnly);
  };

  const handleSuggestionSelected = (suggestion: ParcelTo) => {
    addParcelOrZoneParcelsToAdd(suggestion);
  };

  const clearSuggestions = () => {
    clearParcelsAndZonesSuggestions(parcelsOnly, zonesOnly);
  };

  const handleItemRemove = (index: number) => {
    arrayHelpers.remove(index);
  };

  return (
    <>
      <ParcelZoneSelector
        autoFocus={true}
        inputRef={(ref: unknown) => ref}
        onSuggestionsClearRequested={clearSuggestions}
        onSuggestionSelected={handleSuggestionSelected}
        onSuggestionsFetchRequested={getSuggestions}
        placeholder="ParcelZoneSelector.placeholder"
        suggestions={suggestions.map((s) => ({
          ...s,
          title: formatMessage({ id: s.title }),
        }))}
      />
      {hasSubmitted && isError && (
        <ListSelectorError
          error={<FormattedMessage id="ParcelControl.chooseParcel" />}
        />
      )}
      <ReportsParcelsList items={field.value} onItemRemove={handleItemRemove} />
    </>
  );
};

const mapStateToProps = (
  state: ReportsState,
  props: { filter?: AnyTodo[] },
) => {
  const { filter } = props;
  return {
    suggestions: getParcelsAndZonesSuggestions((i: { id: AnyTodo }) =>
      filter?.length ? filter.includes(i.id) : true,
    )(state),
    parcelsToAdd: getParcelsToAdd(
      state,
    ) as ReportsState["ui"]["reportParcels"]["additions"]["items"],
  };
};

const mapDispatchToProps = (dispatch: Thunk<ReportsState>) =>
  bindActionCreators(
    {
      fetchParcelsAndZonesSuggestions,
      clearParcelsAndZonesSuggestions,
      addParcelOrZoneParcelsToAdd,
      clearParcelsToAdd,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ReportsParcelsControl);
