import React, { useEffect } from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import {
  setSensorsTabActive,
  setActionButtonHidden,
} from "../../../shared/actions/manager.actions";

import { getFarmPfVersionQuery } from "../../../../../shared/api/sentinel/admin/admin.api";
import { StatsPanelTable } from "../SentinelAdminStatsPanel/StatsPanelTable";
import { SentinelAdminTable } from "../SentinelAdminTable/SentinelAdminTable";

import { SentinelAdminHeader } from "./header/SentinelAdminHeader";
import { useSentinelAdmin } from "./useSentinelAdmin";

const SentinelAdmin = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    activeParcel,
    dialogTitle,
    farm,
    handleBulkActionClick,
    handleBulkDialogAccept,
    handleBulkDialogCancel,
    handleRowClick,
    mode,
    selected,
  } = useSentinelAdmin();

  const farmPfVersion = useQuery({
    ...getFarmPfVersionQuery({ farmIds: farm?.id || "" }),
    enabled: !!farm?.id,
  });

  useEffect(() => {
    dispatch(setActionButtonHidden(true));
    dispatch(setSensorsTabActive(false));
  }, [dispatch]);

  return (
    <Grid
      alignItems="center"
      className={classes.sentinelAdmin}
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Grid className={classes.tableHeaderContainer} container spacing={2}>
          <SentinelAdminHeader
            activeParcel={activeParcel}
            dialogTitle={dialogTitle}
            farm={farm}
            handleBulkActionClick={handleBulkActionClick}
            handleBulkDialogAccept={handleBulkDialogAccept}
            handleBulkDialogCancel={handleBulkDialogCancel}
            mode={mode}
            selected={selected}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={9} md={8} xs={12}>
            <SentinelAdminTable
              onRowClick={handleRowClick}
              versionPF={farmPfVersion.data?.data || ""}
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <StatsPanelTable versionPF={farmPfVersion.data?.data || ""} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  sentinelAdmin: {
    fontWeight: 500,
  },
  tableHeaderContainer: {
    paddingBottom: 8,
  },
}));

export { SentinelAdmin };
