import React, { useContext } from "react";

import { IconButton, Tooltip } from "@mui/material";
import TableRowMUI from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { generatePath, useHistory, useLocation } from "react-router-dom";

import { getPrecisionParcelListFocusedRow } from "../../../../shared/api/sentinel/precision/precision.selectors";

import { PRECISION_URLS } from "../../../../core/precision/precision.constants";

import deleteIcon from "../../../../assets/img/icons/satellite/precision/services/delete.svg";
import { PRECISION_TABS } from "../../../../core/precision/containers/PrecisionDetail";
import {
  DashboardParcelTo,
  InternalCropTo,
  PrecisionFarmingProduct,
  SeasonTo,
} from "../../../../generated/api/satellite";
import CfFormattedNumber from "../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import { SelectObject } from "../../../../shared/components/form/FormSelect/types";
import CfTableCell from "../../../../shared/components/tables/CfTableCell/CfTableCell";
import { SnackbarContext } from "../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { useFocusedTableRowTarget } from "../../../../shared/hooks/useFocusedTableRowTarget";
import { useToggle } from "../../../../shared/hooks/useToggle";
import useToggles from "../../../../shared/toggles/toggles";
import TOGGLES from "../../../../shared/toggles/toggles.const";
import { COLOR_PRIMARY } from "../../../../theme";
import { YieldCell } from "../../../components/yield/YieldCell";
import { HistoryPrevState } from "../../../types";
import { apiKeys } from "../api/apiKeys";
import {
  useServicesDelete,
  useUpdateSeasonParcelMutation,
} from "../api/services.api";
import { ActiveProductsCell } from "../components/ActiveProductsCell";
import { CropCell } from "../components/CropCell";
import { DeleteDialog } from "../components/DeleteDialog";

import { PrecisionState } from "../../../../reducers/precision.reducer.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  farmId: string;
  isInit?: boolean;
  parcel: DashboardParcelTo;
  season: SeasonTo;
  cropsOptions: SelectObject<InternalCropTo>[];
};
type Props = ReduxProps & OwnProps;

const mapStateToProps = (state: PrecisionState) => ({
  focusedRow: getPrecisionParcelListFocusedRow(state),
});

const TableRow = ({
  cropsOptions,
  farmId,
  focusedRow,
  isInit,
  parcel,
  season,
}: Props) => {
  const classes = useStyles();
  const yieldClasses = useYieldStyles();

  const history = useHistory();
  const queryClient = useQueryClient();
  const location = useLocation();
  const showSnackbar = useContext(SnackbarContext);
  const [, , isToggleActive] = useToggles();

  const { targetElementRef } = useFocusedTableRowTarget({
    focusedRow,
    isInit,
    rowId: parcel.parcelId.toString(),
  });

  const {
    on: deleteIsOpen,
    setOff: handleDeleteClose,
    setOn: handleDeleteOpen,
  } = useToggle();

  const servicesMutation = useServicesDelete();

  const handleDeleteAccept = () => {
    servicesMutation.mutate([season.id, parcel.parcelId, { farmIds: farmId }], {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: apiKeys.parcels.all(),
        });
        showSnackbar({
          message: (
            <FormattedMessage id="PrecisionServices.deleteDialog.submitted" />
          ),
          isSuccess: true,
        });
        handleDeleteClose();
      },
      onError: () => {
        showSnackbar({
          message: <FormattedMessage id="common.changesSaved.error" />,
          isError: true,
        });
      },
    });
  };

  const getTabPath = (parcel: DashboardParcelTo) => {
    const planetIsActive = parcel?.products?.includes(
      PrecisionFarmingProduct.SENTINEL_HUB_MANAGEMENT_ZONES_SAVI,
    );
    const soilIsActive = parcel?.products?.includes(
      PrecisionFarmingProduct.INTERNAL_SOIL_SAMPLES,
    );
    const monitoringIsActive =
      parcel?.products?.includes(
        PrecisionFarmingProduct.PLANET_BIOMONITORING_SAVI,
      ) ||
      parcel?.products?.includes(
        PrecisionFarmingProduct.GISAT_BIOPHYSICAL_MONITORING,
      );
    const yieldIsActive = parcel?.products?.includes(
      PrecisionFarmingProduct.INTERNAL_YIELD_MAPS,
    );

    let tabPath;
    if (planetIsActive) {
      tabPath = PRECISION_TABS.zoneManagement.path;
    } else if (monitoringIsActive) {
      tabPath = PRECISION_TABS.monitoring.path;
    } else if (soilIsActive) {
      tabPath = PRECISION_TABS.soilSamples.path;
    } else if (yieldIsActive) {
      tabPath = PRECISION_TABS.yieldMaps.path;
    }
    return tabPath;
  };

  const tabPath = getTabPath(parcel);

  const handleRowClick = (parcel: DashboardParcelTo) => {
    history.push(
      generatePath(
        `/farm/${farmId}/${PRECISION_URLS.serviceDetailV2()}/${tabPath}`,
        {
          parcelId: parcel.parcelId,
          seasonId: season.id,
        },
      ),
      {
        previousLocation: { ...location },
      } as HistoryPrevState,
    );
  };

  const seasonParcelMutation = useUpdateSeasonParcelMutation();

  return (
    <>
      <TableRowMUI
        hover
        ref={targetElementRef}
        className={classNames(classes.wrapper, {
          [classes.disabledRow]: !tabPath,
        })}
        onClick={() => {
          if (tabPath) {
            handleRowClick(parcel);
          }
        }}
      >
        <CfTableCell name="name">
          <span className={classes.name}>{parcel.localName}</span>
        </CfTableCell>
        <CfTableCell classes={{ root: classes.cell }} name="blockNr">
          <span>{parcel.blockNr}</span>
        </CfTableCell>
        <CfTableCell classes={{ root: classes.cell }} name="area">
          <span>
            <CfFormattedNumber decimalDigits={2} value={parcel.areaInHa || 0} />{" "}
            ha
          </span>
        </CfTableCell>
        <CfTableCell
          classes={{ root: classNames(classes.cell, classes.resetCell) }}
          name="crop"
        >
          <CropCell
            cropsOptions={cropsOptions}
            parcel={parcel}
            season={season}
          />
        </CfTableCell>
        <CfTableCell
          classes={{ root: classNames(classes.cell, classes.resetCell) }}
          name="yield"
        >
          <YieldCell
            classes={yieldClasses}
            handleMutation={seasonParcelMutation.mutate}
            isPending={seasonParcelMutation.isPending}
            parcel={parcel}
            seasonId={season?.id}
          />
        </CfTableCell>
        <CfTableCell
          classes={{ root: classNames(classes.cell, classes.services) }}
          name="services"
        >
          <ActiveProductsCell
            farmId={farmId}
            parcel={parcel}
            products={parcel.products ?? []}
            season={season}
          />
        </CfTableCell>
        <CfTableCell
          classes={{ root: classNames(classes.cell, classes.actions) }}
          name="actions"
        >
          <div className={classes.actionsBody}>
            {isToggleActive(TOGGLES.SEASON_DASHBOARD_TOGGLE.name) && (
              <Tooltip title={<FormattedMessage id="common.remove" />}>
                <IconButton
                  onClick={handleDeleteOpen}
                  onMouseDown={handleDeleteOpen}
                  style={{ padding: 9 }}
                >
                  <img alt="delete" src={deleteIcon} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </CfTableCell>
      </TableRowMUI>
      {deleteIsOpen && (
        <DeleteDialog
          handleAccept={handleDeleteAccept}
          handleClose={handleDeleteClose}
          open={deleteIsOpen}
          parcel={parcel}
          season={season}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    cursor: "pointer",
    background: "white",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.10)",
    "&.MuiTableRow-root:hover": {
      backgroundColor: "#E5F8E9",
    },
  },
  disabledRow: {
    cursor: "default",
  },
  services: {
    padding: "6px 0",
  },
  actions: {
    padding: "6px 0",
  },
  actionsBody: {
    display: "flex",
    justifyContent: "flex-end",
  },
  name: {
    paddingLeft: 15,
    fontSize: 16,
    fontWeight: 700,
    color: COLOR_PRIMARY.cleverfarm.main,
  },
  cell: {
    textAlign: "left",
    fontSize: 16,
  },
  resetCell: {
    padding: 0,
  },
}));

const useYieldStyles = makeStyles(() => ({
  wrapper: {
    width: "100%",
  },
  formControl: {
    maxWidth: "12ch",
  },
  input: {
    textAlign: "end",
  },
  placeholder: {
    height: 56,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "12ch",
    paddingRight: "1ch",
    borderRadius: 8,
  },
}));

const connector = connect(mapStateToProps);
export default connector(TableRow);
