import React from "react";

import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { isNil } from "lodash";

import { DashboardParcelTo } from "../../../generated/api/satellite";
import CfFormattedNumber from "../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";

type Props = {
  parcel: DashboardParcelTo;
  onClick: React.MouseEventHandler<unknown>;
  classes?: Record<string, string>;
};

const YieldPlaceholder = ({
  classes: propClasses = {},
  onClick,
  parcel,
}: Props) => {
  const classes = useStyles();

  return (
    <button
      className={classNames(propClasses.placeholder, classes.placeholder)}
      onClick={onClick}
    >
      <span
        className={classNames(
          propClasses.placeholderValue,
          classes.placeholderValue,
        )}
      >
        {isNil(parcel.yieldInTonnes) ? (
          "-"
        ) : (
          <CfFormattedNumber
            decimalDigits={1}
            value={parcel.yieldInTonnes || 0}
          />
        )}
        <span>t/ha</span>
      </span>
    </button>
  );
};

const useStyles = makeStyles(() => ({
  placeholder: {
    background: "none",
    border: "none",
    padding: 0,
    margin: 0,
    outline: "none",
    color: "inherit",
    font: "inherit",
    "&:hover": {
      backgroundColor: "#0000000a",
    },
    "&:focus": {
      backgroundColor: "#0000000a",
    },
  },
  placeholderValue: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "0.7ch",
  },
}));

export { YieldPlaceholder };
