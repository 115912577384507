const TOGGLES = {
  SEASON_DASHBOARD_TOGGLE: {
    name: "SEASON_DASHBOARD_TOGGLE",
    jira: "CFD-6440",
    description: "Precision season dashboard tab",
    active: false,
    permanent: false,
  },
  PRESCRIPTION_MAPS_TOGGLE: {
    name: "PRESCRIPTION_MAPS_TOGGLE",
    jira: "CFD-6902",
    description: "Prescription maps tab",
    active: false,
    permanent: false,
  },
  // example:
  // MY_FIRST_TOGGLE: {
  //   name: 'MY_FIRST_TOGGLE', //should be same as key (case sensitive)
  //   jira: 'JIRA ticket ID',
  //   active: false, //active by default
  //   permanent: true //overrides user preferences
  // }
  // more info: Readme.md # Content toggles
};

export default TOGGLES;
