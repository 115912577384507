import React from "react";

import { useFarmIds } from "../api/client.utils";
import LocalStorage from "../services/LocalStorage.service";

type Toggles = {
  [key: string]: {
    [farmId: string]: boolean;
  };
};

const TOGGLES_LOCAL_STORAGE_KEY = "farm_toggles";
const TOGGLES = ["PRECISION_FARMING_2_0"] as const;

const getDefaultToggles = () => {
  const result = {} as Toggles;

  TOGGLES.forEach((toggle) => {
    result[toggle] = {};
  });

  return result;
};

const useFarmToggles = () => {
  const { farmIds: farmId } = useFarmIds();
  const [toggles, setToggles] = React.useState<Toggles>(() => {
    const obj = LocalStorage.loadFromLocalStorage(TOGGLES_LOCAL_STORAGE_KEY);
    if (!obj) {
      return getDefaultToggles();
    }
    return (
      LocalStorage.loadFromLocalStorage(TOGGLES_LOCAL_STORAGE_KEY) ??
      getDefaultToggles()
    );
  });

  const setFarmToggle = (toggle: (typeof TOGGLES)[number], value: boolean) => {
    setToggles((c) => ({
      ...c,
      [toggle]: {
        ...c[toggle],
        [farmId]: value,
      },
    }));
  };

  const isActive = (toggle: (typeof TOGGLES)[number]) =>
    toggles[toggle][farmId];

  // @ts-expect-error type missing
  window.setFarmToggle = setFarmToggle;

  React.useEffect(() => {
    LocalStorage.saveToLocalStorage(toggles, TOGGLES_LOCAL_STORAGE_KEY);
  }, [toggles]);

  return { isActive };
};

export { useFarmToggles };
