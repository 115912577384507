import {
  GetCropsParams,
  GetDashboardPagedParams,
  GetSeasonsParams,
} from "../../../../generated/api/satellite";

const generalKeys = ["satellite", "dashboard"] as const;

const apiKeys = {
  general: [...generalKeys] as const,

  parcels: {
    all: () => [...apiKeys.general, "parcels"] as const,
    listUniqueKey: () => [...apiKeys.parcels.all(), "list"].join("-"),
    list: (params: GetDashboardPagedParams) =>
      [
        ...apiKeys.parcels.all(),
        apiKeys.parcels.listUniqueKey(),
        params,
      ] as const,
  },

  crops: {
    all: () => [...apiKeys.general, "crops"] as const,
    list: (params: GetCropsParams) => [...apiKeys.crops.all(), params] as const,
  },

  seasons: {
    all: () => [...apiKeys.general, "seasons"] as const,
    detail: (params: GetSeasonsParams) =>
      [...apiKeys.seasons.all(), params] as const,
  },
};

export { apiKeys };
