import React, { Fragment, useState } from "react";

import {
  DialogTitle,
  Grid,
  DialogActions,
  Button,
  Dialog,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfButtonPanel from "../../../../../shared/components/common/CfButtonPanel/CfButtonPanel";
import { useToggle } from "../../../../../shared/hooks/useToggle";
import { VaMapSourceItem } from "../VaMapSourceItem/VaMapSourceItem";
import { VaMapSourceList } from "../VaMapSourceList/VaMapSourceList";
import { VaMapSourceNotAvailable } from "../VaMapSourceNotAvailable/VaMapSourceNotAvailable";
import { VariableFertilizationCard } from "../VariableFertilizationCard/VariableFertilizationCard";

import { useVaMapSourceDialog } from "./useVaMapSourceDialog";

import {
  IndexResponse,
  IndexType,
  VariableApplicationZoneType,
  ZoneResponse,
} from "../../../../../shared/api/satellite/satellite.types";
import {
  ExtendedIndexResponse,
  SatelliteDataType,
} from "../../../../../shared/api/sentinel/managementZones/managementZones.types";

type Props = {
  currMapZones?: ZoneResponse[];
  disabled?: boolean;
  onAccept: (s?: IndexResponse) => void;
  onClick: (z?: ZoneResponse[]) => void;
  onRemove: () => void;
  satellite: IndexResponse;
  satelliteData: SatelliteDataType;
};

const VaMapSourceDialog = ({
  currMapZones,
  disabled,
  onAccept,
  onClick,
  onRemove,
  satellite,
  satelliteData,
}: Props) => {
  const classes = useStyles();
  const { on: isOpen, setOff: onClose, setOn: onOpen } = useToggle();
  const { entriesData } = useVaMapSourceDialog(satelliteData);

  const [currSatellite, setCurrentSatellite] =
    useState<ExtendedIndexResponse>();

  return (
    <Fragment>
      <VariableFertilizationCard
        disabled={!!disabled}
        onAddClick={onOpen}
        title="VaMapSourceDialog.managementZoneCard"
        onRemoveClick={() => {
          setCurrentSatellite(undefined);
          onRemove();
        }}
      >
        {satellite ? (
          <VaMapSourceItem
            isSelected={currMapZones === satellite?.zones}
            mapId="var-fert-zones-map"
            satellite={satellite}
            showType
            onClick={() =>
              onClick(
                currMapZones === satellite?.zones ? undefined : satellite.zones,
              )
            }
          />
        ) : null}
      </VariableFertilizationCard>

      <Dialog
        data-test="dialog"
        maxWidth="lg"
        open={isOpen}
        onClose={() => {
          onAccept(currSatellite);
          setCurrentSatellite(undefined);
          onClose();
        }}
      >
        <DialogTitle data-test="dialog-title">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <span className={classes.title}>
                <FormattedMessage id="VaMapSourceDialog.dialogTitle" />
              </span>
            </Grid>
          </Grid>
        </DialogTitle>

        <div className={`${classes.variabilityGroupsWrapper}`}>
          {entriesData.map((entry) => {
            const [key, source] = entry;

            return (
              <div
                className={classes.variabilityGroupWrapper}
                data-test={`source-${key}`}
                key={key}
              >
                <CfButtonPanel data-test="source-label">
                  <FormattedMessage id={`VaMapSourceDialog.${key}`} />
                </CfButtonPanel>

                {!source?.length && (
                  <VaMapSourceNotAvailable
                    source={source}
                    type={key as IndexType | VariableApplicationZoneType}
                    messageId={
                      key.endsWith(IndexType.SAVI)
                        ? "VaMapSourceNotAvailable.isNotAvailable"
                        : undefined
                    }
                  />
                )}

                {Boolean(source?.length) && (
                  <VaMapSourceList
                    onItemClick={setCurrentSatellite}
                    satellite={currSatellite}
                    source={source ?? []}
                  />
                )}
              </div>
            );
          })}
        </div>
        <DialogActions data-test="dialog-actions">
          <Button
            id="cancel"
            onClick={() => {
              onClose();
            }}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            color="primary"
            id="accept"
            onClick={() => {
              setCurrentSatellite(undefined);
              onAccept(currSatellite);
              onClose();
            }}
          >
            <FormattedMessage id="common.add" />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export { VaMapSourceDialog };

const useStyles = makeStyles({
  title: {
    fontSize: 20,
    fontWeight: 500,
  },
  image: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
    imageRendering: "pixelated",
    "&": {
      // fallback for FF
      imageRendering: "-moz-crisp-edges",
    },
  },
  variabilityGroupsWrapper: {
    margin: 6,
  },
  wrapperOneLine: {
    justifyContent: "flex-start",
  },
  variabilityGroupWrapper: {
    margin: "0 15px 10px 15px",
  },
});
