import { GetSeasonDashboardParams } from "../../../../generated/api/satellite";

const generalKeys = ["satellite", "services"];

const apiKeys = {
  general: [...generalKeys] as const,

  parcels: {
    all: () => [...apiKeys.general, "parcels"] as const,
    listUniqueKey: () => [...apiKeys.parcels.all(), "list"].join("-"),
    list: (seasonId: string, params: GetSeasonDashboardParams) =>
      [
        ...apiKeys.parcels.all(),
        apiKeys.parcels.listUniqueKey(),
        seasonId,
        params,
      ] as const,
  },
};

export { apiKeys };
