import React from "react";

import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { YieldMapLegendItem } from "../../../../../generated/api/satellite";
import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import { useIsMobile } from "../../../../../shared/hooks/useWidth";
import { ManagementZoneIcon } from "../../../../../shared/icons/ManagementZoneIcon";

interface Props {
  legend: Array<YieldMapLegendItem>;
}

const YieldMapLegend = (props: Props) => {
  const { legend } = props;

  const classes = useStyles();
  const isMobile = useIsMobile();
  const position = isMobile ? "static" : "absolute";

  return (
    <div className={classes.wrapper} style={{ position: `${position}` }}>
      <div className={classes.title}>
        <FormattedMessage id="YieldMaps.legend.title" />
      </div>
      {legend.map((item: YieldMapLegendItem) => (
        <div className={classes.textWrapper} key={item.order}>
          <ManagementZoneIcon color={item.color} size={16} />
          <div className={classes.label}>
            <CfFormattedNumber decimalDigits={1} value={item.from} /> -{" "}
            <CfFormattedNumber decimalDigits={1} value={item.to} /> t/ha
          </div>
        </div>
      ))}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.10);",
    left: "20px",
    bottom: "20px",
    zIndex: 1,
    padding: "16px",
    fontSize: "16px",
    fontFamily: "Roboto",
    lineHeight: "16px",
  },
  title: {
    color: "#848484",
    fontWeight: 400,
  },
  label: {
    color: "#000",
    fontSize: "12px",
    fontWeight: 500,
    padding: "0 0 0 8px",
  },
  textWrapper: {
    display: "flex",
    padding: "6px 0",
  },
}));

export { YieldMapLegend };
