import React from "react";

import { makeStyles } from "@mui/styles";

import {
  DashboardParcelTo,
  DashboardSeasonTo,
} from "../../../../../generated/api/satellite";

import { ParcelCellIconBar } from "./ParcelCellIconBar";

type Props = {
  data: DashboardParcelTo;
  newestSeason: DashboardSeasonTo;
  isFirstParcelCell: boolean;
};

const ParcelCell = ({ data, isFirstParcelCell, newestSeason }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div>
          <div className={classes.mainText}>{data.localName}</div>
          <div className={classes.subText}>{data.areaInHa} ha</div>
        </div>
      </div>
      {isFirstParcelCell ? (
        <div
          id="productfruits-seasondashboard-firstParcel-icons"
          product-fruits="productfruits-seasondashboard-firstParcel-icons"
        >
          <ParcelCellIconBar
            data={data}
            newestSeason={newestSeason}
            products={data.products ?? []}
          />
        </div>
      ) : (
        <ParcelCellIconBar
          data={data}
          newestSeason={newestSeason}
          products={data.products ?? []}
        />
      )}
    </div>
  );
};

export const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 12,
    height: "100%",
  },
  mainText: {
    fontSize: 16,
    color: "#00A179",
    fontWeight: 700,
  },
  subText: {
    fontSize: 12,
  },
  header: {
    display: "flex",
    gap: 11,
  },
}));

export { ParcelCell };
