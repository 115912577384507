import { queryOptions } from "@tanstack/react-query";
import moment from "moment";

import { GetAll7Params, getAll7 } from "../../../../generated/api/telematics";
import { staleTime } from "../../../../shared/query-client";

const cropsQuery = ({ dateFrom, dateTo, farmIds }: GetAll7Params) =>
  queryOptions({
    queryKey: ["telematics", "crops", dateFrom, dateTo, farmIds],
    queryFn: ({ signal }) =>
      getAll7(
        {
          dateFrom,
          dateTo,
          "sort-col": "NAME",
          "sort-dir": "ASC",
          farmIds,
        },
        { signal },
      ),
    staleTime: moment(dateTo).isBefore(moment().endOf("day"))
      ? staleTime.Infinity
      : undefined,
  });

export { cropsQuery };
