import React from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { getPrecisionParcelListTextFilter } from "../selectors/precisionParcelList.selectors";

import { PRECISION_URLS } from "../precision.constants";

import { NAMESPACE as namespace } from "../reducer/precisionParcelList.reducer";

import CfTextFilter from "../../../common/components/CfTextFilter/CfTextFilter";
import { useCfTextFilter } from "../../../common/components/CfTextFilter/useCfTextFilter";

import { usePfVersion } from "./api/usePfVersion";
import PrecisionServicesTable from "./PrecisionServicesTable";
import { usePrecisionListStyles } from "./styles";

import { PrecisionState } from "../../../reducers/precision.reducer.types";

interface Props {
  textFilter: string;
  farmId: string;
}

const PrecisionServices = ({ farmId, textFilter }: Props) => {
  const classes = usePrecisionListStyles();
  const { onChange } = useCfTextFilter(namespace);

  const history = useHistory();

  const { isLoading, isV1, isV2 } = usePfVersion();

  if (isV2) {
    history.push(`/farm/${farmId}/${PRECISION_URLS.seasons()}`);
  }

  return (
    <div className={classes.wrapper}>
      {!isLoading && isV1 ? (
        <div>
          <div className={classes.toolbar}>
            <CfTextFilter
              initialValue={textFilter}
              name="precision-list-text-filter"
              onChange={onChange}
              translId="PrecisionListTextFilter.placeholder"
            />
          </div>
          <PrecisionServicesTable
            rootUrl={`/farm/${farmId}/${PRECISION_URLS.services()}`}
          />
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  textFilter: getPrecisionParcelListTextFilter(state),
});

export default connect(mapStateToProps)(PrecisionServices);
