import React, {
  ChangeEvent,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  Checkbox,
  FormControlLabel,
  Grid,
  Theme,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Field,
  FieldArray,
  Form,
  FormikErrors,
  useFormikContext,
} from "formik";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getActionRestrictions } from "../../../shared/api/agroevidence/actions/actions.selectors";

import {
  getEPHRestrictionsApi,
  resetActionRestriction,
} from "../../../shared/api/agroevidence/actions/actions.api";
import CfFormControl from "../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikDatePicker from "../../../shared/components/form/CfFormikDatePicker/CfFormikDatePicker";
import CfFormikTextField from "../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import { useTypedIntl } from "../../../shared/hooks/useTypedIntl";
import * as validators from "../../../shared/misc/validators";
import { ActionButtons } from "../shared/components/ActionButtons/ActionButtons";
import { ActionRestrictionsInfo } from "../shared/components/ActionRestrictionsInfo/ActionRestrictionsInfo";
import { CropsSelector } from "../shared/components/CropsSelector/CropsSelector";
import { ActionDetailContext } from "../shared/containers/ActionDetail/ActionDetail";
import { ParcelsControl } from "../shared/containers/ParcelsControl/ParcelsControl";

import { mapRequestBodyEphActionTo } from "./actionEph.services";
import { validateEphFormValues } from "./actionEphFormValidationSchema";
import { Buttons } from "./components/Buttons";
import { FertilizersControl } from "./components/FertilizersControl/FertilizersControl";
import { PlantProtectionControl } from "./components/PlantProtectionControl/PlantProtectionControl";

import { ActionEphFormErrors, ActionEphFormValues } from "./actionEph.types";
import { CropTo } from "../../../shared/api/agroevidence/agroevidence.types";

type Props = {
  errors: FormikErrors<Partial<ActionEphFormErrors>>;
  existingActionId?: string;
  handleLsReset: () => void;
  handleResetForm: () => void;
  handleSaveToLs: (values: ActionEphFormValues) => void;
  isDraft: boolean;
  isEditing: boolean;
  isExisting: boolean;
  isSubmitting: boolean;
  onEditingEnd: () => void;
  values: ActionEphFormValues;
};

const ActionEphForm = ({
  errors,
  existingActionId,
  handleLsReset,
  handleResetForm,
  handleSaveToLs,
  isDraft,
  isEditing,
  isExisting,
  isSubmitting,
  onEditingEnd,
  values,
}: Props) => {
  const classes = useStyles();
  const { locale } = useTypedIntl();

  const [actionTotalArea, setActionTotalArea] = useState(0);
  const dispatch = useDispatch();
  const actionRestriction = useSelector(getActionRestrictions);

  const {
    handleCancelIsSplitting,
    isFetchingActionSplit,
    isSplitting,
    isSplittingError,
    splitActionHandler,
  } = useContext(ActionDetailContext);

  const { resetForm, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (isExisting) return;
    handleSaveToLs(values);
  }, [handleSaveToLs, isExisting, values]);

  useEffect(() => {
    if (
      values.targetCrop &&
      values.parcels.length > 0 &&
      values.fertilizers.length > 0
    ) {
      const isValid = validateEphFormValues(values);
      if (isValid) {
        const data = mapRequestBodyEphActionTo(
          values,
          locale,
          existingActionId,
        );
        dispatch(getEPHRestrictionsApi(data));
      } else {
        dispatch(resetActionRestriction());
      }
    }
  }, [dispatch, existingActionId, locale, values]);

  const handleReset = useCallback(() => {
    if (!isExisting) {
      handleLsReset();
      handleResetForm();
    } else {
      onEditingEnd();
      resetForm();

      // TODO: temporary fix with reloading formik state
      window.location.reload();
    }
  }, [handleLsReset, handleResetForm, isExisting, onEditingEnd, resetForm]);

  const hasDuplicateParcelIds =
    values?.parcels
      .map((p) => p.id)
      .filter((value, index, self) => self.indexOf(value) !== index).length > 0;

  const handleChangeCrop = (crop: CropTo) => {
    setFieldValue("targetCrop", crop);
  };

  const handleIsStrawDecayChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setFieldValue("isStrawDecay", evt.target.checked);
  };

  const materialFieldError = errors?.noFertilizersOrPlantProtection === "error";

  const isPorOnly = values.fertilizers.length === 0;

  return (
    <Fragment>
      <Form>
        <Grid alignItems="center" container justifyContent="center" spacing={1}>
          <Grid item xs={12}>
            <FieldArray name="parcels">
              {({ form, push, remove }) => (
                <ParcelsControl
                  allMustBeSown={false}
                  existingActionId={existingActionId}
                  form={form}
                  formName="eph"
                  formType="EPH"
                  handleChangeCrop={handleChangeCrop}
                  handleInsert={push}
                  handleRemove={remove}
                  hasDuplicateParcelIds={hasDuplicateParcelIds}
                  isDraft={isDraft}
                  isEditing={isEditing}
                  isExisting={isExisting}
                  setActionTotalArea={setActionTotalArea}
                />
              )}
            </FieldArray>
          </Grid>
          <Grid className={classes.date} container justifyContent="center">
            <Grid item md={4} sm={6} xl={3} xs={10}>
              <CfFormControl>
                <Field
                  component={CfFormikDatePicker}
                  disabled={!isEditing}
                  label={<FormattedMessage id="common.date" />}
                  name="date"
                  validate={validators.formikDateValidRequired}
                />
              </CfFormControl>
            </Grid>
          </Grid>

          <Grid alignItems="center" container justifyContent="center">
            <Grid item md={4} sm={6} xl={3} xs={10}>
              <CropsSelector
                defaultValues={values.targetCrop}
                disabled={!isEditing}
                error={!!errors.targetCrop}
                label={<FormattedMessage id="action.targetCrop" />}
                onChange={handleChangeCrop}
              />
            </Grid>
          </Grid>

          <Grid
            alignItems="center"
            className={classes.date}
            container
            justifyContent="center"
          >
            <Grid item md={4} sm={6} xl={3} xs={10}>
              <Tooltip
                title={
                  <FormattedMessage id="Eph.strawDecayApplication.tooltip" />
                }
              >
                <FormControlLabel
                  className={classes.checkboxContainer}
                  disabled={!isEditing}
                  label={<FormattedMessage id="Eph.strawDecayApplication" />}
                  labelPlacement="start"
                  control={
                    <Checkbox
                      checked={values.isStrawDecay}
                      color="primary"
                      id="isStrawDecay"
                      name="straw-decay"
                      onChange={handleIsStrawDecayChange}
                    />
                  }
                />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            alignItems="center"
            container
            justifyContent="center"
            spacing={1}
          >
            <Grid item md={4} sm={6} xl={3} xs={10}>
              <CfFormControl>
                <Field
                  component={CfFormikTextField}
                  disabled={!isEditing}
                  label={<FormattedMessage id="common.note" />}
                  name="description"
                />
              </CfFormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FieldArray name="plantProtections">
              {(arrayHelpers) => (
                <PlantProtectionControl
                  actionTotalArea={actionTotalArea}
                  arrayHelpers={arrayHelpers}
                  errors={errors}
                  isEditing={isEditing}
                  isExisting={isExisting}
                  name={arrayHelpers.name}
                />
              )}
            </FieldArray>
            <FieldArray name="fertilizers">
              {(arrayHelpers) => (
                <FertilizersControl
                  actionTotalArea={actionTotalArea}
                  arrayHelpers={arrayHelpers}
                  errors={errors}
                  isEditing={isEditing}
                  name={arrayHelpers.name}
                />
              )}
            </FieldArray>
            {materialFieldError && (
              <div className={classes.error}>
                <FormattedMessage
                  id="Eph.chooseMaterial.warning"
                  tagName="span"
                />
              </div>
            )}
          </Grid>
          {!isPorOnly && (
            <ActionRestrictionsInfo validationDetails={actionRestriction} />
          )}
          {isEditing && (
            <Buttons
              handleReset={handleReset}
              isEditing={isEditing}
              isExisting={isExisting}
              isSubmitting={isSubmitting}
            />
          )}
        </Grid>
      </Form>
      {isSplitting && (
        <ActionButtons
          isDisabled={isSplittingError}
          isLoading={isFetchingActionSplit}
          onCancel={handleCancelIsSplitting}
          onConfirm={splitActionHandler}
        />
      )}
    </Fragment>
  );
};

export { ActionEphForm };

const useStyles = makeStyles((theme: Theme) => ({
  date: {
    marginTop: 10,
  },
  checkboxContainer: {
    marginLeft: 0,
    marginRight: 0,
    width: "100%",
    "& .MuiFormControlLabel-label": {
      width: "100%",
    },
  },
  error: {
    color: theme.palette.error.main,
    fontSize: "12px",
  },
}));
