import { useEffect } from "react";

import { useField, useFormikContext } from "formik";
import { flatten, map } from "lodash";
import { useIntl } from "react-intl";

import {
  ApplicationBufferType,
  RestrictionType,
  SubtractionResponse,
} from "../../../../generated/api/agroevidence";
import { AnyTodo } from "../../../../types";
import { driftClassValue } from "../actionEph.services";

import { InitialParcelToAdd } from "../../ActionOthers/actionOther.types";
import {
  ActionEphFormValues,
  EphPlantProtectionsType,
} from "../actionEph.types";

const useEphRestrictions = (isEditing = false) => {
  const { setFieldValue } = useFormikContext<ActionEphFormValues>();

  const intl = useIntl();

  const [parcels] = useField(`parcels`);
  const [plantProtectionsFormValue] = useField(`plantProtections`);
  const plantProtections =
    plantProtectionsFormValue.value as EphPlantProtectionsType[];

  const extractBuffers = (predicate: (b: AnyTodo) => boolean, mapFn: AnyTodo) =>
    flatten(
      plantProtections.map((por) =>
        flatten(
          por.pests?.map(
            (pest) =>
              pest.buffer
                ?.filter(predicate)
                .map((bufferItem) => mapFn(bufferItem, por.name)) ?? [],
          ),
        ),
      ),
    );

  const buffersWithDriftReduction = flatten(
    plantProtections.map((por) =>
      flatten(
        por.pests?.map((pest) =>
          pest.buffer
            ?.filter(
              (b) =>
                b.driftReduction ===
                driftClassValue[
                  (por.driftClass as AnyTodo)
                    ?.code as keyof typeof driftClassValue
                ],
            )
            .map((bufferItem) => ({
              type: bufferItem?.type,
              value: bufferItem?.buffer,
              isMaterial: true,
              isAdded: true,
              name: por.name,
            })),
        ) ?? [],
      ),
    ),
  );

  const waterProtectionZonesBuffers = extractBuffers(
    (b) =>
      b.type === RestrictionType.SurfaceWaterProtectionZones ||
      b.type === RestrictionType.GroundWaterProtectionZones,
    (bufferItem: AnyTodo, name: string) => ({
      type: bufferItem?.type,
      value: bufferItem?.protectionLevel,
      isMaterial: true,
      isAdded: true,
      name,
    }),
  );

  const slopeWaterProtectionZones = extractBuffers(
    (b) =>
      b.type === RestrictionType.SlopeWater ||
      b.type === RestrictionType.SlopeVegetationStrip,
    (bufferItem: AnyTodo, name: string) => ({
      type: bufferItem?.type,
      value: bufferItem?.buffer,
      isAllowed: bufferItem.isAllowed,
      isMaterial: true,
      isAdded: true,
      name,
    }),
  );

  const populationProtectionZonesBuffers = extractBuffers(
    (b) => b.type === RestrictionType.PopulationProtectionZones,
    (bufferItem: AnyTodo, name: string) => ({
      type: bufferItem?.type,
      value: bufferItem?.buffer,
      isMaterial: true,
      isAllowed: bufferItem?.isAllowed,
      isAdded: true,
      name,
    }),
  );

  const landscapeBuffers = extractBuffers(
    (b) => b.type === RestrictionType.Landscape,

    (bufferItem: AnyTodo, name: string) => ({
      type: bufferItem?.type,
      value: bufferItem?.buffer,
      isMaterial: true,
      isAdded: true,
      name,
      isAllowed: bufferItem?.isAllowed,
      error: null,
    }),
  );

  const waterRestrictions = buffersWithDriftReduction.filter(
    (buffer) => buffer?.type === RestrictionType.Water,
  );
  const boundaryRestrictions = buffersWithDriftReduction.filter(
    (buffer) => buffer?.type === RestrictionType.Boundary,
  );
  const surfaceWaterProtectionZones = waterProtectionZonesBuffers.filter(
    (buffer) => buffer?.type === RestrictionType.SurfaceWaterProtectionZones,
  );
  const groundWaterProtectionZones = waterProtectionZonesBuffers.filter(
    (buffer) => buffer?.type === RestrictionType.GroundWaterProtectionZones,
  );
  const slopeWaterZones = slopeWaterProtectionZones.filter(
    (buffer) => buffer?.type === RestrictionType.SlopeWater,
  );
  const slopeVegetationStripZone = slopeWaterProtectionZones.filter(
    (buffer) => buffer?.type === RestrictionType.SlopeVegetationStrip,
  );

  const mappedSlopeWaterProtectionZones = (
    slopeWaterZones: AnyTodo,
    slopeVegetationStripZone: AnyTodo,
  ) => {
    const mappedSlopeWaterZones: AnyTodo[] = [];

    slopeWaterZones.forEach((slopeWaterZone: AnyTodo) => {
      const matchingSlopeVegetationStripZone = slopeVegetationStripZone.find(
        (vegetationStrip: AnyTodo) =>
          vegetationStrip?.name === slopeWaterZone?.name,
      );

      // SVAH: n, VEG. PAS: n
      if (
        slopeWaterZone.isAllowed &&
        (matchingSlopeVegetationStripZone ||
          matchingSlopeVegetationStripZone?.value === 0)
      ) {
        const mutatedSlopeWaterZone = {
          ...slopeWaterZone,
          name: `${slopeWaterZone.name} ${intl.formatMessage({ id: "Catalogues.plantProtection.application.slopeWater.name" })}`,
        };

        const mutatedVegetationStripZone = {
          ...matchingSlopeVegetationStripZone,
          name: `${matchingSlopeVegetationStripZone.name} ${intl.formatMessage({ id: "Catalogues.plantProtection.application.vegetationSlopeWater.name" })}`,
        };

        mappedSlopeWaterZones.push(
          mutatedSlopeWaterZone,
          mutatedVegetationStripZone,
        );
      }

      // SVAH: n, VEG. PAS: null
      if (
        slopeWaterZone.isAllowed &&
        (!matchingSlopeVegetationStripZone ||
          matchingSlopeVegetationStripZone?.value === 0)
      ) {
        const mutatedSlopeWaterZone = {
          ...slopeWaterZone,
          name: `${slopeWaterZone.name} ${intl.formatMessage({ id: "Catalogues.plantProtection.application.slopeWater.name" })}`,
        };
        mappedSlopeWaterZones.push(mutatedSlopeWaterZone);
      }

      // SVAH: NELZE, VEG. PAS: n
      if (!slopeWaterZone.isAllowed && matchingSlopeVegetationStripZone) {
        const mutatedVegetationStripZone = {
          ...matchingSlopeVegetationStripZone,
          name: `${matchingSlopeVegetationStripZone.name} ${intl.formatMessage({ id: "Catalogues.plantProtection.application.vegetationSlopeWater.name" })}`,
        };
        mappedSlopeWaterZones.push(mutatedVegetationStripZone);
      }

      // SVAH: NELZE, VEG. PAS: NELZE
      if (
        !slopeWaterZone.isAllowed &&
        matchingSlopeVegetationStripZone &&
        !matchingSlopeVegetationStripZone.isAllowed
      ) {
        matchingSlopeVegetationStripZone.error = intl.formatMessage(
          {
            id: `parcels.protectionZones.${matchingSlopeVegetationStripZone.type.toLocaleLowerCase()}`,
          },
          {
            name: matchingSlopeVegetationStripZone.name,
            value: matchingSlopeVegetationStripZone.value,
          },
        );
        mappedSlopeWaterZones.push(matchingSlopeVegetationStripZone);
      }
    });

    return mappedSlopeWaterZones.filter((restriction) => restriction.isAllowed);
  };

  const pestsArray = flatten(map(plantProtections, "pests"));

  interface mappedBuffer {
    type: ApplicationBufferType;
    value: number | undefined;
    isMaterial: boolean;
    isAdded: boolean;
    name: string;
    error?: string | null;
  }

  const getMaxMaterialValue = (
    areas?: (mappedBuffer | SubtractionResponse)[],
  ) =>
    areas?.reduce((maxValue, sa) => {
      if (sa.isMaterial && sa.value && sa.value > maxValue) {
        return sa.value;
      } else {
        return maxValue;
      }
    }, 0);

  useEffect(() => {
    Promise.all(
      parcels.value.map(async (parcel: InitialParcelToAdd, index: number) => {
        const defaultBoundaryBuffers =
          parcel?.subtractableAreas?.boundary.filter(
            (buffer: AnyTodo) => !buffer?.isAdded,
          );

        const defaultWaterBuffers = parcel?.subtractableAreas?.water.filter(
          (buffer: AnyTodo) => !buffer?.isAdded,
        );

        const defaultSurfaceWaterProtectionZones =
          parcel?.subtractableAreas?.surfaceWaterProtectionZones?.filter(
            (buffer: AnyTodo) => !buffer?.isAdded,
          ) ?? [];

        const defaultGroundWaterProtectionZones =
          parcel?.subtractableAreas?.groundWaterProtectionZones?.filter(
            (buffer: AnyTodo) => !buffer?.isAdded,
          ) ?? [];

        const defaultPopulationProtectionZones =
          parcel?.subtractableAreas?.populationProtectionZones?.filter(
            (buffer: AnyTodo) => !buffer?.isAdded,
          ) ?? [];

        const defaultLandscapeBuffers =
          parcel?.subtractableAreas?.landscape?.filter(
            (buffer: AnyTodo) => !buffer?.isAdded,
          ) ?? [];

        await setFieldValue(`parcels[${index}]`, {
          ...parcel,
          subtractableAreas: {
            ...parcel.subtractableAreas,
            boundary: [...defaultBoundaryBuffers, ...boundaryRestrictions],
            laundscape: [...defaultLandscapeBuffers, ...landscapeBuffers],
            water: [
              ...defaultWaterBuffers,
              ...waterRestrictions,
              ...(parcel?.slope && parcel?.slope >= 3
                ? mappedSlopeWaterProtectionZones(
                    slopeWaterZones,
                    slopeVegetationStripZone,
                  )
                : []),
            ],
            surfaceWaterProtectionZones: [
              ...defaultSurfaceWaterProtectionZones,
              ...surfaceWaterProtectionZones,
            ],
            groundWaterProtectionZones: [
              ...defaultGroundWaterProtectionZones,
              ...groundWaterProtectionZones,
            ],
            populationProtectionZones: [
              ...defaultPopulationProtectionZones,
              ...populationProtectionZonesBuffers,
            ],
          },
        });
      }),
    );
  }, [parcels?.value?.length, JSON.stringify(plantProtections)]);

  useEffect(() => {
    if (isEditing) {
      Promise.all(
        parcels.value.map(async (parcel: InitialParcelToAdd, index: number) => {
          const defaultBoundaryBuffers =
            parcel?.subtractableAreas?.boundary.filter(
              (buffer: AnyTodo) => !buffer?.isAdded,
            );

          const defaultWaterBuffers = parcel?.subtractableAreas?.water.filter(
            (buffer: AnyTodo) => !buffer?.isAdded,
          );

          const defaultSurfaceWaterProtectionZones =
            parcel?.subtractableAreas?.surfaceWaterProtectionZones?.filter(
              (buffer: AnyTodo) => !buffer?.isAdded,
            ) ?? [];

          const defaultGroundWaterProtectionZones =
            parcel?.subtractableAreas?.groundWaterProtectionZones?.filter(
              (buffer: AnyTodo) => !buffer?.isAdded,
            ) ?? [];

          const defaultLandscapeBuffers =
            parcel?.subtractableAreas?.landscape?.filter(
              (buffer: AnyTodo) => !buffer?.isAdded,
            ) ?? [];

          const defaultPopulationProtectionZones =
            parcel?.subtractableAreas?.populationProtectionZones?.filter(
              (buffer: AnyTodo) => !buffer?.isAdded,
            ) ?? [];

          await setFieldValue(`parcels[${index}]`, {
            ...parcel,
            subtractableAreas: {
              ...parcel.subtractableAreas,
              boundary: [...defaultBoundaryBuffers, ...boundaryRestrictions],
              boundaryChecked:
                getMaxMaterialValue([
                  ...defaultBoundaryBuffers,
                  ...boundaryRestrictions,
                ]) ?? 0,
              water: [
                ...defaultWaterBuffers,
                ...waterRestrictions,
                ...(parcel?.slope && parcel?.slope >= 3
                  ? mappedSlopeWaterProtectionZones(
                      slopeWaterZones,
                      slopeVegetationStripZone,
                    )
                  : []),
              ],
              waterChecked:
                getMaxMaterialValue([
                  ...defaultWaterBuffers,
                  ...waterRestrictions,
                  ...(parcel?.slope && parcel?.slope >= 3
                    ? mappedSlopeWaterProtectionZones(
                        slopeWaterZones,
                        slopeVegetationStripZone,
                      )
                    : []),
                ]) ?? 0,
              laundscape: [...defaultLandscapeBuffers, ...landscapeBuffers],
              laundscapeChecked:
                getMaxMaterialValue([
                  ...defaultLandscapeBuffers,
                  ...landscapeBuffers,
                ]) ?? 0,
              surfaceWaterProtectionZones: [
                ...defaultSurfaceWaterProtectionZones,
                ...surfaceWaterProtectionZones,
              ],
              surfaceWaterProtectionZonesChecked:
                getMaxMaterialValue([
                  ...defaultSurfaceWaterProtectionZones,
                  ...surfaceWaterProtectionZones,
                ]) ?? 0,
              groundWaterProtectionZones: [
                ...defaultGroundWaterProtectionZones,
                ...groundWaterProtectionZones,
              ],
              groundWaterProtectionZonesChecked:
                getMaxMaterialValue([
                  ...defaultGroundWaterProtectionZones,
                  ...groundWaterProtectionZones,
                ]) ?? 0,
              populationProtectionZones: [
                ...defaultPopulationProtectionZones,
                ...populationProtectionZonesBuffers,
              ],
              populationProtectionZonesChecked:
                getMaxMaterialValue([
                  ...defaultPopulationProtectionZones,
                  ...populationProtectionZonesBuffers,
                ]) ?? 0,
            },
          });
        }),
      );
    }
  }, [JSON.stringify(pestsArray)]);
};

export { useEphRestrictions };
