import React, { Component } from "react";

import { withStyles } from "@mui/styles";
import OlFeature from "ol/Feature";
import ImageLayer from "ol/layer/Image";
import VectorLayer from "ol/layer/Vector";
import Projection from "ol/proj/Projection";
import Static from "ol/source/ImageStatic";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style as OlStyle } from "ol/style";
import PropTypes from "prop-types";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./ParcelDetailMap.css";
import { editorSetSelected } from "../../../../../map/actions/editor/editor.actions";
import {
  setMouseClickEL,
  setHoverStyleEL,
} from "../../../../../map/actions/eventListener/eventListener.actions";
import { storeInitialLayers } from "../../../../../map/actions/layersUI/layersUI.actions";
import {
  storeServiceWrapper,
  setMapGrabEL,
  resetMap,
} from "../../../../../map/actions/map/map.actions";
import {
  refreshParcelDetailStyles,
  refreshDefaultStyles,
} from "../../../../../map/actions/style/style.actions";

import {
  fetchLayersConfig,
  resetLayers,
} from "../../../../../../shared/api/other/layers/layers.api";
import SectionWrapper from "../../../../../../shared/components/specific/SectionWrapper/SectionWrapper";
import withConfig from "../../../../../../shared/hocs/context/withConfig";
import { withFarm } from "../../../../../../shared/hocs/context/withFarm";
import { hexToRgba } from "../../../../../../shared/misc/colorLuminance";
import { HomeControl } from "../../../../../map/components/HomeControl/HomeControl";
import { LayersCropsControl } from "../../../../../map/components/LayersCropsControl/LayersCropsControl";
import EventListener from "../../../../../map/services/EventListener.service";
import Feature from "../../../../../map/services/Feature.service";
import Geometry from "../../../../../map/services/geometry/Geometry.service";
import Layers from "../../../../../map/services/Layers.service";
import MapService from "../../../../../map/services/Map.service";
import Style from "../../../../../map/services/Style.service";
import CommonStyles, {
  FILL_COLOR,
  STROKE_COLOR,
} from "../../../../../map/services/styles/CommonStyles.service";
import MapRedirectControl from "../../components/MapRedirectControl/MapRedirectControl";

const styles = {
  map: {
    height: "100%",
    position: "relative",
  },
};

export class ParcelDetailMap extends Component {
  constructor(props) {
    super(props);

    this.MAP_SRID_ID = "3857";
    this.DATA_SRID_ID = "4326";

    this.transformOptions = {
      dataProjection: `EPSG:${this.DATA_SRID_ID}`,
      featureProjection: `EPSG:${this.MAP_SRID_ID}`,
    };

    this.layer = null;
  }

  componentDidMount() {
    const { farm } = this.props;

    this.map = new MapService(
      "parcel-map",
      farm.id,
      farm.boundingBox,
      this.transformOptions,
    );
    this.props.fetchLayersConfig(farm.customer.countryCode !== "CZ");

    const el = new EventListener(this.map.getMap());
    this.props.storeServiceWrapper("main", this.map);
    this.props.storeServiceWrapper("el", el);
  }

  componentDidUpdate(prevProps) {
    const {
      config,
      displayMap: newDisplayMap,
      farm: { customer, id: farmId },
      geometries: newGeometries,
      isHistorical: newIsHistorical,
      layersConfig: newLayersConfig,
      mapImage: newMapImage,
      parcelColor: newParcelColor,
      parcelGeometry: newParcelGeometry,
      parcelId: newParcelId,
    } = this.props;

    const {
      displayMap: prevDisplayMap,
      geometries: prevGeometries,
      isHistorical: prevIsHistorical,
      layersConfig: prevLayersConfig,
      mapImage: prevMapImage,
      parcelColor: prevParcelColor,
      parcelGeometry: prevParcelGeometry,
    } = prevProps;

    if (prevParcelColor !== newParcelColor) {
      this.props.refreshParcelDetailStyles(newParcelColor);
    }

    if (
      (!prevParcelGeometry.geometry && newParcelGeometry.geometry) ||
      prevParcelGeometry !== newParcelGeometry
    ) {
      this.zoomToParcel();
    }

    /*
     * Sets layers on the map when:
     * 1) layer config is fetched
     */
    if (!prevLayersConfig.length && newLayersConfig.length) {
      this.layers = new Layers(
        this.map.getMap(),
        config.api,
        farmId,
        this.map.getFarmExtent(),
      );

      // adding zIndex to the parcels layer
      const extendedNewLayersConfig = newLayersConfig.map((c) => {
        if (c.layerId === "parcel") {
          return {
            ...c,
            zIndex: 10,
          };
        }
        if (c.layerId === "parcel_label") {
          return {
            ...c,
            zIndex: 11,
          };
        }
        return c;
      });
      this.layers.setInitialLayers(
        extendedNewLayersConfig,
        this.props.storeInitialLayers,
      );
      const style = new Style(
        this.layers.getParcelLayer(),
        this.layers.getParcelLabelLayer(),
        customer.countryCode,
      );

      this.props.storeServiceWrapper("layers", this.layers);
      this.props.storeServiceWrapper("style", style);

      this.setHoverEL();
      this.setSelectEL();

      this.props.editorSetSelected({ id: newParcelId });
      this.props.refreshParcelDetailStyles(newParcelColor);
      this.props.setMapGrabEL();
    }

    if (newGeometries !== prevGeometries && !newGeometries) {
      this.removeLayer();
    }

    if (newMapImage !== prevMapImage && !newMapImage) {
      this.removeLayer();
    }

    if (newGeometries !== prevGeometries && newGeometries) {
      this.addGeometries(newGeometries);
    }

    if (newMapImage !== prevMapImage && newMapImage) {
      this.addImage(newMapImage);
    }

    if (!prevDisplayMap && newDisplayMap) {
      this.map.updateSize();
      this.zoomToParcel();
    }

    if (
      this.layers &&
      newParcelGeometry.geometry &&
      (!prevParcelGeometry.geometry ||
        !this.layers ||
        prevIsHistorical !== newIsHistorical ||
        newParcelGeometry.geometry !== prevParcelGeometry.geometry)
    ) {
      if (newIsHistorical) {
        this.props.editorSetSelected([]);
        this.props.refreshDefaultStyles();
        this.addHistorical(newParcelGeometry);
      } else {
        this.removeHistorical();
        this.props.editorSetSelected({ id: newParcelId });
        this.props.refreshParcelDetailStyles(newParcelColor);
      }
    }
  }

  componentWillUnmount() {
    this.props.storeServiceWrapper("main", undefined);
    this.props.storeServiceWrapper("el", undefined);
    this.props.storeServiceWrapper("layers", undefined);
    this.props.storeServiceWrapper("style", undefined);

    this.props.resetMap();
    this.props.resetLayers();
  }

  setHoverEL = () => {
    this.props.setHoverStyleEL(
      Feature.isFeature,
      () => refreshParcelDetailStyles(this.props.parcelColor),
      "parcelHoverELKey",
    );
  };

  setSelectEL = () => {
    this.props.setMouseClickEL(
      (feature) => {
        if (feature) {
          const featureId = feature.get("id");
          const selectedParcel = {
            id: feature.get("id"),
            blockNr: feature.get("block_nr"),
            localName: feature.get("local_name"),
          };
          this.props.goToHistoricalParcel(selectedParcel);
          this.props.editorSetSelected({ id: featureId });
        }
      },
      () => refreshParcelDetailStyles(),
      "parcelSelectELKey",
    );
  };

  addGeometries(geometries) {
    if (this.layer) {
      this.removeLayer();
    }

    this.layer = new VectorLayer({
      source: new VectorSource(),
      zIndex: 5,
      style: (feature) =>
        new OlStyle({
          fill: new Fill({
            color: hexToRgba(
              feature.get("color").charAt(0) === "#"
                ? `${feature.get("color")}`
                : `#${feature.get("color")}`,
              feature.get("opacity") || 0.9,
            ),
          }),
          stroke: new Stroke({
            color: "#333333",
            width: 1,
          }),
        }),
    });

    geometries.forEach((zone) => {
      this.layer.getSource().addFeature(
        new OlFeature({
          geometry: Geometry.readGeometry(zone.geometry, {}),
          color: zone.color,
          opacity: zone.opacity,
        }),
      );
    });

    this.layers.addLayer(this.layer);
  }

  removeLayer() {
    this.layers.removeLayer(this.layer);
    this.layer = null;
  }

  addImage(image) {
    if (this.layer) {
      this.removeLayer();
    }

    const { extent, url } = image;
    this.layer = new ImageLayer({
      source: new Static({
        url,
        projection: new Projection({
          code: "xkcd-image",
          units: "pixels",
          extent,
        }),
        imageExtent: extent,
      }),
    });

    this.layers.addLayer(this.layer);
    this.setState({
      isFetching: false,
    });
  }

  zoomToParcel = () => {
    try {
      this.map.zoomToGeometry(this.props.parcelGeometry.geometry);
    } catch {
      // todo: temporary fix
    }
  };

  addHistorical(parcelGeometry) {
    if (this.historicalLayer) {
      this.removeHistorical();
    }

    this.historicalLayer = new VectorLayer({
      source: new VectorSource(),
      style: CommonStyles.getGeometryStyle({
        stroke: {
          color: STROKE_COLOR.SELECTED,
          width: 6,
          lineDash: [10, 20],
        },
        fill: {
          color: FILL_COLOR.HISTORICAL,
        },
      }),
    });
    const newFeature = new OlFeature({
      geometry: Geometry.readGeometry(
        parcelGeometry.geometry,
        this.transformOptions,
      ),
    });

    this.historicalLayer.getSource().addFeature(newFeature);
    this.layers.addLayer(this.historicalLayer);
  }

  removeHistorical() {
    this.layers.removeLayer(this.historicalLayer);
    this.historicalLayer = null;
  }

  render() {
    const { classes, farm, isHistorical, ngGoToMainMap, parcelId } = this.props;
    return (
      <div className={classes.map} id="parcel-map">
        <HomeControl moveToTop={false} zoomToHome={this.zoomToParcel} />
        {!isHistorical && (
          <MapRedirectControl
            farmId={farm.id}
            ngGoToMainMap={ngGoToMainMap}
            parcelId={parcelId}
          />
        )}
        <SectionWrapper left={14} top={17}>
          <LayersCropsControl
            farmCountryCode={farm?.customer?.countryCode}
            withCrops={false}
          />
        </SectionWrapper>
      </div>
    );
  }
}

ParcelDetailMap.propTypes = {
  classes: PropTypes.object.isRequired,
  farm: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  parcelId: PropTypes.string.isRequired,
  parcelGeometry: PropTypes.object.isRequired,
  setHoverStyleEL: PropTypes.func.isRequired,
  storeServiceWrapper: PropTypes.func.isRequired,
  setMouseClickEL: PropTypes.func.isRequired,
  storeInitialLayers: PropTypes.func.isRequired,
  fetchLayersConfig: PropTypes.func.isRequired,
  layersConfig: PropTypes.array.isRequired,
  setMapGrabEL: PropTypes.func.isRequired,
  editorSetSelected: PropTypes.func.isRequired,
  refreshParcelDetailStyles: PropTypes.func.isRequired,
  goToHistoricalParcel: PropTypes.func.isRequired,
  parcelColor: PropTypes.object,
  ngGoToMainMap: PropTypes.func.isRequired,
  displayMap: PropTypes.bool.isRequired,
  geometries: PropTypes.array,
  mapImage: PropTypes.object,
  isHistorical: PropTypes.bool,
  refreshDefaultStyles: PropTypes.func.isRequired,
  resetMap: PropTypes.func.isRequired,
  resetLayers: PropTypes.func.isRequired,
};

ParcelDetailMap.defaultProps = {
  parcelColor: null,
  geometries: null,
  mapImage: null,
  isHistorical: false,
};

const mapStateToProps = (state) => ({
  parcelGeometry: state.api.agroevidence.parcels.item || {},
  layersConfig: state.api.layers.items,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setHoverStyleEL,
      storeServiceWrapper,
      setMouseClickEL,
      storeInitialLayers,
      fetchLayersConfig,
      setMapGrabEL,
      editorSetSelected,
      refreshParcelDetailStyles,
      refreshDefaultStyles,
      resetMap,
      resetLayers,
    },
    dispatch,
  );

const ParcelDetailMapWithFarm = withFarm(ParcelDetailMap);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withConfig(),
  withStyles(styles),
)(ParcelDetailMapWithFarm);
