import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./monitoring.constants";
import * as typesV2 from "./monitoringV2.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import { IndiceType } from "../../../../core/precision/selectors/indices";

export const getCropIntervalsApi = (
  parcelId: string,
  indexType?: IndiceType,
) => ({
  [RSAA]: {
    endpoint: `biophysical-monitoring/${parcelId}/crop-intervals?${queryString.stringify({ indexType })}&`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_MONITORING_CROP_INTERVALS_REQUEST,
      types.GET_MONITORING_CROP_INTERVALS_SUCCESS,
      types.GET_MONITORING_CROP_INTERVALS_ERROR,
    ],
  },
});

export const resetCropIntervals = () => ({
  type: types.RESET_MONITORING_CROP_INTERVALS,
});

export const getMonitoringDataApi = (
  parcelId: string,
  index: string,
  dateFrom: string,
  dateTo: string,
  status: string,
  perPage?: number,
) => ({
  [RSAA]: {
    endpoint: `biophysical-monitoring/${parcelId}?type=${index}&date-from=${
      dateFrom || ""
    }&date-to=${dateTo || ""}&status=${status || ""}&per-page=${
      perPage || ""
    }&`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_MONITORING_DATA_REQUEST,
      types.GET_MONITORING_DATA_SUCCESS,
      types.GET_MONITORING_DATA_ERROR,
    ],
  },
});

export const getMonitoringDataV2Api = (parcelId: string, index: string) => ({
  [RSAA]: {
    endpoint: `biophysical-monitoring/v2/${parcelId}?type=${index}&`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      typesV2.GET_MONITORING_DATA_REQUEST,
      typesV2.GET_MONITORING_DATA_SUCCESS,
      typesV2.GET_MONITORING_DATA_ERROR,
    ],
  },
});

export const resetMonitoringData = () => ({
  type: types.RESET_MONITORING_DATA,
});

type GetBiomonitoringIndexRecordsParams = {
  parcelId: string;
  "index-type": IndiceType;
  "date-from": string;
  "date-to": string;
};

export const getBiomonitoringIndexRecordsApi = (
  p: GetBiomonitoringIndexRecordsParams,
) => {
  const { parcelId, ...rest } = p;

  return {
    [RSAA]: {
      endpoint: `biomonitoring/${parcelId}?${queryString.stringify({ ...rest })}&`,
      method: methods.GET,
      module: modules.SENTINEL,
      types: [
        types.GET_BIOMONITORING_INDEX_RECORDS_REQUEST,
        types.GET_BIOMONITORING_INDEX_RECORDS_SUCCESS,
        types.GET_BIOMONITORING_INDEX_RECORDS_ERROR,
      ],
    },
  };
};

export const resetBiomonitoringIndexRecordsData = () => ({
  type: types.RESET_BIOMONITORING_INDEX_RECORDS,
});
