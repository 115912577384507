import { groupBy } from "lodash";
import { createSelector } from "reselect";

import { selectSoilSamplesData } from "../../../shared/api/sentinel/soilSamples/soilSamples.selectors";

import {
  zonesColors,
  phZonesColors,
  kmgZonesColors,
  percentageZonesColors,
  SoilSamplesZonesColor,
} from "../services/soilSamlesZonesColors";

import {
  CombinedSoilSamplesData,
  Sample,
  Zone,
} from "../../../shared/api/sentinel/soilSamples/soilSamples.types";

const samplesDataTypes = ["SAMPLES"];

const customOrderSoilSamples = [
  "PH",
  "P",
  "K",
  "MG",
  "CA",
  "KMG",
  "S",
  "FE",
  "ZN",
  "B",
  "HUMUS",
  "COx",
];

export const selectZonesSoilSamplesData = createSelector(
  selectSoilSamplesData,
  (data) =>
    (data ?? []).reduce((acc: { [key: string]: Zone[] }, item) => {
      item?.zones.forEach((zone) => {
        let colorArray: SoilSamplesZonesColor[];
        if (zone.sample === "PH") {
          colorArray = phZonesColors;
        } else if (zone.sample === "KMG") {
          colorArray = kmgZonesColors;
        } else if (zone.sample === "HUMUS" || zone.sample === "COx") {
          colorArray = percentageZonesColors;
        } else {
          colorArray = zonesColors;
        }

        // if the sample of the current zone doesn't exist in the result object yet, add it as an empty array
        if (!acc[zone.sample]) {
          acc[zone.sample] = [];
        }
        // add a new object to the array for the current sample, with the zone's properties and the color
        acc[zone.sample] = [
          ...acc[zone.sample],
          {
            ...zone,
            color: colorArray.find(
              (color) => color.zoneStrength === zone.zoneStrength,
            )?.color,
          },
        ];
      });
      return acc;
    }, {}),
);

export const selectPointsSoilSamplesData = createSelector(
  selectSoilSamplesData,
  (data) =>
    (data ?? []).reduce((acc: { [key: string]: Sample[] }, item) => {
      item?.samples.forEach((point) => {
        // if the sample of the current point doesn't exist in the result object yet, add it as an empty array
        if (!acc[point.sample]) {
          acc[point.sample] = [];
        }
        // add a new object to the array for the current sample, with the point's properties
        acc[point.sample] = [...acc[point.sample], point];
      });
      return acc;
    }, {}),
);

export const selectCombinedSoilSamplesData = createSelector(
  selectZonesSoilSamplesData,
  selectPointsSoilSamplesData,
  (zones, points) => {
    if (!zones) return;
    return Object.entries(zones)
      .map(([name, zones]) => ({
        name,
        zones,
        points: points[name] || [],
      }))
      .sort(
        (a, b) =>
          customOrderSoilSamples.indexOf(a.name) -
          customOrderSoilSamples.indexOf(b.name),
      );
  },
);

export const selectValuesSoilSamplesData = createSelector(
  selectSoilSamplesData,
  (data) => {
    if (!data) {
      return [];
    }

    const combinedSamples = data
      .reduce((acc: Sample[], obj) => acc.concat(obj.samples), [])
      .sort(
        (a, b) =>
          customOrderSoilSamples.indexOf(a.sample) -
          customOrderSoilSamples.indexOf(b.sample),
      );

    const groups = groupBy(
      combinedSamples,
      (item) =>
        `${item.geometry.coordinates[0]}_${item.geometry.coordinates[1]}`,
    );
    return Object.values(groups);
  },
);

export const selectSoilSamplesDataForVRF = createSelector(
  selectCombinedSoilSamplesData,
  (data) =>
    data?.map((d: CombinedSoilSamplesData) => ({
      dateFrom: null,
      dateTo: null,
      zones: d.zones,
      chemicalElement: d.name,
      type: samplesDataTypes[0],
    })) || null,
);
